/* chat*/
.chat-container {
    /* width: 300px; */
    height: 70vh;
    margin: 5px 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #2c2648;
    /* border-radius: 30px; */
    /* padding-top: 40px; */
}

.chat-container .chat-log {
    position: relative;
    height: 100%;
    /* background: rgb(235, 235, 235); */
    overflow: auto;
    /* padding: 5px 10px; */
    padding: 30px;
}

.chat-container .chat-log::-webkit-scrollbar {
    width: 10px;
    padding: 2px;
}

.chat-container .chat-log::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    background: #141121;

}

.chat-container .chat-log::-webkit-scrollbar-thumb {
    /* background: var(--background-theme); */
    background: #fff;
    border-radius: 20px;
}

.chat-container .chat-log::-webkit-scrollbar-thumb:hover {
    /* background: #fff; */
    background: #fff;
}


.chat-container .chat-log .message {
    width: 100%;
    background: #fff;
    width: fit-content;
    display: flex;
    margin: 10px 0;
    flex-direction: column;
    padding: 5px 10px;
    border-radius: 0px 15px 15px 15px;
}

.chat-container .chat-log .message.emojies {
    flex-direction: row;
    align-items: center;
    gap: 2px;
}

.chat-container .chat-log .message.self {
    border-radius: 15px 0px 15px 15px;
    background: #e9f9fa;
    /* background: #edfae9; */
    margin-left: auto;
}

.chat-container .chat-log .message .name {
    font-size: 11px;
    margin-bottom: 3px;
    text-transform: capitalize;
}

.chat-container .chat-log .message.emojies .name {
    font-size: 14px;
    margin-top: 5px;
}

.chat-container .chat-log .message.self .name {
    display: none;
}

.chat-container .chat-log .message .name.col-943d24 {
    color: #943d24;
}

.chat-container .chat-log .message .name.col-9b45b2 {
    color: #9b45b2;
}

.chat-container .chat-log .message .name.col-73a24e {
    color: #73a24e;
}

.chat-container .chat-log .message .name.col-f0a04b {
    color: #f0a04b;
}

.chat-container .chat-log .message .name.col-2b6777 {
    color: #2b6777;
}

.chat-container .chat-log .message .content {
    font-size: 14px;
    margin: 0 5px;
    width: 100%;
    text-align: justify;
    overflow-wrap: break-word;
}

.chat-container .chat-log .no-messages {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.chat-container .chat-log .no-messages h4 {
    font-size: 16px;
    font-weight: 500;
    color: #929292;
}

.chat-container form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.chat-container form .form-group {
    margin: 0;
    width: 100%;
    margin-right: 5px;
    position: relative;
}

.chat-container form .form-group input {
    border: 1px solid #929292;
    padding-right: 40px;
}

.chat-container form .btn {
    height: 100%;
}

.chat-container .form-group .emoji-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: grey;
    filter: grayscale(100%);
    cursor: pointer;
    height: 20px;
    border-radius: 50%;
}

#emoji-btn {
    position: absolute;
    right: 15px;
    bottom: 0px;
    cursor: pointer;
    /* height: 80px; */
    /* border-radius: 50%; */
}

#webinar-chatbox {
    background-color: #100829;
    width: 14vw;
    /* padding: 20px; */
    /* padding-left: 5px; */
    /* border-radius: 20px; */
    color: white;
    /* padding-right: 100px; */
    font-size: small;
}

#videoTopIce {
    width: 50%;
    top: -20px;
    left: -20px;
}

#videoBottomIce {
    width: 50%;
    bottom: -30px;
    right: -15px;
}

#chatBoxIce{
    position: absolute;
    top: -30px;
}

@media  screen and (min-width: 870px) and (max-width: 1100px) {
    #webinar-chatbox{
        width: 12vw;
        padding-right: 10px;
        padding-left: 2px;
        padding-bottom: 3px;
        padding-top: 3px;
    }
    .chat-container form .form-group{
        margin-left: 3px;
    }
}

@media  screen and (min-width: 500px) and (max-width: 870px) {
    #webinar-chatbox {
        width: 100%;
    }
    #reactionEmoji{
        margin-right: 15px;
    }
    #chatBox-btn{
        width: 100px;
        margin-left: 20px;
    }
    .container .mobile.chat-container{
        max-height: 100%;
    }
}

@media screen and (max-width:500px) {
    #chatBox-btn{
        width: 90px;
        margin-left: 20px;
    }

    .chat-container {
        height: 300px;
    }

    .chat-container .chat-log {
        padding-bottom: 40px;
    }

    #videoTopIce {
        width: 50%;
        top: -10px;
        left: -10px;
    }

    #videoBottomIce {
        width: 50%;
        bottom: -15px;
        right: -10px;
    }

    #chatBoxIce {
        position: absolute;
        top: -30px;
    }

    .chat-container form {
        width: 100%;
        padding-right: 20px;
    }

    #webinar-chatbox {
        width: 100%;
    }
}


