/* gameaction table */
.games-table-sect {
  margin-top: 4px;
}

.games-table-sect .no-data-desk {
  color: var(--color-theme);
  margin: 3% 0 0 3%;
}

.games-table-sect .no-data-mob {
  margin: 30px 0 0 5%;
  color: var(--color-theme);
}

.games-table-sect thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #23282e;
  opacity: 0.8;
  font-family: "firaSans-medium";
  padding-left: 26px;
}

.games-title {
  font-family: "firaSans-semibold";
  word-break: break-word;
}

.games-table-sect h4 {
  font-size: 20px;
  line-height: 24px;
  color: #23282e;
  font-family: "firaSans-regular";
  font-weight: normal;
}

.games-table-sect .action-group {
  display: flex;
}

/* .games-table-sect .action-group button, */
.games-table-sect .action-group img {
  margin-left: 10px;
  background: inherit;
}

.games-table-sect .action-group button.delete-icon-img {
  border: 1px solid var(--color-theme);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.games-table-sect .action-group button.delete-icon-img img {
  margin: 0;
}

.games-table-sect .action-group .customise-icon {
  width: 33px;
  height: 32px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid var(--color-theme);
  border-radius: 3px;
  margin-left: 10px;
}

.games-table-sect .action-group button:hover+.tooltip,
.games-table-sect .action-group img:hover+.tooltip,
.games-table-sect .action-group svg:hover+.tooltip {
  display: flex;
  justify-content: center;
}

.games-table-sect .action-group .btn-tooltip {
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.games-table-sect .action-group .tooltip {
  width: 80px;
  position: absolute;
  top: -55px;
  left: 30px;
  background: #404040;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}


.game-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.game-btn button {
  border: none;
  cursor: pointer;
}

.download-btn {
  width: 24px;
  height: 24px;
  background: var(--color-theme_1);
  border-radius: 4px;
  padding: 0;
  border: 0px solid gameparent;
  cursor: pointer;
}

.download-btn img {
  margin: 0;
}

.game-mode {
  background: #F1F1F1;
  border-radius: 4px;
  border: 1px solid #F1F1F1;
  display: inline-flex;
  padding: 2.5px 4px;
}

.game-mode span {
  font-size: 14px;
  line-height: 14px;
  color: #23282e;
  margin-left: 3px;
  margin-top: 3px;

}

.game-head-active {
  display: flex;
  justify-content: space-between;
}

.game-head-active h5 {
  font-size: 24px;
}

.newGame-btn {
  float: right;
}

.game-head-new-game {
  float: right;
}

.copyLink-options-row {
  display: flex;
  width: 100%;
  border: 1px solid #e5e7eb;
  flex-direction: row;
  height: 55px;
  position: relative;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336); */
}

.copyLink-options-row::after {
  content: "";
  height: 35px;
  width: 35px;
  position: absolute;
  background-color: #ffffff;
  top: -17px;
  /* left: 78%; */
  right: 169px;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336); */
  /* box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.336), 5px 0px 10px rgba(0, 0, 0, 0.336); Top and right shadow */

  /* height: 52px;
  width: 52px;
  position: absolute;
  background-color: #ffffff;
  top: 52px;
  left: -28% ; */
  border-top: 1px solid rgb(0 0 0 / 8%);
  border-left: 1px solid rgb(0 0 0 / 8%);
  transform: rotate(45deg);

}

.copyLink-options-row-td::after {
  content: "";

  height: 52px;
  width: 52px;
  position: absolute;
  background-color: #ffffff;
  top: 52px;
  left: -28% ;
  border-top: 1px solid rgb(0 0 0 / 8%);
  border-left: 1px solid rgb(0 0 0 / 8%);
  transform: rotate(45deg);
  z-index: 20;


}

@media screen and (min-width: 1264px) and (max-width: 1355px) {
  .copyLink-options-row::after {
    right: 190px;
  }
}
@media screen and (min-width: 1355px) and (max-width: 1559px) {
  .copyLink-options-row::after {
    right: 200px;
  }
}

@media screen and (min-width: 1560px) and (max-width: 1880px) {
  .copyLink-options-row::after {
    right: 221px;
  }
}

@media screen and (min-width:1549px) and (max-width: 1560px) {
  .copyLink-options-row::after {
    right: 179px;
  }
}

@media screen and (min-width: 1880px) and (max-width: 2180px) {
  .copyLink-options-row::after {
    right: 223px;
  }
}

.mobile-copyLinks-options{
  display: flex;
  width: 350px;
  border: 1px solid #e5e7eb;
  flex-direction: row;
  height: 60px;
  position: absolute;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  top: -90px;
  right: -100px;
}

.mobile-copyLinks-options::after{
  content: "";
  height: 25px;
  width: 25px;
  position: absolute;
  background-color: #ffffff;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  border-right: 1px solid rgb(0 0 0 / 8%);
  transform: rotate(45deg);
  bottom: -12px;
  right: 41%;

}

@media screen and (max-width: 421px) {
  .mobile-copyLinks-options{
    top: -152px;
    right: 60px;
    width: 200px;
    flex-direction: column;
    height: 120px;
    justify-content: space-around;
  }
  .mobile-copyLinks-options::after{
    right: 38%;
  }

  .link-type-span {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 380px) and (max-width: 400px) {
  .mobile-copyLinks-options::after{
    right: 31%;
  }
}

@media screen and (min-width: 330px) and (max-width: 370px) {
  .mobile-copyLinks-options{
    right: 22px;
  }
}
@media screen and (min-width: 300px) and (max-width: 330px) {
  .mobile-copyLinks-options{
    right: 13px;
  }
}
@media screen and (min-width: 280px) and (max-width: 300px) {
  .mobile-copyLinks-options{
    right: -2px;
  }
}
@media screen and (max-width: 280px) {
  .mobile-copyLinks-options{
    right: -8px;
  }
}

.link-type-span {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
}


@media screen and (max-width: 991px) {

  /* table responsive */
  .games-table-sect {
    margin-top: 24px;
  }

  .games-table-sect .no-data-desk {
    display: none;
  }

  .games-table-sect h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    font-family: "firaSans-semibold";
  }

  .m-games-table .c-white-card {
    margin-top: 16px;
    box-shadow: none;
  }

  .game-m-card {
    padding: 16px !important;
    margin-top: 16px;
    border: 1px solid rgba(35, 40, 46, 0.16);
    box-shadow: none;
    margin-bottom: 0 !important;
  }

  .games-m-title {
    font-family: "firaSans-semibold";
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    color: #23282e;
    word-break: break-word;
  }

  .game-m-top {
    display: flex;
    justify-content: space-between;
  }

  .game-m-bottom {
    display: flex;
    margin: inherit;
    justify-content: space-between;
  }

  .game-m-top h5 {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    margin: 0;
    font-family: "firaSans-regular";
    font-weight: normal;
  }

  .game-m-top span {
    font-size: 14px;
    line-height: 14px;
    color: #23282e;
    margin-left: 3px;
    margin-top: 3px;
  }

  .game-m-top h4 {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    font-family: "firaSans-medium";
    font-weight: 500;
  }

  .game-m-top {
    margin-bottom: 10px;
  }

  .game-m-bottom span {
    font-size: 14px;
    line-height: 17px;
    color: #23282e;
  }

  .game-m-mode {
    background: #F1F1F1;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    display: inline-flex;
    padding: 2.5px 4px;
  }

  .games-m-date {
    font-size: 14px;
  }

  .game-m-initiate {
    opacity: 0.6;
    font-size: 12px;
  }

  .game-m-user {
    font-size: 14px;
    word-break: break-word;
    width: 100%;
    word-wrap: break-word;
  }

  .game-m-bottom button.join-game {
    white-space: nowrap;
    height: 35px;
    width: 93.48px;
  }

  .game-head-active {
    display: none;
  }

  .game-m-head {
    display: flex;
    justify-content: space-between;
  }

  .game-m-head h5 {
    font-size: 18px;
  }

  .games-m-head button {
    margin-left: auto;
  }
}


/* game table */

.game-table h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #23282e;
  margin-bottom: 16px;
  font-family: "firaSans-medium";
}

.game-table.c-white-card {
  margin-bottom: 10px;
}

/* table desktop */
.game-table.mb0 h5 {
  margin-bottom: 0;
}

.game-table table {
  width: 100%;
  border-spacing: 0em 1em;
}

.game-table th {
  text-align: left;
}

.game-table thead tr {
  background: #f6f9fe;
  border-radius: 6px;
}

.game-table table tr:hover td {
  background: #f6f9fe;
}

.game-table tbody td {
  border-top: 1px solid rgba(35, 40, 46, 0.1);
  border-bottom: 1px solid rgba(35, 40, 46, 0.1);
  padding: 16px 24px;
}

.games-table-sect .game-table tbody td {
  padding: 8px 24px;
}

.game-table tbody td:first-child {
  border-left: 1px solid rgba(35, 40, 46, 0.1);
  border-radius: 6px 0 0 6px;
}

.game-table tbody td:last-child {
  border-right: 1px solid rgba(35, 40, 46, 0.1);
  border-radius: 0 6px 6px 0;
}

.game-table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}

.game-table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}

.game-table thead th {
  padding: 9px 24px;
  font-size: 14px;
  line-height: 22px;
  color: rgb(35 40 46 / 80%);
  padding-left: 26px;
}

.game-table tbody td:nth-child(6),
.game-table thead th:nth-child(6) {
  text-align: center;
}

.transaction-table-sect .game-table thead th:nth-child(4) {
  text-align: left;
}

.table-arrow {
  display: flex;
  justify-content: space-between;
}

.table-arrow-img {
  margin-left: 20px;
}

.game-viewall {
  text-align: center;
  margin-top: 8px;
}

.game-viewall button {
  font-family: "firaSans-regular";
  height: 40px;
}

.game-table .table-desc {
  align-items: center;
  display: flex;
}

/* table responive */
.table-responsive {
  display: none;
  flex-direction: column;
}

.game-t-card {
  width: 100%;
}

.game-t-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table-detail {
  display: flex;
}

.table-detail .table-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 12px;
  background: #f1f1f1;
}

.table-detail .table-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table-detail h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #23282e;
  margin-bottom: 2px;
  font-family: "firaSans-medium";
}

.table-detail span {
  font-size: 14px;
  line-height: 17px;
  /* color: #23282e; */
  /* opacity: 0.5; */
}

.table-upadte {
  padding: 2px 4px 2px 14px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  position: relative;
  font-family: "firaSans-medium";
  text-transform: capitalize;
}

.table-upadte::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  left: 5px;
  top: 7px;
}

.table-upadte.live {
  background: rgba(42, 176, 112, 0.1);
  color: #2ab070;
}

.table-upadte.live::before {
  background: #2ab070;
}

.table-upadte.complete {
  background: rgba(226, 85, 105, 0.1);
  color: #e25569;
}

.table-upadte.complete::before {
  background: #e25569;
}

.game-t-bottom {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}

.game-t-left,
.game-t-right {
  flex: 1;
}

.game-t-bottom span {
  font-size: 14px;
  line-height: 17px;
  color: #23282e;
  opacity: 0.5;
}

.game-t-bottom h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #23282e;
  margin-top: 11px;
  font-family: "firaSans-medium";
}

.animated-btn-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  position: relative;
}

.hover-tooltip {
  width: 60px;
  position: absolute;
  top: -34px;
  left: 19px;
  background: #404040;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}

.copy-type-btn-tooltip{
  width: 100%;
  height: 60px;
  position: absolute;
  top: -34px;
  left: 19px;
  background: pink;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: flex;
  transform: translateX(-50%); 
}

.hover-tooltip>span {
  font-size: 10px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.animated-btn-tooltip:hover .hover-tooltip {
  display: flex;
}

.hover-qrCode-tooltip {
  width: 70px;
  position: absolute;
  top: -34px;
  left: 19px;
  background: #404040;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}

.hover-qrCode-tooltip>span {
  font-size: 10px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.animated-btn-tooltip:hover .hover-qrCode-tooltip {
  display: flex;
}

/* copy tool tip start*/
.icon-conatiner {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  /* box-shadow: 20px 20px 15px 0 #ababab4d; */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
  cursor: pointer;
}

.svgIcon-Copy path {
  fill: #5F638A;
  ;
}

.icon-conatiner svg {
  width: 15px;
  height: auto;
}

.icon-conatiner svg:last-child {
  position: absolute;
}

.icon-conatiner:active {
  animation: press 0.2s 1 linear;
}

.icon-conatiner:active svg:last-child {
  animation: bounce 0.2s 1 linear;
}

/* .text {
  color: #666;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  user-select: none;
} */

@keyframes press {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.92);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }

  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}


/*copy tool tip end*/


/*delete tooltip start*/

.session-delete-button {
  width: 32px;
  height: 32px;
  /* border-radius: 50%; */
  border-radius: 5px;
  /* background-color: rgb(20, 20, 20) !important; */
  background-color: white;
  border: none;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164); */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
  gap: 2px;
  margin: 0 !important;
}

.svgIcon {
  width: 11px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: #5F638A;
}

.session-delete-button:hover {
  transition-duration: 0.3s;
  /* background-color: rgb(255, 69, 69) !important; */
  align-items: center;
  gap: 0;
}

.bin-top {
  transform-origin: bottom right;
}

.session-delete-button:hover .bin-top {
  transition-duration: 0.5s;
  transform: rotate(160deg);
}

/*delete tooltip end*/

/*edit anime=ated tooltip*/
.animated-editBtn {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.123); */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
}

.animated-editBtn::before {
  content: "";
  width: 200%;
  height: 200%;
  background-color: white;
  position: absolute;
  z-index: 1;
  transform: scale(0);
  transition: all 0.3s;
  border-radius: 50%;
  filter: blur(10px);
}

.animated-editBtn:hover::before {
  transform: scale(1);
}

.animated-editBtn:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
}

.animated-editBtn svg {
  height: 17px;
  fill: #5F638A;
  z-index: 3;
  transition: all 0.2s;
  transform-origin: bottom;
}

.animated-editBtn:hover svg {
  transform: rotate(-15deg) translateX(5px);
}

.animated-editBtn::after {
  content: "";
  width: 16px;
  height: 1.5px;
  position: absolute;
  bottom: 8px;
  left: -3px;
  background-color: #5F638A;
  border-radius: 2px;
  z-index: 2;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-out;
}

.animated-editBtn:hover::after {
  transform: scaleX(1);
  left: 0px;
  transform-origin: right;
}

/*edit anime=ated tooltip end*/

/*qr tooltip start*/
.qr-loader {
  width: 31px;
  height: 32px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
}

.qr-box1,
.qr-box2,
.qr-box3 {
  border: 4px solid #5F638A;
  box-sizing: border-box;
  position: absolute;
  display: block;
}

.qr-box1 {
  width: 25px;
  height: 10px;
  margin-top: 18px;
  margin-left: 3px;
}

.qr-box2 {
  width: 11px;
  height: 11px;
  margin-top: 4px;
  margin-left: 3px
}

.qr-box3 {
  width: 11px;
  height: 11px;
  margin-top: 4px;
  margin-left: 16.2px;
  animation: infinite 1.8s wink;
}

/*qr tooltip end*/

/*setting tooltip start*/
.setting-btn {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
}

.bar {
  width: 50%;
  height: 2px;
  background-color: #5F638A;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 2px;
}

.bar::before {
  content: "";
  width: 2px;
  height: 2px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #5F638A;
  transition: all 0.3s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336);
}

.bar1::before {
  transform: translateX(-4px);
}

.bar2::before {
  transform: translateX(4px);
}

.setting-btn:hover .bar1::before {
  transform: translateX(4px);
}

.setting-btn:hover .bar2::before {
  transform: translateX(-4px);
}

/*setting tooltip end*/

@keyframes wink {
  0% {
    width: 12px;
    height: 10px;
    rotate: -25deg;
    margin-top: 4px;
  }

  12.5% {
    width: 11px;
    height: 10px;
    rotate: -45deg;
    margin-top: 4px;
  }

  25% {
    width: 11px;
    height: 11px;
  }

  37.5% {
    width: 11px;
    height: 11px;
  }

  50% {
    width: 11px;
    height: 11px;
  }

  62.5% {
    width: 11px;
    height: 11px;
  }

  75% {
    width: 11px;
    height: 11px;
  }

  87.5% {
    width: 11px;
    height: 11px;
  }

  100% {
    width: 11px;
    height: 11px;
    rotate: 0deg;
  }
}

/*qr tool tip end*/

.hover-tooltip-type {
  width: 60px;
  position: absolute;
  top: -34px;
  left: 19px;
  background: #404040;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}

.hover-tooltip-type>span {
  font-size: 10px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#copy-icon-btn-tip:hover .hover-tooltip-type {
  display: flex;
}

@media screen and (max-width: 991px) {
  .game-table.c-white-card {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .game-table table {
    display: none;
  }

  .table-responsive {
    display: flex;
  }

  .game-viewall {
    display: none;
  }

  .game-t-right h6 {
    font-weight: normal;
    font-family: "firaSans-regular";
  }
}

@media screen and (max-width: 420px) {
  .game-m-bottom-mob {
    flex-wrap: wrap;
    flex-direction: column;
  }
}