.avg-wrapper {
  display: flex;
  justify-content: space-between;
}
.avg-wrapper .c-white-card {
  width: 24%;
}
.avg-left {
  padding-top: 12px;
}
.avg-title {
  font-size: 14px;
  line-height: 17px;
  color: #23282e;
  opacity: 0.8;
}
.avg-graph h5 {
  font-weight: 500;
  font-family: "firaSans-medium";
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.32px;
  color: #23282e;
  margin-bottom: 8px;
}
.avg-graph h5 span {
  font-family: "firaSans-regular";
  font-size: 14px;
  line-height: 17px;
  color: rgb(35 40 46 / 80%);
  letter-spacing: 4px;
  margin-left: 6px;
}
.avg-graph span {
  font-family: "firaSans-medium";
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
}
.avg-graph span.success {
  color: var(--color-theme);
}
.avg-graph span.danger {
  color: var(--color-theme);
}
.avg-graph {
  display: flex;
  justify-content: space-between;
}
/* total top */
.total-top {
  display: flex;
  justify-content: space-between;
}
.total-top .c-white-card {
  width: 49.3%;
}
.total-top h5 {
  font-size: 18px;
  line-height: 22px;
  color: rgb(35 40 46 / 80%);
  margin-bottom: 12px;
  font-family: "firaSans-regular";
  font-weight: 400;
}
.total-top h6 {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.32px;
  color: #23282e;
  margin-bottom: 16px;
}
.total-top li {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.32px;
  color: #23282e;
  margin-bottom: 27px;
}
.total-top .total-top-left ul {
  display: flex;
}
.total-top li {
  display: flex;
}
.total-top li span {
  width: 175px;
  display: inline-flex;
}

/* percentage card */
.percentage-card {
  margin-top: 10px;
}
.percentage-card .percentage {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.32px;
  color: #23282e;
  margin-top: 16px;
  position: relative;
  padding-left: 20px;
}
.percentage-card .percentage::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 5px;
  background: #fff;
}
.percentage-card .percentage.blue::before {
  background: #5381ef;
}
.percentage-card .percentage.green::before {
  background: #52cdd5;
}
.percentage-card .percentage.yellow::before {
  background: #ffb03d;
}
.percentage-card .percentage span {
  padding-left: 30px;
  position: relative;
}
.percentage-card .percentage span::before {
  content: "-";
  position: absolute;
  left: 14px;
}
/* second graph */
.total-top .total-top-left li:first-child {
  padding-right: 20px;
  border-right: 1px solid rgb(35 40 46 / 50%);
}
.total-top .total-top-left li:nth-child(2) {
  padding-left: 20px;
}
.total-top .c-white-card:nth-child(2) h5 {
  margin-bottom: 27px;
}
.total-top li:last-child {
  margin-bottom: 0;
}
/* played game bar */
.total-top .game-bar {
  width: calc(100% - 175px);
  font-family: "firaSans-semibold";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.32px;
  color: var(--color-theme);
  display: flex;
  align-items: center;
}
.play-game-bar {
  width: 100%;
  height: 10px;
  border-radius: 32px;
  margin-right: 12px;
  background: linear-gradient(90deg, var(--color-theme_5) 0%, var(--color-theme) 99.99%, var(--color-theme) 100%);
}
.total-top li:nth-child(2) .play-game-bar {
  width: 70%;
}
.total-top li:nth-child(3) .play-game-bar {
  width: 60%;
}
.total-top li:nth-child(4) .play-game-bar {
  width: 50%;
}
.total-top li:nth-child(5) .play-game-bar {
  width: 40%;
}
/* report-game-ques */
.report-game-ques .c-white-card {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.report-game-ques .avg-graph {
  flex-direction: column;
}
.report-game-ques h5 {
  margin: 15px 0 0;
}
.report-game-ques .avg-right {
  display: flex;
}
@media screen and (max-width: 991px) {
  .avg-wrapper {
    flex-wrap: wrap;
  }
  .avg-wrapper .c-white-card {
    width: 48%;
  }
  .total-top .c-white-card {
    width: 100%;
  }
  /* total tol */
  .total-top {
    flex-direction: column;
  }
  .total-top li {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .total-top .game-bar {
    width: 100%;
  }
  .total-top li span {
    margin-bottom: 8px;
  }
  .avg-title {
    height: 35px;
  }
  .avg-left h5 {
    font-size: 24px;
    line-height: 29px;
  }
  .avg-left span {
    font-family: "firaSans-regular";
  }
  .report-table button {
    display: none;
  }
  .report-viewall button {
    width: 165px;
    height: 40px;
    padding: 10px;
    background: white;
  }
  .avg-right img {
    width: 40px;
  }
  .report-game-ques .c-white-card {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 16px;
  }
  .report-game-ques h5 {
    margin: 4px 0 0;
    font-size: 20px;
    line-height: 24px;
  }
  .report-game-ques .avg-title {
    height: auto;
    margin-top: 16px;
  }
}
