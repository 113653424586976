.bannercard{
    width: 100%;
    /* height: 208px; */
    position: relative;
    border-radius: 10px;
}
.bannercard-img{
    height: 100%;
}
.bannercard-img img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
    cursor: pointer;
    aspect-ratio: 185/42;
}

/*banner slider*/
.toggle-container{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;

    height: 0px;
}
.bannercard .switch ,
.mobile-bannercard .switch{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}
.bannercard .switch input ,
.mobile-bannercard .switch input{ 
    opacity: 0;
    width: 0;
    height: 0;
}  
.bannercard .slider ,
.mobile-bannercard .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 4px;
}
.bannercard .slider:before ,
.mobile-bannercard .slider::before{
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 4px;
    -webkit-transition: .4s;
    transition: .4s;
}
.bannercard input:checked + .slider ,
.mobile-bannercard input:checked + .slider{
    background-color: #2AB070;
}  
.bannercard input:focus + .slider ,
.mobile-bannercard input:focus + .slider{
    box-shadow: 0 0 1px #2AB070;
}
.bannercard input:checked + .slider:before ,
.mobile-bannercard input:checked + .slider::before{
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.toggle-container .btn{
    padding: 5px 15px;
    /* -webkit-filter: invert(80%); */
    /* filter: invert(80%); */
}
/*tooltip*/
.toggle-container .btn-tooltip{
    position: relative;
    margin-left: 0px;
    margin-top: 5px;
}  
.toggle-container .btn-tooltip.hidden{
    visibility: hidden;
}
.toggle-container .btn-tooltip .switch:hover + .tooltip{
  display: flex;
  justify-content: center;
}
.toggle-container .btn-tooltip .tooltip{
    width: 130px;
    position: absolute;
    top: 30px;
    left: 70px;
    background: #404040;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    display: none;
    transform: translateX(-50%);
}
.toggle-container .btn-tooltip.join .tooltip{
    left: -30px;
    top:40px;
    width: 180px;
}
.toggle-container .btn-tooltip .tooltip:before{
    content:'';
    display:block;
    width:0;
    height:0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #404040;
    left: 10%;
    top: -12px;
    transform: rotate(90deg);
}
.toggle-container .btn-tooltip.join .tooltip:before{
    left: 80%;
}
.toggle-container  .btn-tooltip .tooltip span{
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 0;
    z-index: 1000;
}

/* banner content */
.bannercard-content{
    position: absolute;
    left: 110px;
    top: 51px;
}
.bannercard-content h5{
    color: #ffffff;
    font-size: 32px;
    line-height: 22px;
    margin: 16px 0 26px;
    font-family: 'firaSans-medium';
    font-weight: 500;
}
.banner-viewcount{
    display: flex;
}
.banner-viewcount-card{
    margin-right: 20px;
    display: flex;
}
.banner-viewcount-card span{
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 22px;
}
.banner-viewcount-card img{
    margin-right: 4px;
}
.banner-btn-grp{
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.banner-btn-grp .hide{
    display: flex !important;
    visibility: hidden;
}
.banner-btn-grp .btn-primary{
    padding: 5px;
    margin: 5px 5px;
    font-size: 13px;
    height: 30px;
}
.banner-btn-grp .btn-primary img{
    margin: 0;
}
.banner-btn-grp div{
    display: flex;
    align-items: center;
}

.banner-btn-grp .btn-primary img{
    width: 20px;
}
/* for video banner customize */
.customizeButton {
    z-index: 1;
}


.video-container {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: auto;
  }
  .video-container::before {
    content: "";
    display: block;
    padding-top: 32%; /* 1250 / 400 = 3.125 */
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .hide-elements {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
    z-index: 10;
  }

  #video_banner::-webkit-media-controls {
    display: none !important;
  }
  
  #video_banner::-webkit-media-controls-enclosure {
    display: none !important;
  }
  
  #video_banner::-webkit-media-controls-panel {
    display: none !important;
  }
  
  #video_banner::-webkit-media-controls-play-button {
    display: none !important;
  }
  
  #video_banner::-webkit-media-controls-volume-slider {
    display: none !important;
  }


@media screen and (min-width: 768px){
    .mobile-bannercard{
        display: none;
    }
}


@media screen and (max-width: 767px){
    .mobile-bannercard .toggle-container{
        padding: 0 5px;
    }
    .mobile-bannercard{
        width: 100%;
        /* height: 240px; */
        max-height: 240px;
        position: relative;
        border-radius: 10px;
    }
    .mobile-bannercard-img{
        height: 100%;
    }
    .mobile-bannercard-img img{
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
        cursor: pointer;
        aspect-ratio: 420 / 179;
    }
    /* mobile-banner content */
    .mobile-bannercard-content{
        left: 40px;
        top: 25px;
    }
    .mobile-bannercard-content h5{
        color: #ffffff;
        font-size: 18px;
        margin: 8px 0 12px;
        margin: 16px 0 26px;
        font-family: 'firaSans-medium';
        font-weight: 500;
    }
    .mobile-banner-viewcount{
        display: flex;
    }
    .mobile-banner-viewcount-card{
        margin-right: 20px;
        display: flex;
    }
    .mobile-banner-viewcount-card span{
        color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        line-height: 22px;
    }
    .mobile-banner-viewcount-card img{
        margin-right: 4px;
    }
    .bannercard{
        /*  */
        display: none;
    }
    
}
