.add-users-teams-container h4 {
    font-family: 'firaSans-medium';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: white;
}

.add-users-teams-container-two h4{
    font-family: 'firaSans-medium';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: white;
}

.add-users-teams-container p {
    align-items: center;
    color: white;
    display: flex;
    font-family: 'Fira Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.5;
}
.add-users-teams-container-two p{
    align-items: center;
    color: white;
    display: flex;
    font-family: 'Fira Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.5;
}



.overflow-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.input-text-field {
    display: flex;
    padding: 10px 12px;
    font-size: 16px;
    width: 100%;
    height: 100%;
    border: none;


}

.input-text-field:focus {
    border: none;
    outline: none;
}
.input-text-field::placeholder {
   font-size: 16px;
   font-weight: 100;
}

.btn-teams-secondry{
    color: var(--color-theme);
    /* background: transparent; */
    border: 1px solid white !important;
    /* border: 1px solid var(--color-theme) !important; */

}
.btn-teams-secondry:hover{
    color: white;
    background: var(--color-theme);

}

@media screen and (min-width:220px) and (max-width: 440px) {
    .add-users-teams-container p {
        font-size: 13px;
        line-height: 15px;
    }
    .add-users-teams-container-two p{
        font-size: 13px;
    }
    .add-users-teams-container-two h4{
      font-size: 16px;
    }
    
}