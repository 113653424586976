.outer-accordion-container{
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.accordion-item{
  width: Fill (762px)px;
  height: Hug (73px)px;
  box-shadow: 0px 4px 34px #00000029;
  gap: 0px;
  border-radius:6px;
  background-color: #fff;
  /* box-shadow: 0px 4px 34px 0px rgba(210, 210, 210, 0.25); */
}
.accordion-title {
  text-align: left;
  position: relative;
  padding: 1rem;
  cursor: pointer;

  font-size: 16px;
  line-height: 25px;

  font-weight: 600;
   background: url('../../assets/icons/ad.png') no-repeat center right 15px; /* Adjust path */
  
   @media (max-width: 600px) {
   font-weight: normal;
  }
}

.accordion-title:hover {

  background-color: #eae7e7; /* Lighter background on hover */
}

.accordion-title.open {
  background: url('../../assets/icons/au.png') no-repeat center right 15px; /* Arrow pointing up for open state */
  background-size: 24px; /* Adjust size as needed */
}

.accordion-content {
  padding: 0 15px;
  color: #919397;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out; /* Smooth height and padding transition */
  max-height: 0;
}

.accordion-content.open {
 
  max-height: 200px;
  padding: 0rem 1rem 1rem;
  font-size: 16px;
  line-height: 25px;
  color: #6c6c6c;
  text-align: left;
}
.shadowAccordion{
  box-shadow: 0px 4px 34px #00000029!important;
  border-radius: 6px!important;
}
.shdw-none{box-shadow: none!important;}

.newFontForBookEvent{
 
margin-top: 1rem!important;
  font-size: 16px!important;
  line-height: 25px!important;
  color: #23282E!important;
}