.user-signup-form {
    width: 80%;
}

.singUp-link {
    font-size: 13.34px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    color: #0b0953;
    font-weight: bold;
}

.signUp-heading {
    font-size: 1.3rem;
    color: var(--color-theme);
    font-weight: bold;
    font-family: "firaSans-semibold";

}

.singUp-link>span {
    font-weight: bold;
    color: var(--color-theme);
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.signup-form-group>label {
    font-size: 13.34px;
    line-height: 190%;
    color: #03014C;
    margin-bottom: 4px;
}

.signup-form-group>input {
    font-size: 12px;
    width: 100%;
    height: 40px;
    background: #f6f7f9;
    color: #0b0953;
    border-bottom: 1px solid #bfc1d7;
    /* border-radius: 6px; */
    padding: 5px 5px;
}
.tooltip-input-container{
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 8px;
}
.tooltip-input-container > .error-message{
    display: none;
}
.tooltip-input-container > input{
    font-size: 12px;
    width: 100%;
    height: 40px;
    background: #f6f7f9;
    color: #0b0953;
    border-bottom: 1px solid #bfc1d7;
    /* border-radius: 6px; */
    padding: 5px 5px;
    /* cursor: url("https://toppng.com/uploads/preview/text-cursor-icon-11549398328qxuanqfyfz.png"); */
    position: relative;
    z-index: 10;

}
.tolltip-error-text{

    position: absolute;
    left: -69%;
    /* top: 24%; */
    transform: translateX(7%);
    background-color:hsl(351.49deg 70.85% 60.98%);
    color: #fff;
    width: auto;
    min-width: 20px;
    max-width: 250px;
    white-space: wrap;
    font-size: 11px;
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    z-index: 10;

}
.tolltip-mid-error-text{
    position: absolute;
    left: -69%;
    /* top: 24%; */
    transform: translateX(65%);
    background-color:hsl(351.49deg 70.85% 60.98%);
    color: #fff;
    width: auto;
    min-width: 20px;
    max-width: 250px;
    white-space: wrap;
    font-size: 11px;
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    z-index: 10;

}

.tolltip-error-text-required{
    position: absolute;
    left: -32%;
    /* top: 24%; */
    transform: translateX(74%);
    background-color:hsl(351.49deg 70.85% 60.98%);
    color: #fff;
    width: auto;
    min-width: 20px;
    max-width: 250px;
    white-space: wrap;
    font-size: 11px;
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    z-index: 10;

}
.tolltip-error-text-required::before{
    content: "";
    position: absolute;
    left: 103%;
    top: 25%;
    transform: translateX(-32%);
    border: 10px solid;
    border-color: #0000 #0000 #0000 hsl(351.49deg 70.85% 60.98%);
}

.tolltip-error-text::before{

    content: "";
    position: absolute;
    left: 103%;
    top: 25%;
    transform: translateX(-50%);
    border: 10px solid;
    border-color: #0000 #0000 #0000 hsl(351.49deg 70.85% 60.98%);

}
.tolltip-mid-error-text::before{
    content: "";
    position: absolute;
    left: 104%;
    top: 25%;
    transform: translateX(-50%);
    border: 10px solid;
    border-color: #0000 #0000 #0000 hsl(351.49deg 70.85% 60.98%);
}

.form-control-phone{
    background:#f6f7f9 !important;
    color: #0b0953 !important;
    border-bottom: 1px solid #bfc1d7 !important;
    /* border-radius: 6px; */
}

.phone-input-border {
    /* border-bottom: 1px solid #bfc1d7 !important;
    background-color: #fff !important;
    color: #0b0953 !important; */
    border-color: #fc8181 !important;
}

.signup-form-group>input::placeholder {
    color: #0b0953;
    font-weight: bold;
}

.signup-form-group>input:focus {
    outline: none;
    /* background: #e1e6ea; */
}
.tooltip-input-container > input:focus {
    outline: none;
    /* background: #e1e6ea; */
}

.signup-form-group>input:hover {
    /* background: #e1e6ea; */
}

.password-container {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.password-container>input {
    position: relative;
}

.password-container>input {
    font-size: 12px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 40px;
    background: #f6f7f9;
    border: 0px;
    /* border-radius: 6px; */
    padding: 5px 5px;
    color: #0b0953;
    border-bottom: 1px solid #bfc1d7;
}

.password-container>input:focus {
    outline: none;
    /* background: #e1e6ea; */
}

.password-container>input::placeholder {
    color: #0b0953;
    font-weight: bold;

}
.password-container>input:hover {
    /* background: #e1e6ea ; */
}

input.error-input,
select.error-input {
    border-bottom-color: #fc8181 !important;
}

.pass-eye-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    cursor: pointer;
    right: 20px;
}

.saaved-popup {
    display: flex;
    width: 50%;
    height: 100%;
    background-color: #f6f8f855;
    position: absolute;
}


@media screen and (max-width: 359px) {
    .signUp-heading {
        font-size: 1rem;
        color: var(--color-theme);
        font-weight: bold;
    }
}
@media screen and (max-width: 450px){
    .pass-eye-btn{
        right: 11px;
    }
}

@media screen and (max-width: 991px) {
    .tooltip-input-container > .error-message{
        display: flex;
    }
    .tolltip-mid-error-text{
        display: none !important;
    }
    .tolltip-error-text{
        display: none !important;
    }
    .tolltip-error-text-required{
        display: none !important;
    }
}


