.games-statistics-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  padding: 2rem;
}

.statistics-item {
  text-align: center;
  margin: 10px;
  font-family: Arial, sans-serif;
  position: relative; /* Make sure to position items relatively */
}

.number-container {
  position: relative; /* Positioning context for the plus sign */
  display: inline-block;
}

.number {
  font-size: 48px;
  font-weight: bold;
  color: #558AE2;
  display: inline-block; /* Ensure the number does not affect layout shifts */
  transition: transform 0.3s ease; /* Smooth transition */
}

.plus-sign {
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  color: #558AE2;
  font-weight: 600;
}

.text {
  font-size: 22px;
  line-height: 27px;
  color: #303030;
  margin-bottom: 12px;
  font-family: 'firaSans-medium';
  font-weight: 500;
  text-align: center;
}

@media (max-width: 768px) {
  .number {
    font-size: 2.5rem;
  }
  .plus-sign {
    font-size: 1.5rem;
  }
  .text {
    font-size: 0.875rem;
  }
}
