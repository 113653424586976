.game-section-non-logged-in-user {
  display: flex;
  justify-content: center;
  
    .c-line-container {
      position: relative;
      width: 70%;
      height: 38vh;
      top: 18%;
      overflow: visible;
      @media (max-width: 600px) {
        padding: 10px;
        width: 100%;
        height: 50vh;
       
      }
  
  
      .card1 {
        overflow: visible;
        position: absolute;
        width: 14vw;
        height: 50vh;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5vw;
        color: #333;
        border-radius: 40px;
        transition: transform 0.1s ease, z-index 0.3s ease;
        transform-origin: center;
        height: 391px;
        width: 300px;
       
     

        @media (max-width: 600px) {
         
          width: 170px;
        } 
        img {
          width: 100%;
          height: 100%;
          border-radius:40px;
        }
  
        &.card-left-1 {
          width: 267px;
          height: 348px;
     
    
          top: 94%;
          left: 32%;
          transform: translate(-50%, -50%) rotate(-4.47deg);
          z-index: 2;
          @media (max-width: 600px) {
         
            top:94%;
            } 
          /* background-image: url('../../assets/icons/gamebg.jpg'); */
        }
  
        &.card-left-2 {
          width: 267px;
          height: 348px;
          top: 115%;
          left: 16%;
          transform: translate(-50%, -50%) rotate(-8.88deg);
          z-index: 1;
          /* background-image: url('../../assets/icons/gamebg.jpg'); */
        }
  
        &.card-right-1 {    width: 267px;
          height: 348px;
          top: 94%;
          right: 32%;
          transform: translate(50%, -50%) rotate(4.47deg);
          z-index: 2;
          @media (max-width: 600px) {
         
          top:94%;
          } 
          /* background-image: url('../../assets/icons/gamebg.jpg'); */
        }
  
        &.card-right-2 {
          width: 267px;
          height: 348px;
          top: 115%;
          right: 16%;
          transform: translate(50%, -50%) rotate(8.88deg);
          z-index: 1;
          /* background-image: url('../../assets/icons/gamebg.jpg'); */
        }
  
        &.card-right-3 {
          width: 267px;
          height: 348px;
          top:64%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          @media (max-width: 600px) {
           top:90%;
          }
          /* background-image: url('../../assets/icons/gamebg.jpg'); */
        }
  
        &:hover {
          z-index: 10;
          transform: scale(1);
          transition: transform 0.5s ease, z-index 0.5s ease;
          top: 65%;
          @media (max-width: 600px) {
             top: 92%;
            } 
        }
  
        &.card-left-1:hover,
        &.card-left-2:hover {
          transform: translate(-50%, -50%) scale(1.15);
        }
  
        &.card-right-1:hover,
        &.card-right-2:hover {
          transform: translate(50%, -50%) scale(1.15);
        }
  
        &.card-right-3:hover {
          transform: translate(-50%, -50%) scale(1.15);
        }
      }
  
      .strip {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #f9f9f9;
        z-index: 11;
      }
    }
  
    .c-title {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 2rem;
      color: #333;
      z-index: 5;
      margin: 0;
    }
  
    .game-card-item-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      padding: 0px 12px;
      text-align: center;
      z-index: 1;
      opacity: 0;
      position: absolute;
      width: 300px;
      background: rgba(255, 255, 255, 0.92);
      border-radius: 16px;
      top:50%;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      transition: opacity 0.4s ease-in-out; 
      min-height: 60px;
      @media (max-width: 600px) {
           width: 220px;
           bottom:35%;
           min-height: unset;
      }

      .card-center:hover & {
        opacity: 1;
      }
    }
  
    .game-card-item-overlay-button {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      background-color: #007bff;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  .movable {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 100px;
    height: 100px;
    background-color: coral;
    cursor: pointer;
  }
  
  .button-bar {
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
  
      .gamebutton {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .game-card-item-overlay {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    
    .logo img {
      max-width: 100%; /* Ensure the image scales to fit within its container */
      height: auto; /* Maintain aspect ratio */
    }
  
    .title-section {
      flex: 2;
      overflow: hidden;
      /* Adjust if needed to ensure proper space for text wrapping */
    }
  
    .button-section {
      flex: 1;
      padding: 3rem;
      background: #f9f9f9;
    }
  
    .outer-container-play {
      display: flex;
      justify-content: space-between; /* Push children to the edges */
      align-items: center; /* Center items vertically */
    
      width: 100%;
      box-sizing: border-box; /* Include padding/borders in width calculation */
      
      .left-container {
        display: flex;
        align-items: center;
        flex: 1;
        box-sizing: border-box; /* Ensure padding/borders are included in width calculation */
        
        .logo-play {
          height: 36px; /* Set a specific height */
          width: auto; /* Allow width to adjust automatically */
          box-shadow: 0 1px 7px #ccc;
          margin-right: 10px;
          padding: 4px;
          border-radius: 10px;
          /* Ensure the image does not exceed the container width */
        }
  
        .title-container-hover {
          display: flex;
          flex-direction: column;
      
          box-sizing: border-box;
          /* Add text-align: left to ensure text starts from the left */
          text-align: left; 
          align-items: flex-start;
          padding: 5%;
          .title-bold {
            font-weight: 600 !important;
          }
  
          .title-play {
            margin: 0 !important;
            font-size: 16px !important;
            color: #303030 !important;
            text-align: left !important;
            font-weight: 600;
            padding: 0px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-wrap: break-word;
            @media (max-width: 600px) {
         
              font-size: 12px !important;
              } 
          }
  
          .light{
            margin: 0 !important;
            font-size: 14px !important;
            color: #000 !important;
            text-align: left !important;
            font-weight: 400;
            padding: 0px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-wrap: break-word;
            @media (max-width: 600px) {
         
              font-size: 12px !important;
              } 
         
          }
        }
      }
  
      .right-container {
        display: flex;
        align-items: center;
        /* Ensure buttons stay at the extreme end */
        
        .button-play {
          border-radius: 9px;
          padding: 7px 21px;
          border: 1px solid red;
          background-color: transparent;
          color: red;
          cursor: pointer;
          font-size: 16px;
          margin-left: 10px;
  
          &:hover {
            background-color: orange;
            border-color: orange;
            color: #ffffff;
          }
        }
      }
    }
  }
  

  


  .shadowCard{
    box-shadow: rgba(249, 246, 246, 0.45) -14px -84px 116px -43px;
    
  }