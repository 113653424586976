
.logo-carousel {
  width: 80%;
  margin: 0 auto;
}

.logo-slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 10px;
}
