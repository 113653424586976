.zoom-container {
  position: fixed; /* Keeps the image in view */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset by half the width and height to center */
  z-index: 1000;
  width: auto; /* Allow dynamic width */
  height: auto; /* Allow dynamic height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  position: relative;
}

.zoom-image {
  display: block;
  width: 500px; /* Adjust the initial size */
  height: auto;
  transform-origin: center center; /* Scale from the center */
  transform: scale(1); /* Initial scale */
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease-in, transform 1s ease; /* Smooth transition */
}

.pop-and-zoom {
  animation: popAndZoom 1s forwards;
}

@keyframes popAndZoom {
  0% {
    transform: scale(1); /* Initial scale */
    opacity: 0; /* Start hidden */
  }
  100% {
    transform: scale(2); /* End with scaling up */
    opacity: 1; /* Fully visible */
  }
}

.close-button {
  position: absolute;
  top: -27px;
  right: -39px;
  background: #616161;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  z-index: 1001;
}

.close-button:hover {
  background: #ffffff;
  color: #000;
  border: 1px solid #000;
}

@media (max-width: 600px) {
  .zoom-image {
    width: 180px; /* Adjust for smaller screens */
  }
}

.no-scroll {
  overflow: hidden !important;
}
