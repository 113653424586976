/*controls */
.orgDetails-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 15px 0;
}
/* slider part */
.orgDetails-actions .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.orgDetails-actions .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.orgDetails-actions .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}
.orgDetails-actions .disabled {
  cursor: not-allowed;
}

.orgDetails-actions .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.orgDetails-actions input:checked + .slider {
  background-color: #2ab070;
}

.orgDetails-actions input:focus + .slider {
  box-shadow: 0 0 1px #2ab070;
}

.orgDetails-actions input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.admin-homepage .data-table .view-btn.delete {
  width: 30px;
  height: 30px;
  padding: 5px 5px;
}
/*slider end*/

.width-50 {
  width: 50%;
}

.text-span {
  position: absolute;
  float: left;
  margin-left: 45%;
  color: grey;
  top: 25%;
  cursor: pointer;
}

.text-span .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.text-span .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.text-span:hover .tooltiptext {
  visibility: visible;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  width: 40px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}

.disabled {
  cursor: not-allowed;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab070;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ab070;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.game_link {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inputField-style{
  text-overflow:"ellipsis" !important;
  width:"91%" !important
}
