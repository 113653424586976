.section6 {
  /* height: calc(100vh - 72px); */
  display: flex;
  padding: 2rem 2rem 0rem;
  flex-direction: row;
  position: relative;
  /* background: linear-gradient(109.07deg, rgba(255, 232, 234, 0.5) 3.6%, rgba(255, 242, 254, 0.5) 100%); */

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 2rem;
  }

  .left-section {
    height: 100%;
    width: 40%;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .parent {
      height: 66vh !important;
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 300px;
      padding: 10px;

      .image1,
      .image2 {
        width: 100%;
        height: auto;
      }

      .image1 {
        width: 100%;
        height: auto;
        transform: rotate(0deg);
        top: 0;
        right: 0;
      }

      .image2 {
        width: 100%;
        height: auto;
        transform: rotate(0deg);
        bottom: 0;
        left: 0;
      }
    }

    .image1,
    .image2 {
      position: absolute;
      border-radius: 15px;
      display: block;
    }

    .image1 {
      top: -4%;
      /* right: -9px; */
      z-index: 2;
      width: 85%;
      height: auto;
      transform: rotate(0deg);

      @media (max-width: 600px) {
        top: -34px;
      }
    }

    .image2 {
      top: 23%;
      left: -13%;
      z-index: 1;
      width: 95%;
      height: auto;
      transform: rotate(-3deg);

      @media (max-width: 600px) {
        bottom: -38px;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }


  .right-section {
    width: 60%;
    box-sizing: border-box;
    padding: 0 2rem;

    @media (max-width: 600px) {
      width: 100%;
      padding: 0;
    }

    h2 {
      margin-bottom: 1rem;
      font-size: 1.875rem;
      line-height: 2.25rem;
      font-weight: 600;
      color: #23282E;
    

      @media (max-width: 600px) {
        margin-top: 2rem;
        font-size: 22px;
        text-align: center;
        line-height: 32px;
      }

    }

    h3 {
      font-size: 25px;
      line-height: 30px;
      color: #23282E;
      margin-bottom: 1rem;
      font-family: 'firaSans-medium', sans-serif;
      font-weight: 400;
      text-align: left;


      @media (max-width: 600px) {
        margin-top: 2rem;
        font-size: 22px;
        text-align: center;
        line-height: 32px;
      }
    }

    p {
      font-size: 16px;
      line-height: 25px;
      color: #6c6c6c;
    text-align: left;

    }

    .games-grid-items-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      width: 100%;
      margin-bottom: 2.5rem;
      margin-top: 1rem;

      @media (max-width: 600px) {
        display: block;
      }

      .explore_card {
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
          margin-bottom: 1rem;

        }

        .card-icon {
          border-radius: 50%;
          padding: 10px;
          width: 45px;
          height: 45px;
          margin-right: 10px;
          box-shadow: 0px 4px 14px 0px rgba(204, 204, 204, 0.20);

          background-color: #ffffff;

        }

        .card-title {
          color: #23282E;
          font-size: 16px;
          line-height: 25px;
          font-weight: 400;

          @media (max-width: 600px) {

            font-size: 18px;
            text-align: center;

          }
        }
      }
    }

    .explore-more {
      text-align: left;

      @media (max-width: 600px) {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
      }

      .btn {
        display: inline-flex;
        font-size: 16px;
        letter-spacing: 0px;
        /* font-weight: 600; */
        line-height: 16px;
        text-decoration: none !important;
        /* text-transform: uppercase; */
        background-color: #242830;
        color: #fff !important;
        border: none;
        /* border-radius: 2px; */
        cursor: pointer;
        justify-content: center;
        padding: 16px 32px;
        height: 48px;
        text-align: center;
        white-space: nowrap;

        &.black {
          background-color: #000;
          color: #fff;
          font-size: 16px;

          &:hover {
            background-color: #333;
          }
        }
      }
    }
  }
}

.highlight {
  color: var(--background-theme);
  background: none;
}

.calendarBorder {
  background-color: var(--background-theme);
  color: #fff;
}

.calenderPara {
  font-size: 16px;
  line-height: 24px;
  color: #6c6c6c;
}

.calendarTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.32px;
  color: #23282E;
  margin-right: 10px;
  font-weight: 700;
}

.calendarSubTitle {
  font-size: 16px;
  line-height: 19px;
  /* letter-spacing: 0.32px; */
  color: #23282E;
  /* margin-right: 10px; */
  font-weight: 600;
}

.CalendarEventName {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.32px;
  color: #6c6c6c;
  margin-right: 10px;
  opacity: 0.7;
  margin-bottom: 5px;
}

.shadowCalenderCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 50px 0px #D5D5D540;
  border-radius: 15px;
  opacity: 1;
}

.calendarMainCard {
  background-color: #23282E;
  color: #fff;
  box-shadow: 0px 4px 34px #00000029;
  border-radius: 6px;
}

.OcasionContainerCard {
  /* height: 335px; */
  height: auto;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 34px #00000029;
  border-radius: 15px;
  position: relative;
  z-index: 12;
}

.Dei {
  box-shadow: 0px 4px 34px #00000029;
  border-radius: 6px;
}

.scrollable-container {
  height: 230px;
  /* Custom height (you can adjust this value) */
  overflow-y: auto;
  /* Enable vertical scrolling */

}
.scrollable-container-mob {
  /* max-height: 230px; */
  /* Custom height (you can adjust this value) */
  /* overflow-y: auto; */
  /* Enable vertical scrolling */

}
.calendarSubCard{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 34px #00000029;
  border-radius: 15px;
  border: none;
}

.bgMobileOcasion{
  background-color: #6c6c6c0a;
  border-radius: 6px;
}
.circle-shadow {
  position: absolute;
  top: 20%;
  left: 17%;
  width: 43%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -5px 34px 129px 37px #C5CAE9;
}
.circle-shadow1 {
  position: absolute;
  /* top: -5%; */
  left: 0%;
  width: 0%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: 71px 18px 103px 141px #dc9ca254;
}
.circle-shadowBlue {
  position: absolute;
  top: 18%;
  left: 14%;
  width: 30%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -88px 76px 16px 10px #dbdff329;
  z-index: 10;
}
.circle-shadow-yellow-right {
  position: absolute;
  left: 100%;
  width: 0%;
  height: 50px;
  border-radius: 50%;
  box-shadow: 18px 2px 51px 77px #fff9c466;
 
}
.circle-shadow2 {
  position: absolute;
  top: 6%;
  left: 40%;
  width: 20%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -5px 34px 129px 37px #c5cae999;
}
.circle-shadow3 {
  position: absolute;
  top: 6%;
  left: 72%;
  width: 20%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -5px 34px 129px 37px #fff9c4d6;
}
.circle-shadow4 {
  position: absolute;
  top: 42%;
  left: 8%;
  width: 3%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -15px 84px 72px 82px #dc9ca254;
}
.circle-shadow-yellow {
  position: absolute;
  top: 19%;
  right: 13%;
  width: 28%;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -4px 48px 130px 43px #fff9c4d6;
}

.circle-shadow-pink {
    /* position: absolute; */
    top: 63%;
    left: 1%;
    width: 164px;
    /* height: 50px; */
    border-radius: 50%;
    box-shadow: -4px 48px 84px 43px #f8bbd0b8;
}

.circle-shadow-yellow-1 {
  position: absolute;
  top: 60%;
  right: 23%;
  width: 182px;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -4px 48px 84px 43px #fff9c4d6;
}
.gradientFaq{
  background-image: linear-gradient(280deg, hsl(125deg 0% 100% / 96%) 0%, hsl(113deg 32% 96% / 1%) 55%, hsl(33deg 32% 93% / 0%) 76%, hsl(241deg 32% 89% / 61%) 88%, hsl(240deg 31% 86% / 49%) 96%, hsl(240deg 31% 82% / 0%) 100%);
}
.gradient1 {
  background-image: linear-gradient(98deg, hsl(109deg 0% 100%) 0%, hsl(54deg 100% 98% / 15%) 69%, hsl(54deg 100% 96% / 40%) 81%, hsl(54deg 99% 94% / 44%) 86%, hsl(54deg 97% 92% / 23%) 91%, hsl(66deg 96% 90% / 46%) 100%);
}

/* Default (mobile-first): hide both buttons */
.desktop-btn-down,
.mobile-btn-down{
  display: none!important;
}

/* On large screens (lg and up), show the desktop button */
@media (min-width: 1024px) {
  .desktop-btn-down {
    display: block!important;/* Show the desktop button on large screens */
  }
}

/* On screens smaller than lg, show the mobile button */
@media (max-width: 1023px) {
  .mobile-btn-down {
    display: block!important; /* Show the mobile button on small screens */
  }
}
.gradientYellow{
  
  background-image: linear-gradient(98deg, hsl(109deg 0% 100%) 0%, hsl(54deg 100% 98% / 15%) 69%, hsl(54deg 100% 96% / 40%) 81%, hsl(54deg 99% 94% / 44%) 86%, hsl(54deg 97% 92% / 23%) 91%, hsl(66deg 96% 90% / 46%) 100%);
}