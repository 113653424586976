.section8 {
  position: relative!important; /* Ensure the section has relative positioning for absolute elements */
}

.floating-gif {
  width: 100%;
  position: absolute;
  bottom: 0; /* Position at the bottom of section8 */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to center */
  z-index: 1000; /* Ensure it appears on top */
}

@media (max-width: 600px) {
  .floating-gif {
    width: 80%; /* Adjust the size for smaller screens */
  }
}