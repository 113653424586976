.questiontab-wrapper {
  width: 100%;
}
.questiontab-card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 16px;
}
.questiontab-card:last-child {
  margin: 0;
}
.questiontab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.questiontab-header span {
  font-weight: 600;
  font-family: "firaSans-semibold";
  font-size: 20px;
  line-height: 24px;
  color: rgb(35 40 46 / 50%);
}
.questiontab-header .question-time {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #23282e;
  opacity: 0.5;
  display: flex;
  font-family: "firaSans-medium";
}
.questiontab-header .question-time img {
  margin-right: 4px;
}
/* question body */
.questiontab-body {
  display: flex;
  padding: 18px 0;
  margin: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.question-img {
  margin-right: 16px;
  width: 160px;
  height: 155px;
  background: #f1f1f1;
  border-radius: 10px;
}
.question-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.question-desc h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: rgb(35 40 46 / 90%);
  margin-bottom: 16px;
  font-family: "firaSans-semiBold";
}
.question-desc .radio-check {
  width: 100%;
  margin-bottom: 14px;
}
.question-desc .radio-check:last-child {
  margin-bottom: 0;
}
/* action button */
.questiontab-footer {
  display: flex;
  align-items: center;
  margin: 0 24px;
  height: 58px;
}
.action-btn {
  display: inline-flex;
  padding: 5px 8px;
  margin-right: 16px;
  font-size: 18px;
  line-height: 22px;
  color: rgb(35 40 46 / 50%);
  text-transform: capitalize;
}
.action-btn:hover {
  background: #ffeff0;
  border-radius: 2px;
}
.action-btn img {
  margin-right: 8px;
}
.ml-auto {
  margin-left: auto;
  margin-right: 0;
  padding: 0;
}
.ml-auto img {
  margin-right: 0;
}
/* empty state */
.ques-emptystate {
  width: 100%;
  text-align: center;
}
.ques-emptystate h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #23282e;
  margin: 40px 0 16px;
}
.ques-emptystate span {
  font-size: 16px;
  line-height: 19px;
  color: rgb(35 40 46 / 50%);
}
.ques-emptystate .question-dropdown {
  position: relative;
  margin-top: 40px;
  left: auto;
  right: auto;
}
.ques-emptystate .question-dropdown .dropdownmenu {
  background: var(--background-theme);
}
.ques-emptystate .question-dropdown .dropdownmenu span {
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .questiontab-body {
    flex-direction: column;
    padding: 16px 0;
    margin: 0 16px;
  }
  .questiontab-header {
    padding: 10px 16px;
  }
  .question-img {
    width: 100%;
    height: 116px;
    margin-bottom: 16px;
  }
  .action-btn {
    margin: 0;
  }
  .action-btn span {
    display: none;
  }
  .action-btn img {
    margin-right: 0;
  }
  .ml-auto {
    margin-left: auto;
  }
  .questiontab-footer {
    margin: 0 16px;
    height: 55px;
  }
  /* empty state */
  .ques-emptystate img {
    width: 100%;
  }
  .ques-emptystate .question-dropdown .dropdownmenu {
    width: 164px;
    height: 43px;
    border-radius: 6px;
    left: auto;
    right: auto;
  }
  .ques-emptystate .question-dropdown .dropdownmenu span {
    display: block;
  }
}
