.analytics-card {
    background: #F8F9FD;
    display: block;
    width: fit-content;
    min-width: 250px;
    padding: 12px 25px 10px 20px;
    border-radius: 10px;
    margin: 10px 0px 0px 10px;
    box-shadow: 4px 4px 4px 4px rgb(0 0 0 / 0.05);
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.analytics-card .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #23282e;
    text-transform: capitalize;
}

@media screen and (max-width: 770px) {
    .analytics-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 25px;
        margin: 10px 0px 0px 0px;
    }
}

.search-bar-container {
    margin: 1rem 0 !important;
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: space-between; /* Distribute items evenly along the main axis */
  }
  
  .input-icon {
    display: flex; /* Align icon and input horizontally */
    align-items: center; /* Center items vertically */
    margin-right: 10px;
    margin-left: 0 !important;
  /* Optional: Add some spacing between input-icon elements */
  }
  
  .jzrolw {
     border-radius:0px !important;
    }

    @media (max-width: 600px) {
        .search-bar-container {
            display: block;
        }
    }

    .logo-formtable-container {
        display: flex;
        width: 100%;
      
       
      
        .left-section {
            width: 60%;
            padding: 20px 0;
         
        }
      
        .right-section {
          padding:20px;
            align-items: center;
            justify-content: center;
            
            .company-form {
                display: flex;
                flex-direction: column;
              
                justify-content: space-between;
                padding-bottom: 60px;
                background-color: aliceblue;
                box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
                padding: 1.5rem;
                border-radius: 5px;
                background-color: #fff;
                .preview-container {
                    margin-top: 15px;
              
                    .logo-preview {
                        padding: 1rem 0;
                        width: 100%;
                        height: 100px;
                        object-fit: cover;
                    }
                  }
              
                h3{
                    margin-bottom: 1rem;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                }
        
              .form-group {
                margin-bottom: 15px;
        
                label {
                  display: block;
                  margin-bottom: 5px;
                }
                .errormessage{
                  font-size: 12px;
                  color: red;
                }
                .logo-btn{
                   
                    background-color: var(--color-theme);
                    color: white;
                    border: none;
                    border-radius: 5px;
                    padding: 10px 20px;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }
        
                input[type="text"],
                input[type="file"] {
                  width: 100%;
                  padding: 8px;
                  box-sizing: border-box; 
                }
              }
            }
          }
        }