.allgames-container{
    padding:2% 5%;
    background: #fff;
    border-radius: 10px;
    min-height: 80vh;
}
.all-games .title{
    font-size: 30px;
    margin-top:10px;
    margin-bottom: 10px;
    color: #23282e;;
    font-size: 24px;
    line-height: 22px;
    text-transform: capitalize;
    font-family: "firaSans-semibold";
    font-weight: 600;    
}
.all-games .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.all-games .input-icon{
    margin-left: 0;
}

@media screen and (max-width:991px) {
    .all-games .input-icon{
        width: 307px !important;
    }
}

@media screen and (max-width:600px) {
    .allgames-container{
        padding: 3% 5%;
        margin: 0 -6%;
        border-radius: 0;
    }
    .top{
        flex-direction: column;
        gap: 10px;
    }
    .all-games .top .input-icon{
        width: 100% !important;        
    }
    .all-games .top a,
    .all-games .top .btn{
        width: 100%;
        height: 40px;
    }
}