.filter-body{
    width: 480px;
    background: #ffffff;
    border-radius: 10px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
}
.filter-body form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.filter-body form .form-group{
    width: 100%;
}
.filter-body form .btn{
    width: 100px;
    padding: 10px;
}
.filter-body form .group{
    display: flex;
    width: 100%;
    gap: 10px;
    margin:10px 0 28px;
    align-items: center;
}
.filter-body form .group .form-group{
    margin: 0;
}
.filter-body form label{
    width: 100%;
}

/* .user-list{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    background-color:  hwb(0 100% 0%);
    overflow-y: scroll;
    height: auto;
    max-height: 220px;
    top: 110%;
    z-index: 10;
}
.user-list::-webkit-scrollbar {
    width: 8px; 
}

.user-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.user-list::-webkit-scrollbar-track {
    background-color: transparent; 
}

.user-list {
    scrollbar-width: thin; 
    scrollbar-color: #ccc transparent; 
}
.user-list > li{
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    
}
.user-list > li:hover{
    background-color: hsl(0, 0%, 91%);
}


 */





 .user-list-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 110%;
    z-index: 10;
    max-height: 220px;
    overflow-y: auto; /* Changed to auto to show scrollbar only when necessary */
}

.user-list {
    width: 100%; 
    background-color: hwb(0 100% 0%);
    list-style: none; 
    padding: 0;
    max-height: 220px; 
}

.user-list > li {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #e1e6ea;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-overflow: ellipsis;
}

.user-list > li:hover {
    background-color: hwb(213 66% 14%);
}

/* Scrollbar styles */
.user-list-container::-webkit-scrollbar {
    width: 8px;
}

.user-list-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.user-list-container::-webkit-scrollbar-track {
    background-color: #ccc;
}

/* Firefox */
.user-list-container {
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}

.filter-btn-group{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}