.event-details {
    font-family: 'Fira Sans', sans-serif; /* Ensure the font matches */
  }
  
  .details-list {
    list-style-type: disc !important; /* Bullets */
    padding-left: 20px;     /* Adds some space between bullets and text */
    font-size: 16px;        /* Adjust font size as needed */
    color: #333; 
  }
  
  .details-list li {
    margin-bottom: 6px; /* Space between items */
    list-style-type: disc !important; /* Bullets */
    font-family: 'Fira Sans';           /* Text color */

  }

  .details-list li::marker {
   font-size: 20px;

  }
  
  .ball {
    animation: bounce 2.5s infinite;
  }
  
  @keyframes bounce {
    /* 0%, 100% {
      transform: translateY(0); 
    }
    50% {
      transform: translateY(15px); 
    } */

    0% {
      transform: translateY(0); /* Start at the top */
    }
    50% {
      transform: translateY(calc(100% - 65px)); /* Move to the bottom */
      animation-timing-function: ease-in; /* Slower when reaching the bottom */
    }
    100% {
      transform: translateY(0); /* Move back to the top */
      animation-duration: 1.25s; /* Twice as fast return */
      animation-timing-function: ease-out; /* Speed up when going back up */
    }
  }




  /* Custom scrollbar styles */
.react-select__menu {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 400px; /* Set max height */
}

/* Style the scrollbar */
.react-select__menu::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.react-select__menu::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
}

.react-select__menu::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}



/* Add a custom scrollbar */
select::-webkit-scrollbar {
  width: 10px; 
  cursor: pointer;
}

select::-webkit-scrollbar-thumb {
  background: #c3c3c3; /* Color of the scrollbar */
  border-radius: 4px; /* Rounded edges */
}

select::-webkit-scrollbar-thumb:hover {
  background: #c3c3c3; /* Darker color on hover */
}

select {
  position: relative; /* Ensure select's position is relative */
  z-index: 1;         /* Ensure it stays on top of other elements */
}

select option {
  position: absolute; /* Ensure dropdown options stay absolute to the select */
  top: 100%;          /* Display dropdown below the select */
  left: 0;
  width: 100%;        /* Dropdown takes the full width of the select */
  max-height: 150px;  /* Set max height for scroll behavior */
  overflow-y: auto;   /* Enable scrolling if options exceed the height */
}

