
/* top start*/
.testimonials-container .top{
    height: 450px;
    background: #23282E;
    position: relative;    
    padding: 72px 10% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.testimonials-container .top img{
    position: absolute;
    top: 72px;
    right: 0;
    width: 66%;
}
.testimonials-container .top h4{
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    width: 65%;
}
.testimonials-container .top form{
    width: 473px;
    height: 320px;
    z-index: 1;
    background: #D83307;
    border: 4px solid #FA9846;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 50px;
}
.testimonials-container .top form h5{
    color: #fff;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}
.testimonials-container .top form .form-group{
    margin-bottom: 25px;
    width: 100%;
}
.testimonials-container .top form .form-group label{
    line-height: 15px;
    color: #fff;
}
.testimonials-container .top form .form-group input,
.testimonials-container .top form .form-group input.form-control{
    background: rgb(248, 149, 123) !important;
    height: 25px !important;
    border: 1px solid #fff !important;
    border-radius: 0 !important;
    color: #fff !important;
}
.testimonials-container .top form .form-group input::placeholder{
    color: rgb(233, 233, 233);
}
.testimonials-container .top form .form-group input:-webkit-autofill,
.testimonials-container .top form .form-group input:-webkit-autofill:hover, 
.testimonials-container .top form .form-group input:-webkit-autofill:focus, 
.testimonials-container .top form .form-group input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(248, 149, 123) inset !important;
    -webkit-text-fill-color: #fff !important;
}
.testimonials-container .top form .form-group .error-message{
    color: rgb(165, 165, 165);
    bottom: -18px;
    font-size: 11px;
    font-weight: 600;
}
.testimonials-container .top form .btn.submit{
    background: #fff;
    color: #0077B6;
    height: 25px;
    font-size: 15px;
    line-height: 20px;
    border-radius: 20px;
    padding: 5px 15px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.testimonials-container .top form .btn.download{
    background: #23282E;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
}
/* top end*/

/*stats start*/
.testimonials-container .stats{
    display: grid;
    grid-template-columns: 25% auto auto auto 25%;
}
.testimonials-container .stats .count{
    font-size: 22px;
    font-weight: 600;
}
.testimonials-container .stats .desc{
    font-size: 14px;
    font-weight: 500;
}
.testimonials-container .stats div{
    color: #fff;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #0077B6;
}
.testimonials-container .stats .stat1{
    background: #0077B6;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 50%;
}
.testimonials-container .stats .stat2{
    background: #FA9846;
    padding: 0 50% 0 30px;
}
.testimonials-container .stats .stat3{
    background: #23282E;
    padding: 0 50% 0 30px;
}
.testimonials-container .stats .stat4{
    background: #E15569;
    padding: 0 50% 0 30px;
}
.testimonials-container .stats .stat5{
    background: #2AAF70;
    padding: 0 60% 0 30px;
}
/*stats end*/

/* why extramile start*/
.testimonials-container .why-extramile{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15%;
}
.testimonials-container .why-extramile h4{
    font-family:'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    color: #23282E;
    margin: 20px 0;
}
.testimonials-container .why-extramile .points{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 10px;
}
.testimonials-container .why-extramile .points .point{
    display: flex;
    flex-direction: column;    
    align-items: center;
    /* justify-content: space-around; */
    padding: 0 20px;
}
.testimonials-container .why-extramile .points .point img{
    width: 90px;    
    margin-bottom: 15px;
    height: 110px;
}
.testimonials-container .why-extramile .points .point span{
    font-family:'Fira Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #23282E;
    text-align: center;
}
/* why extramile end*/

/* how we do start*/

.testimonials-container .how-we-do{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #E6E7E8;
    padding: 20px 0;
}
.testimonials-container .how-we-do h4{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    color: #23282E;
    margin: 20px 0;
}
.testimonials-container .how-we-do .group{
    display: flex;    
    gap: 40px;
    margin: 10px 0;
}
.testimonials-container .how-we-do .group img{
    width: 280px;
}
/* how we do end*/

/*most played games start*/
.testimonials-container .most-played-games{
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    background: #0077B6;
    padding: 20px 7% 60px;
}
.testimonials-container .most-played-games h4{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    color: #fff;
    margin: 20px auto;
    width: fit-content;
}
.testimonials-container .most-played-games .games{
    /* display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
    margin: 0 0 20px; */
}
.testimonials-container .most-played-games .games img{
    width: 200px;
}
/*most played games end*/

/* corporates believs start*/
.testimonials-container .corporates-believes{
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    background: #fff;
    padding: 20px 0;
}
.testimonials-container .corporates-believes h4{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    color: #23282E;
    margin: 20px auto;
    width: fit-content;
}
.testimonials-container .corporates-believes .companies{
    /* display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 40px;
    align-items: center; */
    margin: 20px 7% 30px;
}
.testimonials-container .corporates-believes .companies img{
    width: 200px;
    max-height: 100px;
}
/* corporates believs end */

/* what they say starts */
.testimonials-container .what-they-say{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #E6E7E8;
    padding: 20px 20%;
}
.testimonials-container .what-they-say h4{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    color: #23282E;
    margin: 20px 0;
}
.testimonials-container .what-they-say .companies{
    margin: 0 0 20px;
    width: 100%;
}

/* slider css */
.testimonials-container .gameslider .slick-prev {
    left: -56px;
}
.testimonials-container .gameslider .slick-next {
    right: 20px;
}
  
.testimonials-container .what-they-say .testimonials-slider{
    overflow: hidden;
}
.testimonials-container .what-they-say .testimonials-slider .slick-dots{
    position: unset;
    margin-top: 20px;
}
.testimonials-container .what-they-say .testimonials-slider .slick-dots li{
    background: #fff !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 5px !important;
}
.testimonials-container .what-they-say .testimonials-slider .slick-dots li.slick-active{
    background: rgb(100, 100, 100) !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 5px !important;
}
.testimonials-container .corporates-believes .slick-dots li button:before {
    font-family: "firaSans-regular";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: " ";
    opacity: 0.25;
    border: 1px solid pink;
    border-radius: 100%;
    background: pink;
}  
.testimonials-container .corporates-believes .slick-dots li.slick-active button:before {
    background: red;
    height: 8.76px;
    width: 8px;
}
  
.testimonials-container .companies .slick-list .slick-track{
    align-items: center;
}


.testimonials-container .what-they-say .company-card{
    display: flex !important;
    align-items: center;
    gap: 50px;
}
.testimonials-container .what-they-say .company-card img{
    width: 145px;
}
.testimonials-container .what-they-say .company-card h5{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: #23282E;
}
.testimonials-container .what-they-say .company-card p{
    font-size: 16px;
}
/* what they say ends */

/* bottom start*/
.testimonials-container .bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #23282E;
    padding: 20px 0;
    position: relative;
}
.testimonials-container .bottom .btn{
    background: #FB9946;
    padding: 5px 20px;
    font-size: 22px;
    line-height: 30px;
}
.testimonials-container .bottom h4{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    color: #FFFFFF;
    margin: 10px 0;
}
.testimonials-container .bottom .social-medias{
    display: flex;
    gap: 25px;
    margin-bottom: 20px;
}
.testimonials-container .bottom .social-medias img{
    width: 50px;
}
.testimonials-container .bottom .goto-top{
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.testimonials-container .bottom .goto-top img{
    width: 50px;
}
.testimonials-container .bottom .goto-top span{
    color: #fff;
    font-size: 14px;
}
/* bottom end*/

@media screen and (max-width:991px) {
    .testimonials-container .top{
        padding: 72px 5% 0;
    }
    .testimonials-container .top h4{
        font-size: 30px;
    }
    .testimonials-container .how-we-do .group img{
        width: 180px;
    }
}

@media screen and (max-width: 771px) {
    .testimonials-container .stats{
        grid-template-columns: 20% 20% 20% 20% 20%;
    }
    .testimonials-container .stats .stat1{
        padding: 10px;
        justify-content: center;
    }
    .testimonials-container .stats .stat5,
    .testimonials-container .stats .stat2,
    .testimonials-container .stats .stat3,
    .testimonials-container .stats .stat4{
        padding: 10px;
    }
    .testimonials-container .why-extramile{
        padding:10px 5%;
    }
    .testimonials-container .why-extramile h4{
        font-size: 35px;
        line-height: 40px;
    }
    .testimonials-container .why-extramile .points{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 15px;
    }
    .testimonials-container .why-extramile .points .point:nth-child(-1n + 3) {
        grid-column: span 4;
    }
    .testimonials-container .why-extramile .points .point:nth-last-child(2) {
        grid-row-start: 2;
        grid-column: 3 / span 4;
    }
    .testimonials-container .why-extramile .points .point:nth-last-child(1) {
        grid-row-start: 2;
        grid-column: 7 / span 4;
    }
    .testimonials-container .how-we-do .group img{
        width: 150px;
    }
    .testimonials-container .how-we-do .group{
        gap: 20px;
    }
    .testimonials-container .bottom .btn{
        font-size: 18px;
        padding: 3px 10px;
        line-height: 25px;
    }
    .testimonials-container .bottom h4{
        font-size: 24px;
        line-height: 34px;
    }
    .testimonials-container .bottom .social-medias{
        gap: 15px;
    }
    .testimonials-container .bottom .social-medias img{
        width: 35px;
    }
    .testimonials-container .bottom .goto-top{
        right: 0%;
        top: 22px;
        transform: translateY(0%);
    }
    .testimonials-container .bottom .goto-top img{
        width: 30px;
    }
    .testimonials-container .bottom .goto-top span{
        width: 60px;
        text-align: center;
    }
}
@media screen and (max-width:650px) {
    .testimonials-container .top{
        flex-direction: column;
        height: unset;
        padding: 72px 5% 20px;
    }
}
@media screen and (max-width:550px) {
    .testimonials-container .top{
        padding-top: 80px;
    }
    .testimonials-container .top form{
        width: 100%;
        padding: 20px 20px;
        height: auto;
    }
    .testimonials-container .top h4{
        font-size: 25px;
        width: 100%;
        text-align: justify;
    }
    .testimonials-container .top form h5{
        font-size: 25px;
        line-height: 33px;
    }
    .testimonials-container .stats .count{
        font-size: 16px;
    }
    .testimonials-container .stats .desc{
        font-size: 10px;
        font-weight: 900;
    }
    .testimonials-container .stats div{
        height: 80px;
    }
    .testimonials-container .stats .stat5,
    .testimonials-container .stats .stat2, 
    .testimonials-container .stats .stat3, 
    .testimonials-container .stats .stat4,
    .testimonials-container .stats .stat1{
         padding: 5px;
     }
    .testimonials-container .why-extramile .points{
        gap: 0px;
        row-gap: 20px;
    }
    .testimonials-container .why-extramile h4{
        font-size: 25px;
        margin: 10px 0 0;        
    }
    .testimonials-container .why-extramile .points .point{
        padding: 5px;
    }
    .testimonials-container .why-extramile .points .point img{
        width: 60px;
        height: 60px;
    }
    
    .testimonials-container .how-we-do .group img{
        width: 100px;
    }
    .testimonials-container .how-we-do h4{
        font-size: 25px;
        line-height: 35px;
        margin: 0;
    }
    .testimonials-container .how-we-do .group{
        gap: 10px;
    }
    .testimonials-container .most-played-games{
        padding:10px 2% 50px;
    }
    .testimonials-container .most-played-games h4{
        font-size: 30px;
    }
    .testimonials-container .most-played-games .games img{
        width: 150px;
    }
    .testimonials-container .corporates-believes h4{
        font-size: 30px;
        line-height: 35px;
        text-align: center;
    }
    .testimonials-container .corporates-believes .companies img{
        width: 120px;
    }
}