.wheel-container {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  z-index: 1000;
}
@media (max-width: 768px) {
  .wheel-container {
    width: 200px;
    height: 200px;
  }
}
.wheel {
  border: 8px solid #C7B363;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  transition: transform 5s cubic-bezier(0.42, 0, 0.58, 1);
  overflow: hidden;
  transform-origin: center;
}

.segment {
  position: absolute;
  width: 50%;
  height: 50%;
  background: #f3f3f3;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 150px;
  font-size: 18px;
  transform-origin: 100% 100%;
}

.segment-0 { transform: rotate(0deg); }
.segment-1 { transform: rotate(90deg); }
.segment-2 { transform: rotate(180deg); }
.segment-3 { transform: rotate(270deg); }

/* Center Arrowwheel */
.arrowwheel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #C7B363;
  border-radius: 50%;
  border: 3px solid #C7B363;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Arrow Pointer */
.arrowwheel::after {
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 21px solid #C7B363;
  z-index: 1002;
}

.result {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}
.segment-0{
background-color: #531E58!important;
}
.segment-1{
  background-color: #222528!important;
}
.segment-2{
  background-color: #FF0000!important;
}
.segment-3{
  background-color: #433CA7!important;
}