.box {
    height: 30px;
    display: flex;
    cursor: pointer;
    padding: 10px 20px;
    background: #fff;
    border-radius: 30px;
    align-items: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

.box:hover input {
    width: 400px;
}

.box input {
    width: 0;
    outline: none;
    border: none;
    font-weight: 500;
    transition: 0.8s;
    background: transparent;
}

.hadder3 {
    box-shadow: 0px 3px 6px #00000029;
}

.box a .fas {
    color: #1daf;
    font-size: 10px
}

.search-input-container-expand {
    animation: spring 0.5s ease-in-out;
}

.search-input-container {
    animation: spring1 0.5s ease-in-out forwards;
}
.typefilter-text-cont{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 8px;
}
.search-filter-exit{
    color: #f5d925;
}
.headcount:hover .search-filter-exit,
.type:hover .search-filter-exit,
.duration:hover .search-filter-exit  {
    color: black;
}

  /**2 ani tick start**/

  .checkbox-label {
    font-size: 22px;
    color: #fefefe;
    /* padding: 10px 50px 10px 5px; */
    font-family: Arial, Helvetica, sans-serif;
    user-select: none;
    display: flex;
    position: relative;
    align-items: center;
    transition: all 0.3s;
    margin-bottom: 10px;
    padding-right: 35px;
  }
  
  .checkbox-tow {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: -22px;
    appearance: none;
    border-radius: 5px;
    background-color: #64ccc5;
    z-index: 2;
    transition: all 0.3s;
  }
  
  .check-box-container {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 28%;
    left: 12%;
    border-radius: 5px;
    background-color: #dafffb;
    transition: all 0.3s;
    cursor: pointer;
  }
  
  /* .checkbox-tow::before {
    content: "";
    background-color: #ffc8c8;
    position: relative;
    display: flex;
    top: 45%;
    left: 50%;
    width: 55px;
    height: 3px;
    border-radius: 25px;
    transform: translate(100px, 0px) scale(0);
    transition: ease-out 0.15s;
  } */
  
  .checkbox-tow:checked::before {
    transform: translateX(2em);
    top: 12px;
    transition: ease-out 0.15s;
  }
  
  .checkbox-tow:hover {
    transform: translate(4px, 6px);
    transition: ease-out 0.15s;
    background-color: #f5d925;
  }
  
  .checkbox-tow:checked {
    transform: translate(4px, 6px);
    transition: ease-out 0.15s;
    background-color: #f5d925;
  }
  
  .svg-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    z-index: 3;
    top: 38%;
    left: 19%;
    color: #fefefe;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    transform: rotate(0deg) scale(0);
    transition: ease-in 0.2s;
    cursor: pointer;
  }
  
  .checkbox-tow:checked ~ .svg-icon {
    transform: rotate(360deg) scale(1);
    transition: ease-in 0.2s;
  }
  
   /** 2 end tick end **/
  

@keyframes spring1 {
    0% {
        width: 470px;
        position: absolute;
        z-index: 2;
    }

    30% {
        width: 300px;
    }

    50% {
        width: 200px;
    }

    70% {
        width: 170px;
    }

    100% {
        width: 140px;
        position: relative;
        /* opacity: 1; */
    }
}

@keyframes spring {

    0% {
        width: 140px;
    }

    30% {
        width: 200px;
    }

    50% {
        width: 300px;
    }

    70% {
        width: 400px;
    }

    100% {
        width: 480px;
        /* opacity: 1; */
    }
}


.search-input-element input {
    padding-left: 10px;
    padding-right: 35px;
}

.mobile-search-input::placeholder,
.search-input-element input::placeholder {
    color: #5F638A;
}

/* Define styles for the elements */
.headcount,
.type,
.duration {
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Define keyframes animation for text animation */
@keyframes textAnimation {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.headcount:hover .text,
.type:hover .text,
.duration:hover .text {
    animation: textAnimation 0.5s ease-in-out;
}

.headcount:hover,
.type:hover,
.duration:hover {
    /* background: linear-gradient(to bottom, #3D3535 0%, #A2A2A3 100%); */
    border-radius: 4px;
    background: #f5d925;
    color: white;
}

.search-container {
    /* z-index: 2; */
    /* background: linear-gradient(to bottom, #000000 0%, #C8C8C9 100%); */
}

.css-10o0gg1 {
    background: rgb(255, 255, 255);
    padding: 0;
    border-radius: 1.5rem;
    width: 17rem;
    box-shadow: rgba(18, 14, 20, 0.16) 0px 0.5rem 2rem -0.5rem;
    pointer-events: auto;
    display: block;
}

.duration-contant {
    background: rgb(255, 255, 255);
    padding: 0;
    border-radius: 1.5rem;
    width: 10rem;
    box-shadow: rgba(18, 14, 20, 0.16) 0px 0.5rem 2rem -0.5rem;
    pointer-events: auto;
    display: block;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOutToTop {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

.animate-slide-in {
    animation: slideInFromTop 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
    /* Adjust duration and easing as needed */
}

.animate-slide-out {
    animation: slideOutToTop 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
    /* Adjust duration and easing as needed */
}



.css-mf6ln1 {
    line-height: 1.5rem;
    font-size: 1.25rem;
    border-radius: 1rem;
    background-color: rgba(18, 14, 20, 0.04);
    box-shadow: transparent 0px 0px 0px 0.125rem inset;
    height: 3rem;
    padding: 0.5rem 0px;
    text-align: center;
    font-weight: 700;
    width: 100%;
    resize: none;
    appearance: none;
}

.headcount input::placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;

}

.css-1ialerq {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 1fr 0.5fr;
}

.css-ijk8za {
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: rgba(18, 14, 20, 0.64);
}

.css-11vnmxc {
    font-weight: 800;
    line-height: 1.25rem;
    color: rgb(18, 14, 20);
}



.css-9vggtc {
    display: grid;
    /* grid-template-columns: 0.5fr 1fr; */
    gap: 1rem;
    font-weight: 700;
    color: rgb(18, 14, 20);
    padding: 1rem;
}

.heading {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(18, 14, 20);

}

.line {

    background-color: rgba(18, 14, 20, 0.12);

}

.css-1fv6dt5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

}

.para {
    font-weight: 400;
    color: rgba(18, 14, 20, 0.64);
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.gameSearchText {
    box-shadow: 0px 3px 6px #00000029;
}

.search-input-element input:focus-visible {
    border-color: #5F638A;
    outline: none;
}

.typeOnline {
    width: 30px;
}

.typeOffline {
    width: 27px;
    height: 18px;
}

.typeHybrid {
    width: 35px;
}
#filter-option-cont:hover #duration-text-cont{
    font-weight: bold;
    font-size: 0.930rem;
}

@media (max-width: 639px) {
    .mobile-search-input input {
        width: -webkit-fill-available;
    }
}