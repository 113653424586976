.reset-password-tabs-options{
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: space-between; */
}

.otp-input-field{
    background-color: #e1e6ea;    
    color: black;
    margin-top: 2px;
    margin-bottom: 10px;
    border: 1px solid #bfc1d7;
    width: 80%;
    height: 30px;
    border-radius: 6px;
    padding: 15px 10px;
    color: #0b0953;
}

.otp-input-field:focus{
    outline: none;

}
.thankyou-body > p{
    font-size: 0.7rem;
    margin-bottom: 5px;
    margin-top: -5px;
    color: #0b0953;
}



.pswd-input-container{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 23px;
    /* height: 164px; */

}

.pass-otp-input-cont{
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: end;
}
.pass-otp-input-cont > label{
    font-size: 14px;
    color: #5F638A;
}

.reset-input-field{
    display: flex;
    align-items: center;
    width: 300px;
    border: 1px solid black;
    margin: 5px;
    height: 35px;
    padding: 5px 5px;
}

.changes-btns-cont{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    margin-top: 10px;
}
#myaccount-element{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
}
.pass-rest-left-cont{
    width: 50%;
    display: flex;
    flex-direction: column;

}
.pass-reset-right-cont{
    width: 50%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}


@media screen and (max-width: 349px) {
    .thankyou-body > h5{
        font-size: 18px;
    }
}

@media screen and (max-width: 1485px) {
    /* .reset-password-tabs-options{
        width: 50%;
    } */
}

@media screen and (min-width: 1190px) and (max-width: 1731px) {
    .pass-rest-left-cont{
        width: 30%;

    
    }
    .pass-reset-right-cont{
        width: 70%;

    }
}
@media screen and (min-width:991px ) and (max-width: 1190px) {
    .changes-btns-cont{
        justify-content: center;
    }
    .reset-password-tabs-options{
        width: 100%;
        justify-content: center;
    }
    .pass-rest-left-cont{
        width: 100%;
    }
    .pass-reset-right-cont{
        display: none;
    }

    .pswd-input-container{
        flex-direction: column;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
        /* height: 372px; */
    }
    .pass-otp-input-cont{
        width: 100%;
        justify-content: center !important;
        align-items: center;
        height: auto !important;
    }
}
@media screen and (max-width: 991px) {
    .changes-btns-cont{
        justify-content: center;
    }
    .reset-password-tabs-options{
        width: 100%;
        justify-content: center;
    }
    .pass-rest-left-cont{
        width: 100%;
    }
    .pass-reset-right-cont{
        display: none;
    }

    .pswd-input-container{
        flex-direction: column;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
        /* height: 372px; */
    }
    .pass-otp-input-cont{
        width: 100%;
        justify-content: center !important;
        align-items: center;
        height: auto !important;
    }

}

@media screen and (max-width: 660px) {
    .reset-password-tabs-options{
        width: 100%;
    }

}
@media screen and (max-width:501px) {
    .pass-otp-input-cont > .form-group{
        width: 100% !important;
    }
}
@media screen and (max-width: 781px) {
    .pswd-input-container{
        flex-direction: column;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
        /* height: 372px; */
    }
    .pass-otp-input-cont{
        width: 100%;
        justify-content: center !important;
        align-items: center;
        height: auto !important;
    }
}