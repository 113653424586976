/* for my details updated UI with sidebar */
.myaccount-wrapper {
  padding: 3% 5% 3% 5%;
  background: #fff;
  min-height: 80vh;
  margin: -2% -3% 0% 0%;
  border-radius: 20px;
}

.organisation-details-wrapper {
  padding: 3% 5%;
}

/* */
.myaccount-heading h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #23282e;
  font-family: "firaSans-semibold";
  margin-bottom: 25px;
}

.organization-details h4.title {
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #23282E;
  font-style: normal;
  opacity: 0.8;
}

.organization-details .group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* font-family: 'Fira Sans'; */
  font-style: normal;
}

.organization-details .group .form-group {
  width: 48%;
}

.organization-details .group .form-group input {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  font-style: normal;
}

.organization-details hr {
  color: #000;
  opacity: 0.3;
}

.organization-details .details-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.organization-details .details-top .counts h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* font-family: 'Fira Sans'; */
  font-style: normal;
  color: #23282E;
}

.organization-details .details-top .counts p {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.8px;
  /* font-family: 'Fira Sans'; */
  font-style: normal;
  color: #23282E;
}

.organization-details .details-top .right {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}

.organization-details .details-top .right .btn-secondry {
  width: 200px;
  margin-left: 20px;
}

.organization-details .details-top .right .btn-secondry-owner {
  width: 230px;
}

/* .organization-details .details-top .right .input-icon{
  padding: 13px;
} */
.organization-details .game-table .action-group span,
.organization-details .table-responsive .action-group span {
  margin: 0px 8px;
  cursor: pointer;
}

.organization-details .report-table .game-table tbody td {
  /* padding: 10px 24px; */
  cursor: auto;
}

.organization-details .report-table .no-users {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  align-items: center;
}

.organization-details .report-table .no-users h4 {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #23282E;
  opacity: 0.5;
}

.organization-details .table-responsive .c-white-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.organization-details .table-responsive .t-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.organization-details .table-responsive .t-top span.role {
  /* font-family: "Fira Sans"; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #23282E;
  opacity: 0.6;
}

.organization-details .table-responsive .t-top h4 {
  /* font-family: "Fira Sans"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #23282E;
}

.organization-details .table-responsive .t-bottom .name {
  /* font-family: "Fira Sans"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #23282E;
}

.organization-details .table-responsive .t-bottom .email {
  /* font-family: "Fira Sans"; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #23282E;
}

.organization-details .mobile-view {
  display: none;
  margin: 15px 0px;
}

.account-upload {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
  justify-content: space-between;
}

.account-upload .upload-group {
  display: flex;
  /* align-items: center; */
}

.account-upload .color-change .form-group .form-select {
  width: 300px
}

.account-upload .color-change .form-group .select-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-upload .color-change .form-group .select-item .color-box {
  height: 25px;
  width: 25px;
}

.account-upload .color-change .form-group .form-select .react-select__single-value,
.account-upload .color-change .form-group .form-select .react-select__control--is-focused .react-select__placeholder {
  width: 95%;
}

.account-upload .account-profile {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-upload .account-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.account-upload .account-profile-name span {
  font-size: 13px;
  line-height: 16px;
  color: rgb(35 40 46 / 50%);
  margin-top: 4px;
  display: flex;
}

.account-upload .cstm-upload-btn button {
  margin-top: 8px;
}

.account-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-form .form-group {
  margin-bottom: 15px;
}

.account-form .leave-container,
.organization-details .leave-container {
  width: 100%;
  margin-top: 20px;
}

.account-form .leave-container hr,
.organization-details .leave-container hr {
  color: #000000;
  opacity: 0.3;
}

.account-form .leave,
.organization-details .leave {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(35, 40, 46, 0.16);
  padding: 20px 15px;
  border-radius: 8px;
  align-items: center;
}

.organization-details .leave-owner {
  display: block;
}

.organization-details .leave-owner .btn-group {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.organization-details .leave-owner .btn-group button {
  padding: 5px 20px;
}

.account-form .leave h4,
.organization-details .leave h4 {
  /* font-family: "Fira Sans"; */
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: #23282E;
}

.account-form .leave .btn-outline,
.organization-details .leave .btn-outline {
  /* font-family: "Fira Sans"; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: #23282E;
}

.account-form .leave .btn-outline,
.organization-details .leave .btn-outline {
  padding: 5px 20px;
}

.account-btn-group {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.account-btn-group button,
.account-btn-group a {
  width: 164px;
  height: 43px;
  padding: 8px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
}

.account-btn-group button:last-child {
  margin-right: 0;
}

.account-form input:hover {
  background: #e1e6ea;
  cursor: pointer;
}

.account-form input {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #23282E;
}

.account-form input:focus {
  background-color: #fff;
  border: 1px solid #e25569;
}

.org-tip {
  display: flex;
}

.common-owner-container {
  margin: 40px 0 0;
  border-top: 1px solid #ccc;
  padding: 20px 0 0;
}

.common-owner-container h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #23282e;
  font-family: "firaSans-semibold";
  margin-bottom: 10px;
}

.common-owner-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.common-owner-container form .form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.common-owner-container form .form-group .form-select {
  width: 200px;
}

.common-owner-container form .btn {
  width: 150px;
}

.upload-sigin-pageBanner-cont {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sigin-BannerName {
  font-size: 0.6rem;
  width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.upload-cstm-sigin-btn {
  margin-left: 10px;
  width: 10%;
  height: 50px;
  background-color: var(--background-theme);
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
}

.org-detail-cont {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.org-logo-banner-info-cont {
  display: flex;
  align-items: center;
  width: 35%;
  flex-direction: row;
}

/* .btn-remove{
  width: 10px;
  margin-top: 0px;
} */
#table-upload {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#tb-thead {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 15px;
}

#tb-thead-tr {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#tb-thead-tr>th {
  /* font-size: 1rem; */
  color: #5F638A;
  /* font-weight: bold; */
}

.orgs-uploads-cont {
  display: flex;
  /* align-items: center; */
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

#table-upload {
  display: none;
}

.upload-container {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 47%;
}

.upload-container>h5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #5F638A;
  margin-bottom: 15px;
}

.my-account-info {
  display: flex;
  flex-direction: row;
}

.my-detail-form {
  width: 50%;
}

.ac-pg-img-cont {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-100{
  width: 90%;
}

@media screen and (min-width: 1120px) {
  .upload-cstm-sigin-btn {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1120px) {
  .upload-cstm-sigin-btn {
    font-size: 0.8rem;
  }

  .ac-pg-img-cont {
    display: none;
  }

  .my-detail-form {
    width: 100%;
  }
  .my-account-info{
    align-items: center;
    justify-content: center;
  }
  .account-form{
    align-items: center;
  }
  .form-100{
    width: 50%;
  }
}


@media screen and (max-width: 762px) {
  .orgs-uploads-cont {
    display: flex;
    /* flex-direction: column; */
  }

  #table-upload {
    display: none;
  }

  .account-upload .color-change .form-group .form-select {
    width: 215px;
  }

  .account-upload {
    margin-bottom: 0px !important;
  }

  .upload-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 33.33%;
  }
}

@media screen and (max-width: 601px) {
  .orgs-uploads-cont {
    display: flex;
    flex-direction: column;
  }

  #table-upload {
    display: none;
  }

  .account-upload .color-change .form-group .form-select {
    width: 215px;
  }

  .account-upload {
    margin-bottom: 0px !important;
  }

  .upload-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .orgs-uploads-cont {
    display: flex;
  }

  #table-upload {
    display: none;
  }

  .account-upload .color-change .form-group .form-select {
    width: 220px;
  }

  .myaccount-wrapper {
    margin: 0;
  }

  .myaccount-heading h5 {
    font-size: 18px;
    line-height: 22px;
  }

  .account-upload {
    margin-bottom: 32px;
  }

  .account-btn-group button {
    /* width: 48%; */
    max-width: 140px;
  }

  .myaccount-wrapper {
    margin-top: 8px;
    padding: 4.88%;
  }

  .account-btn-group {
    margin-top: 21px;
  }

  .account-btn-group a {
    margin-right: 0;
  }

  .organization-details .details-top .right .btn-secondry {
    width: 320px;
  }

  .organization-details .details-top .right .btn-secondry-owner {
    width: 350px;
    height: 40px;
    font-size: 14px;
    margin-left: 10px;
  }

  .organization-details .details-top .right .input-icon {
    padding: 9.5px;
  }

  .organization-details .leave-owner .btn-group button {
    margin: 5px 5px;
  }

  .common-owner-container h5 {
    font-size: 18px;
    line-height: 22px;
  }
  .form-100{
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .org-detail-cont {
    width: 100%;
    flex-direction: column;
  }

  .organization-details .group {
    display: block;
  }

  .organization-details .details-top .right {
    width: 200px;
  }

  .organization-details .group .form-group {
    width: 100%;
  }

  .organization-details .desk-view {
    display: none;
  }

  .organization-details .mobile-view {
    display: flex;
  }

  .account-form .leave-container .leave {
    display: block;
  }

  .account-form .leave-container .leave button {
    margin-top: 15px;
    width: 100%;
  }

  .upload-sigin-pageBanner-cont {
    align-items: normal;
    margin-bottom: 20px;
    border-bottom: 1px solid #bfc1d7;


  }

  .form-group label {
    font-size: 13px;
  }

  .organization-details .leave-container .leave {
    display: block;
  }

  .organization-details .leave-container .leave .btn-outline {
    width: 100%;
    margin-top: 20px;
  }

  .organization-details .leave-owner .btn-group {
    margin-top: 0px;
    border: 0;
  }

  .organization-details .leave-owner .btn-group button {
    margin: 5px 0px;
  }

  .account-upload .upload-group {
    margin-bottom: 0px;
    border-bottom: 1px solid #bfc1d7;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .common-owner-container {
    margin-bottom: 30px;
  }

  .common-owner-container form .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .common-owner-container form .form-group .form-select {
    width: 100%;
  }

  .common-owner-container form .btn {
    width: 100%;
  }

  .upload-group>button {
    width: 40%;
  }
}


/*new ui*/

/* Container */
.light-beam-container {
  position: relative;
  width: 25%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(115deg, #17EAD9 0%, #6078EA 100%) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  box-shadow: 0px 4px 34px #00000029;
  overflow: hidden;
}

.light-beam-container-loadiing {
  position: relative;
  width: 25%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  /* box-shadow: 0px 4px 34px #00000029; */
  overflow: hidden;
}
@media (min-width:640px) and (max-width:1024px){
  .light-beam-container{
    /* display: none; */
    width: 100%;
  }
  .light-beam-container-loadiing{
    width: 100%;
  }
}
@media (min-width:100px) and (max-width:640px){
  .light-beam-container{
    /* display: none; */
    width: 100%;
    /* height: 200px; */
  }
  .light-beam-container-loadiing{
    width: 100%;
    /* height: 200px; */
    }
}
/* Light beam effect */
.light-beam-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /* border-radius: 100%; */
  background: conic-gradient(
    rgba(255, 255, 255, 0.2) 0deg,   /* Faint start of beam */
    rgba(255, 255, 255, 0.2) 22.5deg,
    transparent 22.5deg,
    transparent 45deg,
    rgba(255, 255, 255, 0.2) 45deg,
    rgba(255, 255, 255, 0.2) 67.5deg,
    transparent 67.5deg,
    transparent 90deg,
    rgba(255, 255, 255, 0.2) 90deg,
    rgba(255, 255, 255, 0.2) 112.5deg,
    transparent 112.5deg,
    transparent 135deg,
    rgba(255, 255, 255, 0.2) 135deg,
    rgba(255, 255, 255, 0.2) 157.5deg,
    transparent 157.5deg,
    transparent 180deg,
    rgba(255, 255, 255, 0.2) 180deg,
    rgba(255, 255, 255, 0.2) 202.5deg,
    transparent 202.5deg,
    transparent 225deg,
    rgba(255, 255, 255, 0.2) 225deg,
    rgba(255, 255, 255, 0.2) 247.5deg,
    transparent 247.5deg,
    transparent 270deg,
    rgba(255, 255, 255, 0.2) 270deg,
    rgba(255, 255, 255, 0.2) 292.5deg,
    transparent 292.5deg,
    transparent 315deg,
    rgba(255, 255, 255, 0.2) 315deg,
    rgba(255, 255, 255, 0.2) 337.5deg,
    transparent 337.5deg,
    transparent 360deg
  );

  z-index: 0;
  /* animation: fade-light-beams 3s linear infinite ; */
  /* animation: rotate-light-beams 3s linear infinite, fade-light-beams 3s linear infinite ; */
}


@keyframes rotate-light-beams {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

}

@keyframes fade-light-beams {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}


.progress-bar-full {
  background:  transparent linear-gradient(90deg, #42E695 0%, #3BB2B8 100%) 0% 0% no-repeat padding-box;
  transition: width 0.3s ease-in-out;
}
.progress-bar-incompete {
  background: transparent linear-gradient(90deg, #42E695 0%, #3BB2B800 100%) 0% 0% no-repeat padding-box;
  transition: width 0.3s ease-in-out;

}

.profile-progress-bar-full {
  background:  transparent linear-gradient(180deg, #FCE38A 0%, #F38181 100%) 0% 0% no-repeat padding-box;
  transition: width 0.3s ease-in-out;
}
.profile-progress-bar-incompete {
  background: transparent linear-gradient(180deg, #FCE38A 0%, #F38181 100%) 0% 0% no-repeat padding-box;
  transition: width 0.3s ease-in-out;

}


.confetti {
  background: 
    radial-gradient(circle, rgba(255,255,255,0.4) 10%, transparent 10%) 0 0 / 20px 20px,
    radial-gradient(circle, rgba(255,255,255,0.3) 10%, transparent 10%) 10px 10px / 20px 20px,
    radial-gradient(circle, rgba(255,255,255,0.2) 10%, transparent 10%) 20px 20px / 20px 20px;
  animation: confetti-move 10s infinite linear;
}

@keyframes confetti-move {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px;
  }
  100% {
    background-position: 100% 100%, 110px 110px, 120px 120px;
  }
}