.add-game-in-slogan-modal .react-select__menu-list{
    overflow: auto;
    overflow-x: hidden;
}
 .add-game-in-slogan-modal .react-select__menu-list::-webkit-scrollbar{
    width: 5px;  
}
.add-game-in-slogan-modal .react-select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
.add-game-in-slogan-modal .react-select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
}
.add-game-in-slogan-modal .react-select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
} 

.add-game-in-slogan-modal .add-slogan-field::placeholder{
    color: #333333;
}
.add-game-in-slogan-modal form .select span.game-count{
    font-size: 12px;
    margin-left: 5px;
    position: absolute;
    bottom: -20px;
    display: flex;
    align-items: center;
    color: #f2545b;
}

.add-default-select-item{
    display: flex;
    justify-content: space-between;
    cursor: auto !important;
    background: #fff;
    width: 100%;
    padding: 10px;
    align-items: center;
}
.add-default-select-item h4{
    margin-right: 30px;
}
.add-default-select-item input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 0px;
    height: 0px;
}  
.add-default-select-item input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 4px solid #FFCB9A;
    border-radius: 20px;
    background-color: #445768;  
    transition: all 0.2s linear;
    left: -30px;
    top: -18px;
}  
.add-default-select-item input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 4px solid #FFCB9A;
    border-radius: 3px;
    background-color: #445768;
    left: -30px;
    top: -18px;
}
.add-default-select-item input[type=checkbox]:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border: solid #FFCB9A;
    border-width: 0 0px 0px 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    transition: all 0.2s linear;
    left: -20px;
    top: -12px;
}
.add-default-select-item input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid #FFCB9A;
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: -20px;
    top: -12px;
}

#add-game-in-slogan-modal-home form{
    min-width: 450px;
}

#add-game-in-slogan-modal-home{
    position: fixed !important;
    top: 0 !important;
    z-index: 100;
}

#add-game-in-slogan-modal-home .drag-item{
    height: 100px;
    width: 100px;
}

#add-game-in-slogan-modal-home .react-select__menu{
    z-index: 101 !important;
}

#add-game-in-slogan-modal-home .game-title{
    display: none;
}

.owner-scroll-design{
    width: 100%;
    height: 10%;
    max-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media screen and (max-width: 620px) {
    #add-game-in-slogan-modal-home form{
        min-width: inherit;
    }
}

.sloganTypeError{
    font-size: 12px;
    margin-bottom: 8px;
    /* position: absolute; */
    /* bottom: 78px; */
    display: flex;
    align-items: center;
    color: #f2545b;
}