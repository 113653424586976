.error-state {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  max-width: 960px;
  margin: auto;
}
.error-state-img img {
  margin-right: 90px;
}
.error-state-img.session-expired img{
  width: 60%;
}
.flex-column .error-state-img img {
  margin-right: 0px;
}
.error-state h5 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #23282e;
  margin-bottom: 24px;
}
.error-state-desc span {
  font-size: 24px;
  line-height: 29px;
  color: #23282e;
  margin-bottom: 72px;
  display: flex;
}
.error-state p {
  font-size: 18px;
  line-height: 140%;
  color: rgba(35, 40, 46, 0.7);
  margin-bottom: 65px;
}
.error-state .btn-secondry {
  width: 200px;
}
.error-state .btn-back {
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #e25569;
  background: transparent;
  outline: 0;
  padding: 0;
}
.error-state .btn-back img {
  transform: rotate(-180deg);
}

@media screen and (max-width: 991px) {
  .error-state {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    text-align: center;
  }
  .flex-column {
    flex-direction: column-reverse;
  }
  .error-state h5 {
    font-size: 16px;
    line-height: 150%;
    color: #23282e;
    margin-bottom: 12px;
  }
  .error-state p {
    font-size: 12px;
    line-height: 140%;
    color: rgba(35, 40, 46, 0.7);
    margin-bottom: 32px;
  }
  .error-state span {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #23282e;
    margin-bottom: 40px;
  }
  .error-state img {
    margin: 0;
  }
  .error-state-img img {
    width: 70%;
    margin-bottom: 40px;
  }
  .error-state .btn-back {
    font-size: 16px;
    line-height: 19px;
  }
}
