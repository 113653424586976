.containerBookEvnt {
width: 50%;
}




.book-event-category-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.book-event-category-form h3 {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.book-event-category-form .form-group {
  margin-bottom: 1.2rem;
}

.book-event-category-form .form-group label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  text-align: left;
}

.book-event-category-form .form-group label.errormessage {
  font-weight: normal;
  color: red;
  font-size: 0.875rem;
  text-align: left;
}

.book-event-category-form .form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.book-event-category-form .form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.book-event-category-form .form-group .errormessage {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: left;
}

.book-event-category-form .preview-container {
  margin-top: 1rem;
  text-align: center;
}

.book-event-category-form .preview-container .logo-preview {
  max-width: 100%;
  max-height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.book-event-category-form .btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
 
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.company-table {
  width: 100%;
  border-collapse: collapse;
 
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.company-table th, 
.company-table td {
  padding: 1rem;
  text-align: left;

}

.company-table th {

 
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.company-table td {
  font-size: 1rem;
}


.company-table td input[type="checkbox"] {
  transform: scale(1.2);
}

.company-table td .title {
  font-weight: bold;
}

.company-table td .image {
  max-width: 80px;
  max-height: 80px;
  border-radius: 4px;
  object-fit: cover;
}

.company-table td .btn-delete {
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  color: #fff;
  background-color:#ea6c00;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.company-table td .btn-delete:hover {
  background-color: #cc0000;
}
.textRight{    display: flex;
  justify-content: end;
}

.imgFit{
  width: 100%;
  height: 100%;
  object-fit: fill;
  aspect-ratio: 2 / 3;
  border-radius: 6px;
}


.button-add-game {
  background-color: initial;
  background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
 font-size: 14px!important;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
}

.button-add-game:hover {
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
}

/* App.css */
.container-feature {
  width: 100%; /* Ensure container takes full width */
  padding: 0 20px; /* Optional: add padding to the container */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  margin-top: 1rem;
}

.formRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #f6f7f9;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  /* margin-bottom: 20px; */
  align-items: center;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left; /* Center the content horizontally */
  margin-right: 10px; /* Space between columns */
}

.form-column:last-child {
  margin-right: 0; /* Remove margin from the last column in a row */
}

.remove-column {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.image-title {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center; /* Center the title text */
}

.preview-image {
  height: 150px; /* Fixed height for the preview image */
  width: auto; /* Automatically adjust width to maintain aspect ratio */
  margin-top: 10px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.remove-button {
  background-color: #dc3545!important;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.remove-button:hover {
  background-color: #c82333;
}

.add-row-button {
  background-color: #007bff!important;
  color: white;
  border: none;
  padding: 6px 14px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;

}

.add-row-button:hover {
  background-color: #0056b3;
}
.highlighted-event {
  background-color: lightblue;
  color: black;
  border-radius: 5px;
  padding: 5px;
  border: none;
}
