.stream-container {
    /* padding-top: 95px; */
}

.stream-container .stream-body {
    /* position: relative;
    width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
}

/*.stream-container .stream-body div{
    width: 100%;
    height: 100%;
}
.stream-container .stream-body .envelope{
    position: absolute;
    top: 0;
} */

.video-js.vjs-fluid {
    width: 100% !important;
    max-width: 100% !important;
    padding-top: inherit !important;
    height: 70vh !important;
}

.video-js{
    background-color: #2c2648 !important;
}

.stream-container .stream-body .instructions {
    width: 70%;
    padding: 12px 0 0;
}

.stream-body .instructions h4 {
    font-size: 18px;
    color: #E25569;
}

.stream-body .instructions ul {
    padding: 0 25px;
    overflow: hidden;
}

.stream-body .instructions ul,
.stream-body .instructions li {
    list-style-type: square;
}

.stream-body li {
    line-height: 25px;
}

.stream-body .instructions li::marker {
    font-size: 25px;
}

.stream-container .not-live {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80vh;
}

.stream-container .not-live h4 {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 24px;
    color: #23282E;
    opacity: 0.5;
    margin-bottom: 10px;
}

.stream-container .not-live h5 {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #E25569;
    text-decoration: underline;
}

.stream-container .stream-body .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.stream-container .mobile.chat-container {
    display: none;
}

.stream-container .reactions {
    display: flex;
    padding: 10px 5px 10px 0;
    position: absolute;
    right: 32%;
    bottom: 20px;
    z-index: 1;
    flex-direction: column;
}
.stream-container .reactions .items{
    transition: all 1s ease;
    max-height: 300px;
    overflow-y: hidden;
}
.stream-container .reactions .items.collapse{
    max-height: 0;
}
.stream-container .reactions .reaction {
    cursor: pointer;
    text-align: center;
    height: 30px;
    width: 35px;
}
.stream-container .reactions .reaction:hover {
    /* transform: scale(1.5); */
    font-size: 25px;
}
.stream-container .video-container .expand-btn{
    color: white;
    font-size: 25px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    transition: all 1s ease;
}
.stream-container .video-container .expand-btn.collapse{
    transform: rotate(180deg);
}
.desktop-christmas-stream-container{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    width: 100%;
    height: 100vh;
    position: absolute
}
/* #videoContainer{
    border: #D2D8FA solid 10px;
}
#videoContainer img{
    position: absolute !important;
} */
#liveStreamContainer{
    width: 60% !important;
}
#liveStreamChatContainer{
    width: 25% !important;
    margin-left: 16px;
}
#liveStreamContainerBorder{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: absolute;
    z-index: 1;
    width: 60%;
    height: -webkit-fill-available;
    pointer-events: none;
}
#liveStreamChatContainerBorder{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: absolute;
    z-index: 1;
    width: 25%;
    height: -webkit-fill-available;
    pointer-events: none; 
}
#liveStreamContainerVideo{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 5px;
}
#liveStreamChatContainerVideo{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
}
#liveStreamChatContainerBorderMobile, #liveStreamChatContainerMobile{
    display: none;
}
.mobile-christmas-stream-container{
    display: none;
}
@media screen and (max-width:1320px) {
    .stream-container .reactions{
        right: 33%;
    }    
}
@media screen and (max-width:1220px) {
    .stream-container .reactions{
        right: 37%;
    }    
}
@media screen and (max-width:870px) {
    .video-js.vjs-fluid {
        height: 37vh !important;
        /* width: 90% !important; */
        /* max-width: 90% !important; */
    }
    .stream-container .stream-body{
        padding-top: 100px;
    }

    .stream-container .reactions {
        right: 8%;
        bottom: -5px;
    }
    .stream-container .reactions .reaction{
        height: 25px;
        width: 25px;
    }

    .stream-container .not-live h4 {
        font-size: 30px;
    }

    .stream-container .stream-body .instructions {
        width: 90%;
    }

    .stream-container .mobile.chat-container {
        display: flex;
        /* margin-bottom: 70px; */
        /* width: 100%; */
        max-height: 100%;
    }

    .stream-container .desktop.chat-container {
        display: none;
    }
/* 
    #videoContainer{
        border: #D2D8FA solid 5px;
        margin-bottom: 50px;
    } */
    #liveStreamContainerBorder{
        width: 90%;
        margin: auto;
    }
    #liveStreamChatContainer{
        display: none;
    }
    #liveStreamChatContainerBorder{
        display: none;
    }
    #liveStreamContainer{
        width: 90% !important;
        height: 40vh !important;
        margin: auto;
    }
    #liveStreamContainerVideo{
        padding: 0px;
        padding-top: 10px;
        padding-left: 5px;
    }
    #liveStreamChatContainerMobile{
        display: block;
        width: 90%;
        margin: auto;
        height: 300px;
        position: relative;
    }
    #liveStreamChatContainerBorderMobile {
        display: block;
        position: absolute;
        /* background-size: 100% 100%;
        background-repeat: no-repeat;
        background-origin: content-box; */
        z-index: 1;
        width: 100%;
        height: inherit;
        pointer-events: none;
    }
    #liveStreamChatContainerVideoMobile{
        height: 300px;
    }
    .desktop-christmas-stream-container{
        display: none;
    }
    .mobile-christmas-stream-container {
        display: block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-origin: content-box;
        width: 100%;
        height: 100vh;
        position: absolute
    }
}

@media screen and (max-width: 500px) {
    #liveStreamChatContainerBorderMobile {
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 310px;
    }
}