.create-team-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 28px;

}

  .inner-content-container {
    overflow-x: hidden; 
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;  
    scrollbar-width: auto;     
}

/* For Webkit browsers (Chrome, Safari) */
.inner-content-container::-webkit-scrollbar {
    width: 20px;
    height: 20px;
}
.inner-content-container::-webkit-scrollbar-thumb {
    border-radius: 10px; 
}
.inner-content-container::-webkit-scrollbar-track {
    background-color: transparent; 
}
.inner-content-container {
    scrollbar-width: thin; 
}
  

.create-Team-names-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background-color: pink; */
    /* min-height: 450px; */
    height: 450px;
    width: 570px;
    padding: 10px;

}

.input-delete-div-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 80px !important;
  width: 100%;
}

.create-team-input {
    width: 448px;
    height: 50px !important;
    /* margin-bottom: 10px; */
    border-radius: 10px;
    border: none;
    background-color: #F9F9F9;
    color: #374565;
    font-size: 18px;
    padding-left: 10px;
    font-weight: 600;

}
.create-team-input::placeholder{
    color: #374565;
    font-weight: 600;
}
.create-team-input:focus{
    outline: none;
    border: none;
}


.teams-lists{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.teams-div-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.team-col-one{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.user-search-cont{
    display: flex;
    align-items: center;
    position: relative;
    width: 95%;
    height: 45px;
    margin-top: 10px;
}
.user-search-cont > input,
.user-search-cont > input::-webkit-input-placeholder {
    color: #374565;
    font-size: 15px;
    font-weight: 600;
  }
  .user-search-cont > input,
  .user-search-cont > input:focus {
    border: none;
    outline: none;
  }
  .team-members{
    display: flex;
    background-color: white;
    height: 276px;
    overflow: scroll;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    flex-direction: column;
    /* margin-top: 10px; */
}

.team-members::-webkit-scrollbar {
    width: 0; 
    height: 0; 
  }
.team-score-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: wheat;
  width: 100%;
  height: 30px;
}
.team-score-cont > span {
  color: #374565;
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  .team-members >table{
    background-color: #FEF6F6;
  }

  .table-div-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 276px;
  }

  .head-cont{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .table-body-cont{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .table-row-cont{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }