.form-container {
    display: flex;
    width: 509px;
    height: 509px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.login-type-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-items: center;
    margin-left: -10%;
}

#pass-login-btn {
    width: 30%;
}

.user-login-form {
    width: 80%;
    margin-top: 5%;
}


.singUp-link {
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-end;
}

.singUp-link>span {
    font-weight: bold;
    color: #e75000;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.password-container {
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.sigin-page-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    /* background-color: aquamarine; */
}

.siginpage-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
    cursor: pointer;
    background-color: #F2ECE8;
    /* color: white; */
    font-size: 16px;
    font-family: "FiraSans-Medium";
    font-weight: 500;
    border-radius: 6px;
    transition: ease 0.5s,

}

.login-via-tabs {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    background-color: white;
    height: 45px;
    justify-content: space-around;
}

.login-via-tab {
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    height: 100%;
    width: 50%;
    /* border-bottom: 3px solid #0e9898; */
}

.login-via-tab>span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    color: #bdbdbd;
    letter-spacing: 0.45px;
    margin-bottom: 5px;
    font-family: "firaSans-semibold";

    /* font-weight: bold; */
    /* font-size: 1.1rem;
    font-weight: bold;
    color: #bdbdbd */


}

.signUp-form-link-cont {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 20%;

}

.auth-form>.reset-text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;

}

.reset-text-cont>p {
    font-size: 12px;
    color: #0b0953;
    font-weight: bold;
    font-family: 'firaSans-semibold';
    text-align: center;
}

.descr-text-cont {
    width: 509px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

}

.descr-text-cont>p {
    font-size: 26px;
    text-align: center;
    /* font-family: 'OpenSans-SemiBold'; */
    font-weight: bold;
    color: var(--color-theme);
    /* opacity: 46%; */
    margin-bottom: 80px;
    margin-top: -50px;
}

.signIn-btn-text {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    position: relative;
    margin-right: 13%;

}

.signIn-btn-text>span {
    font-size: 15px;
    color: white;
}

#guest-user-form {
    width: 367px;
}

.guest-login-heading {
    font-size: 20.8px;
    line-height: 38px;
    color: var(--color-theme);
    margin-bottom: 35px;
    font-family: "firaSans-semibold";
    font-weight: 600;
    border-bottom: 3px solid var(--color-theme);
}


@media screen and (max-width: 991px) {
    .login-type-btns {
        padding-right: 40%;
        margin-left: 0px;
    }

    .login-via-tab>span {
        font-size: 16px;
    }
}

@media screen and (max-width: 390px) {
    .guest-login-heading {
        font-size: 15px;
    }

    #guest-user-form {
        width: 290px;
    }
}

@media screen and (max-width: 501px) {
    .login-type-btns {
        padding-right: 30%;
        margin-left: 0px;
    }

    #pass-login-btn {
        font-size: 0.7rem;
    }

    .form-container {
        width: 100%;
    }

    .descr-text-cont {
        width: 100%;
        margin-bottom: -15px;
        margin-top: 10px;

    }

    .descr-text-cont>p {
        font-size: 1.3rem;

    }

    .signIn-btn-text {
        margin-right: 0% !important;
        margin-left: -10%;
    }
}



@media screen and (max-width: 601px) {
    .login-type-btns {
        padding-right: 30%;
        margin-left: 0px;
    }

    #pass-login-btn {
        font-size: 0.7rem;
    }
}

.tooltip-input-container .login-input::placeholder {
    color: #0b0953;
    font-weight: bold;
  }