.name-initial {
  max-width: 32px;
  width: 32px;
  height: 32px;
  background: #f3f7ff;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  flex: 1;
}
.name-initial span {
  font-size: 12px;
  line-height: 16px;
  color: #0077b7;
}
.leader-table .table-desc {
  flex: 1;
}
/* table */
.leader-table thead th:nth-child(4),
.leader-table th:nth-child(5),
.leader-table th:nth-child(6) {
  text-align: start;
}
.correct-ans,
.reports-dropdown .correct-ans span {
  color: #2ab070;
}
.wrong-ans,
.reports-dropdown .wrong-ans span {
  color: #e22828;
  text-align: center;
}
.un-ans,
.reports-dropdown .un-ans span {
  color: #5381ef;
  text-align: center;
}
.reports-dropdown-view {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.reports-dropdown {
  margin-left: 15px;
}
.reports-dropdown button {
  background: transparent;
  border: 0px solid transparent;
  outline: 0;
  cursor: pointer;
}
.reports-dropdown .dropdown-menu.show {
  left: -40px !important;
}
.reports-dropdown .dropdown-menu {
  padding: 8px 10px;
  width: 145px;
  height: 102px;
}
.reports-dropdown h6 {
  font-size: 10px;
  line-height: 12px;
  color: rgba(35, 40, 46, 0.8);
  margin-bottom: 8px;
}
.reports-dropdown .dropdown-menu div {
  font-size: 12px;
  line-height: 14px;
  color: rgb(35 40 46 / 80%);
  text-align: left;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.reports-dropdown div:last-child {
  margin-bottom: 0;
}

/* #report-tb-head th{
  display: flex;
  flex-direction: row;
} */
@media screen and (max-width: 991px) {
  .leader-table {
    margin: 0;
  }
  .leader-table table {
    display: table !important;
  }
  .leader-table #dropdownMenuButton {
    display: flex !important;
  }
  .leader-table th:nth-child(4),
  .leader-table th:nth-child(5),
  .leader-table th:nth-child(6) {
    display: none;
  }
  .leader-table td:nth-child(4),
  .leader-table td:nth-child(5),
  .leader-table td:nth-child(6) {
    display: none;
  }
  .leader-table tbody td,
  .leader-table thead th {
    padding: 8px !important;
    font-size: 12px;
    line-height: 19px;
  }

  .leader-table .table-detail h4 {
    font-size: 12px;
    line-height: 19px;
    word-break: break-all;
    word-wrap: break-word;
  }
}
@media screen and (max-width: 360px) {
  .leader-table tbody td,
  .leader-table thead th,
  .leader-table .table-detail h4 {
    padding: 8px 2px !important;
  }
}
