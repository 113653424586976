.protected-wrapper{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    align-items: center;
}
.protected-wrapper h4{
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #23282E;
    opacity: 0.5;
}
.protected-wrapper div div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.protected-wrapper div h5{
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #E25569;
}