* {
  user-select: none;
  font-family: inherit;
}
::-webkit-scrollbar {
  width: 0;

}
.react-multi-carousel-list {

  width: 90%;
  margin: 0 auto;
}

.mobileView .react-multi-carousel-list {

  width: 100%;
 
}

.react-multiple-carousel__arrow {
  position: absolute !important;
  outline: 0 !important;
  transition: all .5s !important;
  border-radius: 35px !important;
  z-index: 49 !important;
  border: 0 !important;
  background: rgba(0, 0, 0, 0.6) !important;
  min-width: 43px !important;
  min-height: 43px !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

.bg-grey {
  background-color: #f9f9f9;

  @media (max-width: 600px) {
    .react-multi-carousel-list {
      width: 100%;
      overflow: hidden;
      height: 100%;

      .react-multi-carousel-track {
        transition: none;
        overflow: unset;
        height: 100%;

        li {
          display: flex !important;
          justify-content: center !important;
        }
      }
    }
  }
}


.text-highlight {
  color: var(--color-theme);
}

.game-section-main-banner {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;

  @media (max-width: 600px) {
    height: 73vh;
  }


  .react-multi-carousel-list {
    width: 100%;
    overflow: hidden;
    height: 100%;

    .react-multi-carousel-track {
      transition: none;
      overflow: unset;
      height: 100%;


    }

    .react-multi-carousel-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .carousel-games-item {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px !important;
        position: relative;
        /* Allows absolute positioning of video */

        overflow: hidden;

        .image-banner {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 0px;

          @media (max-width: 600px) {
            height: 100vh;
          }

        }

        .video-banner {
          position: relative;
          /* Position video absolutely within the container */
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* Ensure video covers the container while maintaining aspect ratio */
          background: rgb(216, 212, 212);
          /* Fallback background color */
        }

      }
    }
  }

  h2 {
    line-height: 54px;
    font-weight: 600;
    color: white;
    margin: 1rem;
    font-size: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    @media (max-width: 600px) {
      font-size: 22px;
      line-height: normal;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 28px;
      line-height: normal;
    }
  }

  .user-btn {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    margin: 2rem 0;

    .btn {
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s, color 0.3s, border-color 0.3s;
      height: 44px;
      position: relative;
      overflow: hidden;
      border-radius: 8px;

      &.red {
        padding: 1rem 2rem;
        background-color: var(--color-theme);
        color: white;
        display: inline-flex;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 23px;
        text-decoration: none !important;
        color: #fff !important;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        justify-content: center;
        padding: 16px 32px;
        height: 48px;
        text-align: center;
        white-space: nowrap;

        @media (max-width: 600px) {
          width: 120px;
          height: 37px;
          font-size: 14px;
        }

        @media (min-width: 601px) and (max-width: 900px) {
          width: 120px;
          height: 37px;
          font-size: 14px;
        }

        &:hover {
          background-color: #c62828;
        }
      }

      &.transparent {
        display: inline-flex;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 16px;
        text-decoration: none !important;
        background-color: #242830;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        justify-content: center;
        padding: 16px 32px;
        height: 48px;
        text-align: center;
        white-space: nowrap;
        background: transparent;
        border: 1px solid #ffff;

        @media (max-width: 600px) {
          width: 120px;
          height: 37px;
          font-size: 14px;
        }

        @media (min-width: 601px) and (max-width: 900px) {
          width: 120px;
          height: 37px;
          font-size: 14px;
        }

        &:hover {
          color: #ffffff;
        }

        &:hover:before {
          transform: scaleX(1);
          left: 0;
        }

        span {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.game-cards-section {
  padding: 3rem 2rem;
  display: grid;
  gap: 3rem;
  background-color: #f9f9f9;



  @media (max-width: 600px) {
    padding: 2rem;
  }

  @media (min-width: 601px) and (max-width: 920px) {
    padding: 2rem;
  }

  .custom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;

    .custom-heading {
      font-size: 32px;
      font-weight: bold;
      color: #000;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 22px;
        line-height: normal;
      }

      @media (min-width: 601px) and (max-width: 900px) {
        font-size: 22px;
        line-height: normal;
      }
    }
  }

  .card_ {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: #f6f7f9 14px 26px 30px -3px, rgba(0, 0, 0, 0.05) 0px -5px 16px -3px;
    text-align: center;
    position: relative;

    .card-image {
      position: absolute;
      top: -31px;
      right: 30px;
      width: 80px;
      height: auto;
      z-index: 0;
    }

    h3 {
      font-size: 22px;
      line-height: 24px;
      color: #000;
      margin-bottom: 12px;
      margin-top: 6px;
      font-family: 'firaSans-medium';
      font-weight: 500;
      text-align: left;
    }

    .highligtSpan {
      font-size: 18px;
      font-weight: 200;
      color: var(--color-theme);
      background-color: white;
    }

    p {

      margin: 0;
      color: #919397;
      font-size: 14px;
      line-height: 22px;
      text-align: justify;
      margin: 0;
      color: #919397;
      font-size: 14px;
      line-height: 22px;
      text-align: justify;
      color: #6c6c6c;
      font-size: 14px;
      line-height: 22px;

      font-weight: 400;

    }
  }
}

@media (min-width: 1050px) {
  .game-cards-section {
    grid-template-columns: repeat(3, 1fr);

  }
}

@media (max-width: 1050px) and (min-width: 550px) {
  .game-cards-section {
    grid-template-columns: repeat(2, 1fr);

  }
}

@media (max-width: 550px) {
  .game-cards-section {
    grid-template-columns: 1fr;

  }
}

.carousel-games-item {
  position: relative;
  display: inline-block;
  margin: 20px;
  /* transition: transform 0.3s ease-in-out; */
  overflow: hidden;

  @media (max-width: 600px) {
    display: table;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .games-item {
    height: 300px;
    width: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-align: center;
    box-shadow: #f6f7f9 14px 26px 30px -3px, rgba(0, 0, 0, 0.05) 0px -5px 16px -3px;
    position: relative;
    overflow: hidden;

    img {

      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: opacity 1s ease-in-out;
      z-index: 10;
      border-radius: 6px;

      .button-group {
        position: absolute;
        bottom: 10%;

        .button-play {
          cursor: pointer;
          background-color: transparent;
          padding: 5px 20px;
          color: #fff;
          border: 1px solid white;
          font-size: 14px;
          border-radius: 6px !important;
        }

        .button-play:hover {
          background-color: var(--color-theme);

          color: #ffffff;

        }
      }
    }

    &:hover .overlay {
      opacity: 1;
    }
  }

  .card-title-1,
  .card-title-2 {
    position: relative;
    text-align: center;
    margin: 0;
    text-align: left;
  }

  .card-title-1 {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #23282e;
    text-align: left;
    line-height: 21px;
  }

  .card-title-2 {
    padding: 0;
    font-size: 14px;
    color: #6c6c6c;
    line-height: 18px;
    font-weight: 400;
  }
}

.m-4 {
  margin: 5rem 5rem;
}

.container-form1 {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 3rem 0rem;

  @media (max-width: 700px) {
    flex-direction: column;
    height: unset;
    padding-bottom: 0;
  }

  .left-section,
  .right-section {
    width: 50%;
    height: 100%;
    padding: 3rem;

    @media (max-width: 700px) {
      width: 100%;
      padding: 1rem;
    }

  }

  .left-section {
    position: relative;

    .form {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem;
      background-color: #fff;
      /* box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px; */
      border-radius: 13px;
      box-shadow: #e1e6ea6e 0px 0px 8px;

      h3 {
        margin: 0 4rem 2rem;
        font-size: 30px;
        line-height: 9px;
        color: #000;
        /* margin-bottom: 12px; */
        font-family: 'firaSans-medium';
        font-weight: 500;
        text-align: center;

        @media (max-width: 500px) {
          font-size: 22px;
          padding-bottom: 2rem;
          margin: 0;
        }
      }

      .form-row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 15px;

        @media (max-width: 600px) {
          display: flex;

        }

        .center {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
        }

        .formGroup {
          flex: 1;
          margin-right: 10px;

          .errmsg {
            font-size: 12px;
            color: red;
          }

          input,
          textarea {
            font-size: 14px;
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            background: #fafafa;
            border: none;
            border-radius: 6px;

            &::placeholder {
              color: #999;
            }
          }
        }

        .form-actions {
          text-align: center;
          width: 100%;

          .custom-btn {
            width: 100%;
            background-color: var(--color-theme);
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #e64a19;
            }
          }
        }
      }

      button {
        align-self: flex-start;
        padding: 10px 20px;
        cursor: pointer;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .rocket-image {
      position: absolute;
      z-index: 2;
      bottom: 24px;
      right: -25px;
      width: 25vh;

    }
  }

  .right-section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 1rem;
      font-size: 32px;
      font-weight: 600;

      @media (max-width: 600px) {
        font-size: 22px;
        text-align: center;
      }

      .text-highlight {
        color: var(--color-theme);

      }
    }

    .description {
      color: #919397;
      font-size: 14px;
      line-height: 22px;
      text-align: justify;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}

.button-section {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;

  .img-container {
    margin-top: 3rem;
    display: inline-block;
    box-sizing: border-box;
    position: relative;

    img {
      position: absolute;
      top: -24px;
      left: -19px;
      width: 20px;
      height: auto;

    }

    .heading {
      font-size: 26px;
      line-height: 9px;
      color: #000;
      margin-bottom: 4rem;
      font-family: 'firaSans-medium';
      font-weight: 500;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 22px;
      }
    }
  }

  .container11 {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .tabs {
      display: flex;
      position: relative;
      background-color: #fff;
      box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 1px 7px 0 #f9f9f9;
      padding: 0.75rem;
      border-radius: 99px;
      width: 90%;
      margin-bottom: 1rem;
      overflow: hidden;
    }

    input[type="radio"] {
      display: none;
    }

    label.tab {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      flex: 1 1;
      font-size: 16px;
      font-weight: 500;
      border-radius: 99px;
      cursor: pointer;
      transition: color 0.18s ease-in;
      color: #6c6c6c;

      &:hover {
        color: #000;
      }
    }

    input:checked+label.tab {
      color: white;
      z-index: 2;
    }

    .glider {
      position: absolute;
      height: 40px;
      background-color: var(--color-theme);
      z-index: 1;
      border-radius: 99px;
      transition: transform 0.25s ease-out;
    }
  }

}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.container12 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.section1 {
  .tabs1 {
    display: flex;
    position: relative;

    padding: 0rem;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
  }

  input[type='radio'] {
    display: none;
  }

  .tab1 {

    padding: 10px 20px;
    margin-right: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    flex: 1 1 auto;

    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: color 0.15s ease-in;

    &:last-of-type {
      margin-right: 0;
    }
  }

  input[type='radio']:checked+label {
    color: #ffffff;
  }

  .glider1 {
    position: absolute;
    height: 47px;
    background-color: var(--color-theme);
    border-radius: 10px;
    transition: transform 0.25s ease-out, width 0.25s ease-out;
    z-index: 0;
    top: 0;
    left: 0;
  }

  input[id='radio-0']:checked~.glider1 {
    transform: translateX(0);
    width: calc(25% - 10px);

  }

  input[id='radio-1']:checked~.glider1 {
    transform: translateX(calc(25% + 10px));

    width: calc(25% - 10px);

  }

  input[id='radio-2']:checked~.glider1 {
    transform: translateX(calc(50% + 20px));

    width: calc(25% - 10px);

  }

  input[id='radio-3']:checked~.glider1 {
    transform: translateX(calc(75% + 30px));

    width: calc(25% - 10px);

  }
}


.landing-page-banner-section {
  font-family: Arial, sans-serif;
  position: relative;
  margin: 0;

  .image-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 72px);
    /* Full viewport height minus header height */
    margin-top: 72px;
    /* Ensure the image starts below the header */
    overflow: hidden;

    .react-multi-carousel-list {
      width: 100%;
      overflow: hidden;
      height: 100%;

      .react-multi-carousel-track {
        transition: none;
        overflow: unset;
        height: 100%;


      }

      .react-multi-carousel-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .carousel-games-item {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0px !important;
          position: relative;
          /* Allows absolute positioning of video */

          overflow: hidden;

          .image-banner {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            border-radius: 0px;

            @media (max-width: 600px) {
              height: 100vh;
            }

          }

          .video-banner {
            position: relative;
            /* Position video absolutely within the container */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* Ensure video covers the container while maintaining aspect ratio */
            background: rgb(216, 212, 212);
            /* Fallback background color */
          }

        }
      }
    }

    .full-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* Ensures the image covers the container */
    }

    .content-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      /* Add padding to ensure content doesn't touch edges */
      color: #ffffff;
      box-sizing: border-box;
  
    .paragraph {
        margin-bottom: 20px;
        text-align: center;
        /* Center text inside the paragraph element */
      }

      .button-group {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;

        .btn {
          padding: 10px 20px;
          font-size: 1em;
          margin: 5px;
          /* Space between buttons */
          border: none;
          background: #007bff;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;

          &.red {
            padding: 1rem 2rem;
            background-color: var(--color-theme);
            color: white;
            display: inline-flex;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 23px;
            text-decoration: none !important;
            color: #fff !important;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            justify-content: center;
            padding: 16px 32px;
            height: 48px;
            text-align: center;
            white-space: nowrap;

            @media (max-width: 600px) {
              width: 120px;
              height: 37px;
              font-size: 14px;
            }

            @media (min-width: 601px) and (max-width: 900px) {
              width: 120px;
              height: 37px;
              font-size: 14px;
            }

            &:hover {
              background-color: #c62828;
            }
          }

          &.transparent {
            display: inline-flex;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 16px;
            text-decoration: none !important;
            background-color: #242830;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            justify-content: center;
            padding: 16px 32px;
            height: 48px;
            text-align: center;
            white-space: nowrap;
            background: transparent;
            border: 1px solid #ffff;

            @media (max-width: 600px) {
              width: 120px;
              height: 37px;
              font-size: 14px;
            }

            @media (min-width: 601px) and (max-width: 900px) {
              width: 120px;
              height: 37px;
              font-size: 14px;
            }

            &:hover {
              color: #ffffff;
            }


            span {
              position: relative;
              z-index: 1;
            }
          }

          &:hover {
            background-color: #f9f9f9b3;
          }
        }
      }
    }
  }
}

.nonlogin-landing-section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* margin-top: 72px; */

  .main-content {
    flex: 1;


    .section {

      /* Full viewport height for each section */
      display: flex;
      align-items: center;
      justify-content: center;

      /* Text color for readability */

    }


    .section1 {
      /* background-color: #ff6f61; */
      font-family: Arial, sans-serif;
      position: relative;
      margin: 0;
      height: calc(100vh);

      .image-container {
        position: relative;
        width: 100%;
        height: 100%;

        /* Ensure the image starts below the header */
        overflow: hidden;

        .react-multi-carousel-list {
          width: 100%;
          overflow: hidden;
          height: 100%;

          .react-multi-carousel-track {
            transition: none;
            overflow: unset;
            height: 100%;


          }

          .react-multi-carousel-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            .carousel-games-item {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0px !important;
              position: relative;
              /* Allows absolute positioning of video */

              overflow: hidden;

              .image-banner {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                border-radius: 0px;

                @media (max-width: 600px) {
                  height: 100vh;
                }

              }

              .video-banner {
                position: relative;
                /* Position video absolutely within the container */
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                /* Ensure video covers the container while maintaining aspect ratio */
                background: rgb(216, 212, 212);
                /* Fallback background color */
              }

            }
          }
        }

        .full-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* Ensures the image covers the container */
        }

        .content-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          /* Add padding to ensure content doesn't touch edges */
          color: #ffffff;
          box-sizing: border-box;
          /* Include padding in width calculations */

          .title {
            width: 100%;
            line-height: normal;
            color: white;
            margin: 1rem;
            font-family: 'Roboto', sans-serif;
            margin: 0 auto;
            margin-bottom: 1rem;
            font-family: Fira Sans;
            font-size: 55px;
            font-weight: 700 !important;
            line-height: 32px;
            text-align: center;

            @media (max-width: 600px) {
              font-size: 22px !important;
              line-height: 22px;
              width: 100%;
            }
          }

          .paragraph {
            font-family: Fira Sans;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            color: #B7B7B7;
            margin: 2rem 0;

            @media (max-width: 600px) {

              margin: 1rem 0;
            }
          }

          .button-group {
            margin: 2rem;
            display: flex;
            justify-content: center;

            .btn {
              display: inline-flex;
              font-size: 16px;
              /* letter-spacing: 0px; */
              /* font-weight: 600; */
              /* line-height: 16px; */
              text-decoration: none !important;
              /* text-transform: uppercase; */
              /* background-color: #242830; */
              color: #fff !important;
              border: none;
              /* border-radius: 2px; */
              cursor: pointer;
              justify-content: center;
              padding: 16px 32px;
              /* height: 48px; */
              text-align: center;

              cursor: pointer;

              &.red {
                padding: 1rem 2rem;
                background-color: var(--color-theme);
                color: white;
                display: inline-flex;
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 22px;
                text-decoration: none !important;
                color: #fff !important;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                justify-content: center;
                padding: 16px 32px;
                height: 58px;
                width: 190px;
                text-align: center;
                white-space: nowrap;
                width: Fixed (190px)px;
                height: Hug (58px)px;
                padding: 17px 38px 17px 42px;
                gap: 8px;


                @media (max-width: 600px) {
                  width: 140px;
                  height: 37px;
                  font-size: 14px;
                }

                @media (min-width: 601px) and (max-width: 900px) {
                  width: 120px;
                  height: 37px;
                  font-size: 14px;
                }

                &:hover {
                  background-color: #c62828;
                }
              }

              &.transparent {
                border-top: 1px solid white;
                border-left: 1px solid white;
                border-right: 2px solid white;
                border-bottom: 2px solid white;


                display: inline-flex;
                align-items: center;
                /* Center content vertically */
                justify-content: center;
                /* Center content horizontally */

                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                /* Adjust line height to match font size and padding */

                text-decoration: none;
                /* Remove underline from text */
                background-color: #242830;
                /* Dark background color */
                border-radius: 6px;
                cursor: pointer;
                padding: 16px 32px;
                height: 58px;
                /* Adjusted to match padding */
                width: 190px;
                /* Fixed width */

                text-align: center;
                white-space: nowrap;
                /* Prevent text wrapping */

                /* Remove redundant border and background styles */

                background: transparent;
                /* Reset background to make sure it's transparent */
                width: Fixed (190px)px;
                height: Fixed (58px)px;
                padding: 17px 49px 17px 49px;
                gap: 7px;
                background: rgba(255, 255, 255, 0.2);



                @media (max-width: 600px) {
                  width: 120px;
                  height: 37px;
                  font-size: 14px;
                }

                @media (min-width: 601px) and (max-width: 900px) {
                  width: 120px;
                  height: 37px;
                  font-size: 14px;
                }

                &:hover {
                  color: #ffffff;
                }


                span {
                  position: relative;
                  z-index: 1;
                }
              }

              &:hover {
                background-color: #f9f9f9b3;
              }
            }
          }
        }
      }
    }

    .section2 {
      min-height: calc(100vh - 72px);
      height: auto;
      position: relative;
      /* z-index: 31; */


      @media (max-width: 600px) {
        min-height: unset;
      }



      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;


        @media (max-width: 600px) {

          margin-top: 3rem;

        }

      }

      .card-container2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem;
        justify-content: center;
        padding: 2.5rem;

        .card2 {
          position: relative;
          background-color: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 4px 34px 0px #B0B0B040;

          padding: 1.5rem;
          text-align: left;

          p {
            font-size: 16px;
            line-height: 27px;
            color: #6c6c6c;
            text-align: left;

          }

          h1 {
            font-size: 25px;
            line-height: 30px;
            color: #23282e;
            margin-bottom: 12px;
            /* font-family: 'firaSans-medium', sans-serif; */
            font-weight: 500;
            text-align: left;

            .highligtSpan {
              color: var(--color-theme);
              font-weight: 400;
              font-size: 25px;
              line-height: 30px;

              @media (max-width: 600px) {

                font-size: 18px !important;
              }
            }

            @media (max-width: 600px) {

              font-size: 18px !important;
            }
          }

          .fas {
            font-size: 50px;
          }

          .card-image {
            position: absolute;
            top: -34px;
            right: 13px;
            width: 55px;
            height: 55px;
            object-fit: cover;
          }
        }
      }

      @media (max-width: 1024px) {
        .card-container2 {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @media (max-width: 600px) {
        .card-container2 {
          grid-template-columns: 1fr;
        }
      }

      .section2-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        width: 100%;
        padding: 20px;

        .cardq {
          position: relative;
          background-color: white;
          color: #333;
          border-radius: 8px;
          box-shadow: rgb(225, 230, 234) 0px 0px 8px;
          width: calc(33.333% - 32px);
          box-sizing: border-box;
          padding: 32px;
          text-align: center;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          display: inline-flex;
          flex-direction: column;
          border-radius: 10px;

          @media (max-width: 600px) {
            margin-bottom: 1rem;
          }

          @media (min-width: 769px) and (max-width: 1024px) {
            width: calc(50% - 32px);
          }

          @media (max-width: 768px) {
            width: calc(100% - 32px);
          }

          .card-image {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 55px;
            height: 55px;
            object-fit: cover;
          }

          .card-title {
            font-size: 20px;
            line-height: 1.75rem;
            color: #4d5157;
            margin-bottom: 1rem;
            font-family: 'firaSans-medium', sans-serif;
            font-weight: 500;
            text-align: left;

            .highligtSpan {
              color: var(--color-theme);
            }
          }

          .card-paragraph {
            font-size: 14px;
            line-height: 25px;
            color: #919397;
            text-align: justify;
          }
        }
      }
    }

    .section2 .row .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      width: 100%;
      /* Adjust this based on your layout needs */
    }

    .section2 .row.img-container .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      /* Adjust size of image */
      height: auto;
      /* Maintain aspect ratio */
    }

    .section2 .row .img-container .heading {
      font-size: 2rem;
      /* Default font size for large screens */
      margin-top: 60px;
      /* Space between the image and heading */
    }

    .section2 .row .img-container .highligtSpan {
      color: red;
      /* Style for highlighted text */
    }

    @media (max-width: 768px) {
      .section2 .row.img-container .heading {
        font-size: 1.5rem;
        /* Adjusted for smaller screens */
      }

      .section2 .row.img-container .img {
        width: 30px;
        /* Adjust image size for small screens */
      }
    }



    .section3 {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 72px);
      /* Set minimum height to full viewport height minus header height */
      height: auto;
      padding-top: 2rem;

      /* Media query for screen widths up to 600px */
      @media (max-width: 768px) {
        min-height: unset;
      }

      .btnstyle {
        margin-bottom: 1rem !important;
        /* Ensure margin is applied */
        width: 80%;

        @media (max-width: 1500px) {
          width: 90%;
        }
      }



      .view-all-games {
        .centered-button {
          margin-top: 1rem;
          margin-bottom: 4rem;
          padding: 10px 20px;
          font-size: 18px;
          border: none;
          border-radius: 5px;
          background-color: var(--color-theme);
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s;
          line-height: 25px;
          box-shadow: 0px 14px 30px 0px rgba(255, 147, 147, 0.4);
        }
      }
    }


    .section4 {

      flex-direction: column;
      height: auto;

      @media (max-width: 768px) {
        margin-top: 0rem;
        /* margin-bottom: 3rem; */
      }
    }

    .section4 .games-elevate-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem;
      width: 100%;
      margin-bottom: 0rem;

      @media (max-width: 600px) {
        flex-direction: column;
        padding: 0;
      }
    }

    .section4 .games-elevate-section .left-side {
      width: 50%;
      padding: 0 0 0 2rem;

      @media (max-width: 600px) {
        padding: 0 1rem;
        width: 100%;
      }
    }

    .section4 .games-elevate-section .left-side h2 {
      font-size: 39px;
      font-weight: 700;
      text-align: left;
      color: #23282E;
      line-height: 49px;

      @media (max-width: 600px) {
        font-size: 22px;
        line-height: 25px;
        text-align: center;
      }
    }

    .section4 .games-elevate-section .left-side .text-highlight {
      color: var(--color-theme);
    }

    .section4 .games-elevate-section .right-side {
      width: 50%;
      padding: 0 2rem;

      @media (max-width: 600px) {
        padding: 0 1rem;
        width: 100%;
      }
    }

    .section4 .games-elevate-section .right-side .right-title {
      font-size: 25px;
      line-height: 30px;
      color: #23282E;
      margin-bottom: 12px;
    
      font-weight: 600;
      text-align: left;

      @media (max-width: 600px) {
        margin-top: 1rem;
        font-size: 22px;
        line-height: 25px;
        text-align: center;
      }
    }

    .section4 .games-elevate-section .right-side .right-description {
      font-size: 16px;
      line-height: 27px;
      color: #6c6c6c;
      text-align: justify;

      @media (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }

    .section4 .image-container {
      width: 100%;
      margin-top: 2rem;

      @media (max-width: 600px) {
        width: 90%;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .section4 .image-container .video-wrapper {
      position: relative;

      width: 95%;
      margin: 0 auto;
      background-color: #f9f9f9;
      border-radius: 9px;
      overflow: hidden;
      box-shadow: 0px 0px 0px 20px #ffffff;

      margin-bottom: 2rem;

      @media (max-width: 786px) {
        height: auto;
        border-radius: 0;
        box-shadow: none;
        width: 100% !important;
      }
    }

    .section4 .image-container .video-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 9px;

      @media (max-width: 600px) {
        display: none;
      }
    }

    .section4 .image-container .video-wrapper .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(225, 89, 109, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      z-index: 1;

      @media (max-width: 600px) {
        height: auto;
        position: relative;
      }
    }

    .section4 .image-container .video-wrapper .play-btn {
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      transition: background-color 0.3s ease;
      animation: pulse 1.5s infinite;
      box-shadow: 0 0 0 0 rgba(220, 121, 136, 0.5);

      &:hover {
        background-color: #d0485a;
        animation: none;
      }
    }

    .section4 .image-container .video-wrapper .play-btn .play-symbol {
      width: 0;
      height: 0;
      border-left: 15px solid var(--color-theme);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 6px solid transparent;
      position: relative;
      left: 5px;
      top: 0px;

      @media (max-width: 600px) {
        border-left: 12px solid var(--color-theme);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 4px solid transparent;
        left: 4px;
      }
    }

    .section4 .image-container .video-wrapper video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


    /* Pink */
    .section5 {
      min-height: unset !important;
      height: unset !important;
      /* padding-bottom: 1rem;
      padding-top: 2rem; */

    }

    .section6 {
      height: calc(100vh - 72px);
      display: flex;
      padding: 5rem 2rem;
      flex-direction: row;
      position: relative;
      background: linear-gradient(109.07deg, rgba(255, 232, 234, 0.5) 3.6%, rgba(255, 242, 254, 0.5) 100%);

      @media (max-width: 600px) {
        flex-direction: column;
        padding: 2rem;
      }

      .left-section {
        height: 100%;
        width: 50%;
        box-sizing: border-box;
        padding: 20px;
        position: relative;

        @media (max-width: 600px) {
          width: 100%;
          height: 300px;
          padding: 10px;

          .image1,
          .image2 {
            width: 100%;
            height: auto;
          }

          .image1 {
            width: 100%;
            height: auto;
            transform: rotate(0deg);
            top: 0;
            right: 0;
          }

          .image2 {
            width: 100%;
            height: auto;
            transform: rotate(0deg);
            bottom: 0;
            left: 0;
          }
        }

        .image1,
        .image2 {
          position: absolute;
          border-radius: 15px;
          display: block;
        }

        .image1 {
          top: -7%;
          /* right: -9px; */
          z-index: 2;
          width: 72%;
          height: auto;
          transform: rotate(0deg);

          @media (max-width: 600px) {
            top: -34px;
          }
        }

        .image2 {
          top: 5%;
          left: -13%;
          z-index: 1;
          width: 90%;
          height: auto;
          transform: rotate(-7deg);

          @media (max-width: 600px) {
            bottom: -38px;
          }
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }


      .right-section {
        width: 50%;
        box-sizing: border-box;
        padding: 0 2rem;

        @media (max-width: 600px) {
          width: 100%;
          padding: 0;
        }

        h2 {
          margin-bottom: 2rem;
          font-size: 39px;
          font-weight: 600;
          color: #23282E;
          line-height: 52px;

          @media (max-width: 600px) {
            margin-top: 2rem;
            font-size: 22px;
            text-align: center;
            line-height: 32px;
          }

          .text-highlight {
            color: var(--color-theme);
          }
        }

        h3 {
          font-size: 25px;
          line-height: 30px;
          color: #23282E;
          margin-bottom: 1rem;
          font-family: 'firaSans-medium', sans-serif;
          font-weight: 400;
          text-align: left;


          @media (max-width: 600px) {
            margin-top: 2rem;
            font-size: 22px;
            text-align: center;
            line-height: 32px;
          }
        }

        p {
          font-size: 16px;
          line-height: 25px;
          color: #6c6c6c;
          text-align: justify;
          margin-bottom: 1rem;
        }

        .games-grid-items-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          width: 100%;
          margin-bottom: 2.5rem;
          margin-top: 2rem;

          @media (max-width: 600px) {
            display: block;
          }

          .explore_card {
            display: flex;
            align-items: center;

            @media (max-width: 600px) {
              margin-bottom: 1rem;

            }

            .card-icon {
              border-radius: 50%;
              padding: 10px;
              width: 45px;
              height: 45px;
              margin-right: 10px;
              box-shadow: 0px 4px 14px 0px rgba(204, 204, 204, 0.20);

              background-color: #ffffff;

            }

            .card-title {
              color: #23282E;
              font-size: 16px;
              line-height: 25px;
              font-weight: 400;

              @media (max-width: 600px) {

                font-size: 18px;
                text-align: center;

              }
            }
          }
        }

        .explore-more {
          text-align: left;

          @media (max-width: 600px) {
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
          }

          .btn {
            display: inline-flex;
            font-size: 16px;
            letter-spacing: 0px;
            /* font-weight: 600; */
            line-height: 16px;
            text-decoration: none !important;
            /* text-transform: uppercase; */
            background-color: #242830;
            color: #fff !important;
            border: none;
            /* border-radius: 2px; */
            cursor: pointer;
            justify-content: center;
            padding: 16px 32px;
            height: 48px;
            text-align: center;
            white-space: nowrap;

            &.black {
              background-color: #000;
              color: #fff;
              font-size: 16px;

              &:hover {
                background-color: #333;
              }
            }
          }
        }
      }
    }


    /* Orange */
    .section7 {
      flex-direction: column;
      min-height: calc(100vh - 72px);
      /* Set minimum height to full viewport height minus header height */
      height: auto;


    }

    .section8 {
      height: 597px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .games-view-plan-section {
        text-align: center;
        padding: 6rem;
        color: #fff;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          line-height: 52px;
          color: white;
          margin: 1rem;
          font-size: 39px;
          font-weight: 600;
          text-align: center;
          margin: 0 auto;
        }

        p {
          letter-spacing: 0.32px;
          text-align: center;
          font-size: 25px;
          color: #D0D2FF;
          margin: 1rem 0 2rem 0;
          line-height: 25px;
          font-weight: 400;

          .discount {
            color: var(--color-theme);
          }
        }

        .inputs-container {
          width: 746px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          gap: 15px;

          .input-group {
            width: 100%;
          }

          input {
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            background: #f6f7f9;
            border: none;
            height: 45px;
            color: #000;
            font-size: 16px;
            border-radius: 6px;
            line-height: 24px;
            box-shadow: 0px 4px 54px 0px #C3C9FF59;
          }
        }

        .user-btn {
          display: flex;
          justify-content: center;
          gap: 1rem;
          margin-top: 1.5rem;

          .btn {
            position: relative;
            z-index: 1;
            box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
            width: 160px;
            height: 50px;
            padding: 14px 15px;

            &.red {
              background-color: var(--color-theme);
              color: #fff;
              font-size: 18px;
              line-height: 21px;

              &:hover {
                background-color: #c62828;
              }
            }
          }
        }
      }
    }

    @media (max-width: 600px) {

      .section8 .games-view-plan-section {
        padding: 2rem;
      }

      .section8 .games-view-plan-section h2 {
        font-size: 22px;
      }

      .section8 .games-view-plan-section p {
        margin: 0 1rem;
      }

      .section8 .games-view-plan-section .inputs-container {
        flex-direction: column;
        width: 100%;
        margin-top: 2rem;
      }

      .section8 .games-view-plan-section input {
        margin: 8px 0;
      }

      .section8 .games-view-plan-section .user-btn {
        flex-direction: column;
        margin-top: 2rem;
        display: block;
      }


    }



    .section9 {
      display: flex;
      padding: 2rem 2rem 1rem 2rem;
      height: auto;
      flex-direction: row;

      @media (max-width: 600px) {
        flex-direction: column;
        padding: 1rem;
      }

      .left-section {
        width: 50%;
        padding: 2rem 2rem 0rem 2rem;


        @media (max-width: 700px) {
          width: 100%;
          padding: 0rem;
        }
      }
      .right-section {
        width: 50%;
        padding: 2rem 2rem 0rem 2rem;


        @media (max-width: 700px) {
          width: 100%;
          padding: 0rem;
        }
      }

      .left-section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .form {
          max-width: 558px;
          width: 100%;
          padding: 2rem;
          background-color: #fff;
          border-radius: 13px;
          box-shadow: 0px 4px 50px 0px #D5D5D540;
          position: relative;
          z-index: 2;

          h3 {
            margin: 0 0 2rem;
            font-size: 30px;
            line-height: 1.2;
            color: #000;
            font-family: 'firaSans-medium';
            font-weight: 500;
            text-align: center;

            @media (max-width: 500px) {
              font-size: 22px;
              padding-bottom: 2rem;
              margin: 0;
            }
          }

          .form-row {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            @media (min-width: 701px) {
              flex-direction: row;
              flex-wrap: wrap;
            }

            .formGroup {
              flex: 1;
              margin-bottom: 15px;
              padding: 0 10px;

              @media (min-width: 701px) {
                width: calc(50% - 20px);
                margin-bottom: 0;
              }

              .errmsg {
                font-size: 12px;
                color: red;
              }

              input,
              textarea {
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                background: #f6f7f9;
                border: none;
                color: #000;
                font-size: 16px;
                border-radius: 6px;
                box-shadow: none !important;
                line-height: 24px;

                &::placeholder {
                  color: #999;
                }
              }
            }

            .form-actions {
              width: 100%;
              text-align: center;
              margin-top: 1rem;

              .custom-btn {
                width: 100%;
                height: 54px;
                background-color: var(--color-theme);
                color: white;
                border: none;
                border-radius: 5px;
                padding: 10px;
                font-size: 18px;
                line-height: 25px;
                cursor: pointer;
                transition: background-color 0.3s;
                font-weight: 600;

                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .rocket-image {
          position: absolute;
          bottom: 50px;
          right: 10px;
          width: 20%;
          max-width: 150px;
          z-index: 1;
        }
      }

      @media (max-width: 600px) {
        .left-section .form {
          padding: 2rem;
          margin-top: 2rem;
        }

        .left-section .formGroup {
          margin-bottom: 10px;
          padding: 0;
        }

        .left-section input,
        .left-section textarea {
          width: 100%;
          padding: 12px;
          box-sizing: border-box;
          background: #f6f7f9;
          border: none;
          color: #000;
          font-size: 16px;
          border-radius: 6px;
          box-shadow: none !important;
          line-height: 24px;
        }

        .left-section .form-actions .custom-btn {
          width: 100%;
          height: 54px;
          background-color: var(--color-theme);
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px;
          font-size: 18px;
          line-height: 25px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-weight: 600;

          &:hover {
            opacity: 0.8;
          }
        }
      }


      .right-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin-bottom: 1rem;
          font-size: 39px;
          font-weight: 600;
          color: #23282E;
          line-height: 52px;


        }

        h2 .text-highlight {
          color: var(--color-theme);
          font-weight: bold;
        }

        .description {
          font-weight: 400;
          margin-bottom: 1rem;
          font-size: 16px;
          line-height: 25px;
          color: #6c6c6c !important;
          text-align: justify;
        }
      }

      @media (max-width: 800px) {
        .right-section h2 .text-highlight {
          color: var(--color-theme);
          font-weight: bold;
          line-height: 36px;
        }

        .right-section h2 {
          font-size: 22px;
          text-align: center;
          margin-bottom: 1rem;
        }

        .right-section .description {
          font-weight: 400;
          margin-bottom: 1rem;
          font-size: 16px;
          line-height: 25px;
          color: #6c6c6c !important;
          text-align: justify;
        }
      }

    }

   .section10 {
      height: unset;

      @media (max-width: 600px) {
        height: auto;
        flex-direction: column;
        padding: 1rem;
      }

      padding: 3rem;
      /* Default padding for larger screens */

      @media (max-width: 900px) {
        padding: 2rem;
        /* Padding for screens 900px or smaller */
      }

      @media (max-width: 600px) {
        padding: 1rem;
        /* Padding for screens 600px or smaller */
      }

      @media (max-width: 400px) {
        padding: 1rem;
        /* Padding for very small screens */
      }

      .games-accordion-section {
        display: flex;
        justify-content: space-between;


        @media (max-width: 600px) {
          display: block;
          padding: 0;
          /* Reset internal padding if needed */
        }

        @media (max-width: 900px) {
          flex-direction: column;
        }

        .title-wrapper {
          width: 50%;

          @media (max-width: 900px) {
            width: 100%;

          }

          .section-wrapper {
            font-size: 2.5rem;
            color: #333;
            margin: 0 0 3rem 0;
            font-weight: 800;

            .title-wrapper1 h2 {
              font-weight: 600;
              font-size: 34px;
              color: #23282E;
              margin: 0 0 1rem 0;
              line-height: 45px;
            }

            .title-wrapper1 h2 .text-highlight {
              color: var(--color-theme);
              font-weight: bold;
            }

            .title-wrapper1 .description {
              font-weight: 400;
              margin-bottom: 1rem;
              font-size: 16px;
              line-height: 25px;
              color: #6c6c6c;
            }

            @media (max-width: 600px) {
              .title-wrapper1 h2 {
                font-size: 22px;
                text-align: center;
              }

              .title-wrapper1 .description {

                margin-bottom: 2rem;

              }
            }


            .explore-more {
              text-align: left;

              @media (max-width: 600px) {
                display: flex;
                justify-content: center;
              }

              .black {
                display: inline-flex;
                font-size: 16px;
                letter-spacing: 0px;
                /* font-weight: 600; */
                line-height: 16px;
                text-decoration: none !important;
                /* text-transform: uppercase; */
                background-color: #242830;
                color: #fff !important;
                border: none;
                /* border-radius: 2px; */
                cursor: pointer;
                justify-content: center;
                padding: 16px 32px;
                height: 48px;
                text-align: center;
                white-space: nowrap;
              }
            }
          }
        }

        .image-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .button-group {
            width: 100%;
            margin-bottom: 20px;
            padding: 0 2rem 0 2rem;
            /* Default padding */


            @media (max-width: 600px) {
              padding: 0;
            }

            .button-container {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              justify-content: space-between;
              margin: 0 0 2rem 0;

              .button {
                padding: 10px 20px;
                font-size: 16px;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                transition: background-color 0.3s, box-shadow 0.3s;

                &.btn {
                  &.red {
                    background-color: var(--color-theme);
                    color: #fff;

                    &:hover {
                      background-color: #e64a19;
                    }
                  }

                  &.white {
                    background-color: #fff;
                    color: #333;
                    box-shadow: 0 5px 19px var(--color-theme)52;

                    &:hover {
                      background-color: #f4f4f4;
                    }
                  }
                }

                &.shdw {
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
        }


      }
    }


  }


}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(225, 89, 109, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(225, 89, 109, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(225, 89, 109, 0);
  }
}

.words {
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: 10px;
  height: 84px; /* Matches the height of each word */
  white-space: nowrap;
}

.word {
  display: block;
  height: 84px;
  line-height: 115px;
  color: var(--color-theme);
  white-space: nowrap;
  animation: scrollWords 9s ease-in-out infinite;
}

@keyframes scrollWords {
  0%, 25% {
    transform: translateY(0); /* First word visible, pause */
  }
  33%, 58% {
    transform: translateY(-100%); /* Second word visible, pause */
  }
  66%, 91% {
    transform: translateY(-200%); /* Third word visible, pause */
  }
  100% {
    transform: translateY(-300%); /* Transition back to first word smoothly */
  }
}

.text-highlight {
  color: var(--color-theme);

  /* Example highlight color */
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .title {
    font-size: 1.2em;
    /* Adjust font size for smaller screens */
    white-space: normal;
    /* Allow wrapping */
    text-align: center;
    /* Center text for smaller screens */
  }


}


@media (max-width: 600px) {
 .words {
    display: inline-block;
    overflow: auto;
    position: relative;
    top: 10px;
    height: 67px;
    white-space: nowrap;
  }
}

.desktopView {
  display: block !important;
}

.mobileView {
  display: none !important;
}

/* Media query for smaller screens (mobile) */
@media (max-width: 700px) {
  .desktopView {
    display: none !important;
  }

  .mobileView {
    display: block !important;
  }
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-image: url('../../assets/icons/backtotop.png');
  background-size: cover;
  background-position: center;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 103px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
  transition: opacity 0.3s ease;
  opacity: 1;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wordsz {
  display: inline-block;
  overflow: hidden;
  /* Hide the overflow to create a smooth scroll */
  position: relative;
  top: 10px;
  height: 44px;
  /* Adjusted to match line height */
  white-space: nowrap;
}

.wordz {
  font-size:21px;
  display: block;
  line-height: 30px;
  color: var(--color-theme);
  white-space: nowrap;
  animation: scrollWords1 6s ease-in-out infinite;
}

@keyframes scrollWords1 {
  0% {
    transform: translateY(0%);
  }

  33% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-100%);
  }

  83% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(-200%);
  }
}



.text-highlight {
  color: var(--color-theme);

}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .title {
    width: 100%;
    line-height: normal;
    /* color: white; */
    margin: 1rem;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    margin-bottom: 1rem;
    font-family: Fira Sans;
    /* font-size: 50px; */
    font-weight: 700 !important;
    line-height: 15px;
    text-align: center;
    display: ruby-text;
  }


}


@media (max-width: 600px) {


  .wordsz {
    display: inline-block;
    overflow: auto;
    position: relative;
    top: 10px;
    height: 32px;
    white-space: nowrap;
  }


}

.mobileCarousel {
  display: none !important;
}

@media (max-width: 768px) {
  .section3 .react-multi-carousel-list {

    width: 100%!important;
    margin: 0 ;
  }
  .mobileCarousel {
    display: block !important;
  }

  .section1 {
    height: unset !important;
  }

  .section3 .carousel-games-item .games-item {
    width: 155px !important;
    height: 238px !important;

  }

  .section3 .view-all-games .centered-button {
    margin-bottom: 0px!important;
  }

  .section6 {
    height: auto !important;
    padding: 0px!important
  }
.section6 .right-section{
  padding: 1rem!important;
}
  .section4 .games-elevate-section .left-side h2 {
    font-size: 22px;
    line-height: normal;
    text-align: center;
    margin-bottom: 2rem;
    /* margin-top: 2rem; */
  }

  .section4 .games-elevate-section .right-side .right-title {
    font-size: 18px;
    line-height: 38px;
    text-align: center;
  }

  .section4 .games-elevate-section .right-side .right-description {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 3rem;
    color: #6c6c6c;
  }

  .section5 {
    padding-top: 0rem;
    padding-bottom: 0rem;

  }

  .section6 .right-section h2 {
    font-size: 22px;
    line-height: 29px !important;
    text-align: center;
    margin-bottom: 1rem;
  }

  .section6 .right-section h3 {
    font-size: 18px;
    line-height: 38px;
    text-align: center;
  }

  .section6 .right-section p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #6c6c6c;
    margin-bottom: 1rem;
    margin: 0rem;
  }

  .section6 .right-section .games-grid-items-container {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
    gap: 1rem;
    /* Adjust the gap between items if needed */
  }

  .section6 .right-section .explore-more {
    display: flex;
    /* Use flexbox to center content */
    justify-content: center;
    /* Center horizontally */
    margin-top: 3rem;
    /* Adjust margin-top as needed for spacing */
  }

  .section6 .right-section .explore-more .btn {
    display: inline-flex;
    /* Ensure button uses inline-flex for alignment */
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 16px;
    text-decoration: none !important;
    background-color: #242830;
    color: #fff !important;
    border: none;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    height: 48px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 1rem;
  }

  .section6 .right-section .games-grid-items-container .explore_card {
    display: flex;
    flex-direction: column;
    /* Stack content within each card vertically */
    align-items: center;
    /* Center content within each card */
    text-align: center;
    /* Center text inside cards */
    width: 100%;
    /* Ensure cards take full width on mobile */
    padding: 0rem;
    /* Adjust padding inside cards if needed */
  }

  .section6 .right-section .games-grid-items-container .card-icon {
    margin-bottom: 0.5rem;
    /* Space below the icon */
  }

  .section6 .right-section .games-grid-items-container .card-title {
    font-size: 18px;
    /* Adjust font size for mobile */
  }

  .section7 {
    min-height: unset !important;
    height: auto;
    display: flex;
    /* Use flexbox to center content */
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    text-align: center;
    /* Center text inside container */
    padding: 2rem;
    /* Optional padding to add space around content */
  }

  .section7 .img-container {
    display: flex;
    /* Use flexbox to center content inside the container */
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    margin-top: 0;
    /* Reset margin-top for centering */
  }

  /* .section7 .img-container img {
    display: none;
  } */

  .section7 .heading {
    margin-top: 3rem;
    margin-top: 2rem;
    /* Add margin-top if needed to separate from the image */
  }

  .section8 {
    min-height: 500px;
    display: flex;
  }

  .section8 .games-view-plan-section {
    text-align: center;
    padding: 2rem;
    color: #fff;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .section8 .games-view-plan-section h2 {
    line-height: 52px;
    color: white;
    margin: 1rem;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .section8 .games-view-plan-section .inputs-container .input-group input {
    font-size: 14px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background: #fafafa;
    border: none;
    border-radius: 6px;
    margin-bottom: 1rem;
  }

  .section8 .games-view-plan-section .user-btn .btn {
    display: inline-flex;
    /* Ensure button uses inline-flex for alignment */
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 16px;
    text-decoration: none !important;
    background-color: var(--color-theme);
    color: #fff !important;
    border: none;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    height: 48px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 1rem;
  }

  .section9 {
    display: flex;
    padding: 2rem;
    height: auto;
    flex-direction: column;
  }

  .section9 .left-section form {
    max-width: 558px;
    width: 100%;
    padding: 2rem;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0px 4px 50px 0px #D5D5D540;
    position: relative;
    z-index: 2;
    margin-bottom: 3rem;
  }

  .section9 .left-section form .form-row .formGroup input {
    font-size: 14px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background: #fafafa;
    border: none;
    border-radius: 6px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .section9 .left-section form .form-row .formGroup textarea {
    font-size: 14px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background: #fafafa;
    border: none;
    border-radius: 6px;
  }

  .section9 .left-section form .form-row .form-actions {
    display: flex;
  }

  .section9 .left-section form .form-row .form-actions button {
    margin: 0 auto;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: var(--color-theme);
    color: #fff;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .section9 .right-section h2 {
    margin-top: 3rem;
    line-height: 32px !important;
    color: #000;
    margin: 1rem;
    font-size: 22px;
    font-weight: 600;
    text-align: center !important;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .section9 .right-section p {
    line-height: 30px;
    color: #6c6c6c;
    margin: 1rem;
    font-size: 16px;

    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .section10 .games-accordion-section .title-wrapper .section-wrapper .title-wrapper1 h2 {
    line-height: 30px;
    color: #000;
    margin: 1rem;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 0px !important;
    margin-top: 1rem !important;
  }

  .section10 .games-accordion-section .title-wrapper .section-wrapper .title-wrapper1 p {
    line-height: 30px;
    color: #6c6c6c;
    margin: 1rem;
    font-size: 16px;

    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 0rem;
    margin-bottom: 2rem;
  }

  .section10 .games-accordion-section .title-wrapper .section-wrapper .explore-more {
    display: flex;
    justify-content: center;
  }

  .section10 .games-accordion-section .title-wrapper .section-wrapper .explore-more .black {
    margin: 0 auto;
    display: inline-flex;
    font-size: 16px;
    letter-spacing: 0px;
    /* font-weight: 600; */
    line-height: 16px;
    text-decoration: none !important;
    /* text-transform: uppercase; */
    background-color: #242830;
    color: #fff !important;
    border: none;
    /* border-radius: 2px; */
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    height: 48px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 1.5rem;
  }

  .section10 .games-accordion-section .image-wrapper .accordion {

    padding: 0rem;
  }

  .section10 .games-accordion-section .image-wrapper .button-group .buttongrp {
    margin: 0rem;
    margin-bottom: 3rem;
  }

  .section3 .btnstyle {
    margin-bottom: 1rem !important;
  }
}


.redbtnHomePage {
  padding: 1rem 2rem;
  background-color: var(--color-theme);
  color: white;
  display: inline-flex;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  text-decoration: none !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  /* height: 58px; */
  /* width: 190px; */
  text-align: center;
  white-space: nowrap;
  width: Fixed(190px) px;
  height: Hug(58px) px;
  padding: 10px;
  gap: 8px;
}

.transparentbtnHomePage {
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: none;
  background-color: #242830;
  border-radius: 6px;
  cursor: pointer;
  padding: 16px 32px;
  height: 40px;
  width: 150px;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  width: Fixed(190px) px;
  height: Fixed(58px) px;
  padding: 17px 49px 17px 49px;
  gap: 7px;
  background: rgba(255, 255, 255, 0.2);
}

.font-16 {
  font-size: 18px !important;
  font-weight: 600;
  text-align: left;
  color: #23282E;
}

.font-grey {
  color: #6c6c6c;
}

.h2color {
  color: #23282E !important;
  font-size: 22px !important;
}

.highligtSpan {
  color: var(--color-theme) !important;
}

.btnRed {
  padding: 17px 38px 17px 42px;
  background-color: var(--color-theme);
  color: white;
  display: inline-flex;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  text-decoration: none !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  height: 58px;
  width: 190px;
  text-align: center;
  white-space: nowrap;
  gap: 8px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  /* Add transition */
}

.btnRed:hover {
  background-color: #23282E !important;
  /* Background color on hover */
  color: white !important;
  /* Text color on hover */
}




.img-container span {
  position: relative;
  font-size: 40px;
  line-height: 30px;
  color: #23282E;
  margin-bottom: 0px;
  /* font-family: 'firaSans-medium', sans-serif; */
  font-weight: 700;
  text-align: left;
}

.img-container div {
  font-size: 40;
  line-height: 30px;
  color: #23282E;
  margin-bottom: 0px;
  /* font-family: 'firaSans-medium', sans-serif; */
  font-weight: 700!important;
  text-align: left;
}

.img-container div .highligtSpan2 {
  font-size: 40;
  line-height: 30px;
  color: var(--color-theme);
  margin-bottom: 0px;
  /* font-family: 'firaSans-medium', sans-serif; */
  font-weight: 500;
  text-align: left;
}

.img-container .img {
  position: absolute;
 
}

@media (max-width: 768px) {
  .image-container {
    padding: 0px !important;
  }

  .img-container span {
    display: -webkit-box !important;
    display: flex;
    text-align: center;
    font-size: 18px !important;
    justify-content: center;
  }

  .img-container .img {
    width: 20px;
    /* Adjust image size for small screens */
    position: absolute;
    top: 18%;
    left: 16%;
    width: 22px;
  }
}

.bg-grey {
  background-color: #f9f9f9;
}

@media (max-width: 640px) {
  .customClass {
    position: absolute !important;
    top: -10% !important;
    left: -7% !important;
    width: 22px !important;

  }

  .customcssteamlovesectionimg {
    position: absolute !important;
    top: -9% !important;
    left: -12% !important;
    width: 22px !important;
  }

  .diferenceImg {
    position: absolute !important;
    top: 14% !important;
    left: 13% !important;
    width: 22 !important;
  }

  .section8 .games-view-plan-section p {
    font-size: 16px !important;
    line-height: normal;
    color: #fff;
    margin-bottom: 0px;
    font-family: 'firaSans-medium', sans-serif;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1rem;
  }
}

/* Container that allows horizontal scrolling */
.button-containergames {
  cursor: pointer;
  width: 90vw;
  display: flex;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent buttons from wrapping to the next line */
  padding: 10px;
  /* Add padding if needed */
  scroll-snap-type: x mandatory;
  /* Snap scrolling to the buttons */
}

.button-containergames::-webkit-scrollbar {
  height: 8px;
  /* Height of the scrollbar */
}

/* Styling for the scrollbar */
.button-containergames::-webkit-scrollbar {
  height: 0px;
  /* Adjust height of the scrollbar */

}

.button-containergames::-webkit-scrollbar-thumb {
  background-color: #6c6c6c;
  /* Scrollbar thumb color */
  border-radius: 17px;
}

/* Styling for each button */
.buttonGames {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: #fff;
  color: #23282E;
  cursor: pointer;
  border-radius: 20px;
  scroll-snap-align: center;
  transition: background-color 0.3s ease;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.buttonGames:hover {
  background-color: #ddd;
  /* Darker background on hover */
}

/* Optional: Styling for the active button */
.buttonGames.active {
  background-color: var(--color-theme);
  /* Blue background for active button */
  color: white;
  /* White text color */
}

.removeBgCenterText {
  box-shadow: none !important;
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}

.titleFontSize {
  font-size: 40px !important;
  line-height: 70px !important;
  color: #23282e !important;
  margin-bottom: 0px !important;

  font-weight: 700 !important;
  text-align: left !important;

}

.highlightCardSpan {
  font-size: 18px !important;
  line-height: 30px !important;


  
  font-weight: 500 !important;
  text-align: left !important;
  color: var(--color-theme) !important;
}

.font-40 {
  font-size: 40px !important;
}


.shadowMobileCard {
  box-shadow: 0px 4px 34px 0px #B0B0B040 !important
}


.title {
  width: 90%;
  line-height: normal;
  color: white;
  margin: 1rem;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;

  font-family: Fira Sans;
  font-size: 60px;
  font-weight: 700 !important;
  line-height: 12px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 22px !important;
    line-height: 22px;
    width: 100%;
  }
}

.paragraph {
  font-family: Fira Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #B7B7B7;
  margin: 1.5rem 0;

  @media (max-width: 600px) {

    margin: 1rem 0;
  }
}

.button-group-main-banner {
  display: flex;
  justify-content: center;

  .btn {
    display: inline-flex;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    text-align: center;
    margin: 10px;
    transition: background-color 0.3s ease-in-out;
    /* Smooth hover transition */

    &.red {
      padding: 1rem 2rem;
      background-color: var(--color-theme);
      color: white;
      border-radius: 5px;
      height: 58px;
      width: 190px;
      text-align: center;
      white-space: nowrap;

      @media (max-width: 600px) {
        width: 140px;
        height: 37px;
        font-size: 14px;
      }

      @media (min-width: 601px) and (max-width: 900px) {
        width: 120px;
        height: 37px;
        font-size: 14px;
      }

      &:hover {
        background-color: #c62828;
        /* Hover effect */
      }
    }

    &.transparent {
      border: 2px solid white;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      height: 58px;
      width: 190px;
      white-space: nowrap;

      @media (max-width: 600px) {
        width: 120px;
        height: 37px;
        font-size: 14px;
      }

      @media (min-width: 601px) and (max-width: 900px) {
        width: 120px;
        height: 37px;
        font-size: 14px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
        /* Hover effect */
        color: #fff;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &:hover {
      background-color: #f9f9f9b3;
      /* General hover effect */
    }
  }
}



.custom-position {
  left: -6%;
  top: -65%
}

@media (max-width: 768px) {
  .custom-position {
    left: -9%;
  }
}



.parent {
  position: relative;
  width: 100%;
  /* Set your desired width */
  height: 100vh;
  /* Set your desired height */
}

.image1 {
  position: absolute;
  top: 100px;
  /* Adjust the position of image1 */
  left: 100px;
  /* Adjust the position of image1 */
  width: 150px;
  /* Adjust image size */
  height: auto;
  /* Maintain aspect ratio */
}

.image2 {
  position: absolute;
  bottom: 50px;
  /* Adjust as needed for image2 */
  left: 20px;
  /* Adjust as needed for image2 */
  width: 100px;
  /* Adjust image size */
  height: auto;
  /* Maintain aspect ratio */
}

.circle-shadow {
  position: absolute;
  top: -7%;
  left: 23%;
  width: 50px;
  /* height: 50px; */
  border-radius: 50%;
  box-shadow: -4px 48px 84px 43px rgba(181, 36, 3, 1);
  ;
}

.gradient {
  background-image: linear-gradient(80deg, 
  hsl(0deg 0% 100%) 0%, 
  hsl(53deg 63% 97%) 45%, 
  hsl(54deg 62% 93%) 68%, 
  hsl(54deg 61% 90%) 81%, 
  hsl(31deg 100% 92%) 89%, /* Lightened this yellow */
  hsl(16deg 99% 94%) 95%, 
  hsl(0deg 18% 97%) 100%);

}

.gradient1 {
  background-image: linear-gradient(79deg, hsl(240deg 8% 95% / 0%) 0%, hsl(271deg 20% 95% / 33%) 53%, hsl(310deg 33% 96% / 18%) 71%, hsl(335deg 78% 97%) 80%, hsl(350deg 100% 97% / 0%) 86%, hsl(8deg 100% 96% / 51%) 90%, hsl(23deg 100% 95% / 40%) 93%, hsl(34deg 100% 94% / 51%) 95%, hsl(42deg 100% 93% / 0%) 98%, hsl(56deg 76% 92%) 100%);
}


.gradient3 {
  background-image: linear-gradient(145deg, hsl(0deg 0% 100%) 0%, hsl(242deg 32% 99%) 22%, hsl(242deg 32% 98%) 38%, hsl(242deg 32% 96%) 51%, hsl(242deg 32% 95%) 61%, hsl(241deg 32% 94%) 68%, hsl(241deg 32% 93%) 74%, hsl(241deg 32% 92%) 79%, hsl(241deg 32% 91%) 84%, hsl(241deg 32% 89%) 87%, hsl(241deg 31% 88%) 90%, hsl(241deg 31% 87%) 93%, hsl(240deg 31% 86%) 95%, hsl(240deg 31% 85%) 97%, hsl(240deg 31% 84%) 99%, hsl(240deg 31% 82%) 100%);
}

.gradient4 {
  background-image: linear-gradient(280deg, hsl(125deg 0% 100% / 96%) 0%, hsl(113deg 32% 96% / 1%) 55%, hsl(33deg 32% 93% / 0%) 76%, hsl(241deg 32% 89% / 61%) 88%, hsl(240deg 31% 86% / 49%) 96%, hsl(240deg 31% 82% / 0%) 100%);
}
/* Button styling */
.rocket-button {
  background: transparent;
  padding: 0;
  align-items: center;
  background-color: var(--color-theme);
  color: white;
  display: inline-flex;
  text-decoration: none !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  height: 58px;
  width: 190px;
  text-align: center;
  position: relative; /* Position relative for proper absolute positioning of .fly-container */
  overflow: hidden; /* Hide overflow to prevent animation from going outside */
}

/* Hover effect for the button */
.rocket-button:hover {
  background-color: #f01919;
}

/* Initial style for the rocket (before hover) */
.rocket {
  width: 35px;
  height: 50px;
  background-image: url('../../assets/icons/Rocket_Sprite.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: background-position 0.8s;
}

/* Animation on hover */
.rocket-button:hover .rocket {
  animation: fly 0.8s steps(4) infinite;
}

/* Keyframes for sprite animation */
@keyframes fly {
  100% {
    background-position: -200px;
  }
}

/* Container for the flying image */
.fly-container {
  position: absolute;
  bottom: 0px;
  right: -15px;
  width: 51px;
  height: 40px;
}

/* Flying image style */
.fly-image {
  width: 15px; /* Set to the width of the image */
  height: 20px; /* Set to the height of the image */
  position: absolute; /* Absolute positioning for animation */
  bottom: 0; /* Start from the bottom of .fly-container */
  animation: fly1 3s ease-in-out forwards;
}

/* Keyframes for vertical flight */
@keyframes fly1 {
  0% {
    transform: translateY(0); /* Start position */
    opacity: 1;
  }
  100% {
    transform: translateY(-200px); /* Adjust to desired vertical distance */
    opacity: 0; /* Fade out */
  }
}

.video-banner {
  width: 100vw;    /* Full width of the viewport */
  height: 100vh;   /* Full height of the viewport */
  object-fit: cover; /* Ensure the video covers the area while maintaining aspect ratio */
}

.section8 {
  background-image: url('../../assets/icons/desktopBannerSpinWheel.jpg');
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .section8 {
    background-image: url('../../assets/icons/Mobile_Bg_Spinwheel.jpg');
  }
}

.highlightSpan{
  color:var(--color-theme)!important;
}