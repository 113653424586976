.payment-details-modal .modal-content{
    width: 450px;
    padding: 2%;
    font-family: "firaSans-medium";
    border-radius: 20px;
}
.payment-details-modal .title img{
    width: 70px;
    right: 0;
    margin-top: -15px;
}
.payment-details-modal .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    width: 100%;
}
.payment-details-modal .close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -15px;
}
.payment-details-modal .close button{
    padding: 10px 10px;
    width: 100px;
    border-radius: 5px;
    border: unset;
    color:white;
    background: #dc3545;
    border-color: #dc3545;
    font-weight: 400;
    font-family: "FiraSans-Medium";
    cursor: pointer;
    margin: 0 5px;
}
.payment-details-modal .details{
    display: flex;
    justify-content: center;
    width: 100%;
}
.payment-details-modal .details ul{
    padding: 0;
    width: 100%;
}
.payment-details-modal .details li{
    display: flex;
    max-height: 30px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.payment-details-modal .details li span{
    padding: 0px 3px;
}
.payment-details-modal .details li span.label{
    font-weight: 100;
    opacity: 0.6;
}
@media screen and (max-width: 1200px) {
    .payment-details-modal .details li span{
        font-size: 14px;
    }
}
@media screen and (max-width: 1100px) {
    .payment-details-modal .title img{
        width: 60px;
    }
}
@media screen and (max-width: 990px) {
    .payment-details-modal .details{
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 500px) {
    .payment-details-modal .title img{
        width: 50px;
    }
}
@media screen and (max-width: 400px) {
    .payment-details-modal .details li{
        max-height: 45px;
    }
}