/*controls */
.orgDetails-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 15px 0;
  }
  /* slider part */
  .orgDetails-actions  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .orgDetails-actions  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .orgDetails-actions  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 4px;
  }
  .orgDetails-actions  .disabled{
    cursor: not-allowed;
  }
  
  .orgDetails-actions  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 4px;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .orgDetails-actions  input:checked + .slider {
    background-color: #2AB070;
  }
  
  .orgDetails-actions  input:focus + .slider {
    box-shadow: 0 0 1px #2AB070;
  }
  
  .orgDetails-actions  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  .admin-homepage .data-table .view-btn.delete{
    width: 30px;
    height: 30px;
    padding: 5px 5px;
  }
  /*slider end*/

  .orgs-btn-inner-container{
    width: 400px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .orgs-btn-inner-container button{
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: var(--background-theme);
    color: white;
  }
  .orgs-btn-inner-container button:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width:770px) and (max-width:991px) {
    .orgs-btn-inner-container{
      width: 380px;
    }
    .input-icon{
      width: 40% !important;
    }
  }

  @media screen and (min-width:601px) and (max-width:770px) {
    .orgs-btn-inner-container{
      width: 330px;
    }
    .input-icon{
      width: 40% !important;
    }
    .orgs-btn-inner-container button{
      font-size: small;
    }
  }

  @media screen and (max-width:601px){
    .addEdit-Orgsbtn-searchBar-container{
      flex-direction: column;
      align-items: flex-start;
    }
    .orgs-btn-inner-container{
      width: 330px;
      margin-bottom: 10px;
    }
    .orgs-btn-inner-container button{
      font-size: small;
    }
  }
  @media screen and (min-width:320px) and (max-width:363px) {
    .orgs-btn-inner-container{
      width: 280px;
    }
    .orgs-btn-inner-container button{
      font-size: small;
    }
  }

  @media screen and (max-width:320px){
    .orgs-btn-inner-container{
      width: 250px;
    }
    .orgs-btn-inner-container button{
      font-size: 0.7rem;
    }
  }

  .search-container-org {
    margin: 1rem 0;
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: space-between; /* Distribute items evenly along the main axis */
  }

  @media (max-width: 600px) {
    .search-container-org {
        display: block;
    }
}