.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.download-btn {
  width: 24px;
  height: 24px;
  background: var(--color-theme_1);
  border-radius: 4px;
  padding: 0;
  border: 0px solid transparent;
  cursor: pointer;
}

.download-btn img {
  margin: 0;
}

.plandetail-wrapper {
  padding: 3.5% 5%;
  background: #fff;
  min-height: 80vh;
  margin: -2% -3% 0% 0%;
  border-radius: 20px;
}

.plandetail-card {
  max-width: 1160px;
}

#xoxoday-iframe {
  /* border: 1px solid black; */
  width: 100%;
  /* height: 800px; */
  min-height: 800px;
}

.points-table table {
  margin-top: 20px;
  width: 100%;
}

.thead-points {
  background-color: #dfb2f3;
  padding: 15px !important;
}

.thead-earned {
  background-color: #f68c3e;
}

.trow-head {
  background-color: #f8fdff;
  padding: 10px;
}

.trow-data {
  background-color: #fffdee;
  padding: 10px;
}

.redeem-points {
  background-color: #5f6389;
  color: #fff;
  width: 60px;
  padding: 3px;
  margin-left: 10px;
  text-align: center;
}

.points-dashboard-btn-group {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  white-space: nowrap;
  width: 100%; /* Make sure the container takes full width */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.points-dashboard-btn-group .group-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20%;
  padding: 20px;
  border-radius: 5px;
  font-family: "firaSans-semibold";
  font-size: 18px;
  color: #e25569;
  border: 1px solid #e25569;
  cursor: pointer;
}

.points-dashboard-btn-group .group-item.disabled {
  cursor: not-allowed;
}

.points-dashboard-btn-group .active {
  color: white;
  background: var(--color-theme_1);
}

@media screen and (max-width: 991px) {

  .points-table table {
    width: 100%;
  }

  .thead-points,
  .thead-earned,
  .trow-head,
  .trow-data {
    padding: 10px !important;
  }

  .redeem-points {
    width: 40px;
    padding: 2px;
    margin-left: 5px;
  }

  .points-dashboard-btn-group {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
    justify-content: flex-start; /* Change to flex-start */
  }

  .points-dashboard-btn-group .group-item {
    flex-shrink: 0;
    padding: 10px 15px;
    border-radius: 5px;
    font-family: "firaSans-semibold";
    font-size: 18px;
    border: 1px solid #e25569;
    cursor: pointer;
    margin-left: 70px;
  }
}

@media screen and (max-width: 767px) {
  .points-dashboard-btn-group .group-item {
    padding: 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .points-dashboard-btn-group {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding: 10px;
  }

  .points-dashboard-btn-group .group-item {
    flex-shrink: 0;
    padding: 10px 15px;
    border-radius: 5px;
    font-family: "firaSans-semibold";
    font-size: 16px;
    border: 1px solid #e25569;
    cursor: pointer;
    margin: 20px 5px;
    width: fit-content;
  }
}