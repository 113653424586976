/* App.css */
.container-feature {
    width: 100%; /* Ensure container takes full width */
    padding: 0 20px; /* Optional: add padding to the container */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
    margin-top: 1rem;
}

.formRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #f6f7f9;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    /* margin-bottom: 20px; */
    align-items: center;
}

.form-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left; /* Center the content horizontally */
    margin-right: 10px; /* Space between columns */
}

.form-column:last-child {
    margin-right: 0; /* Remove margin from the last column in a row */
}

.remove-column {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    align-items: center; /* Center the button vertically */
}

.image-title {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center; /* Center the title text */
}

.preview-image {
    height: 150px; /* Fixed height for the preview image */
    width: auto; /* Automatically adjust width to maintain aspect ratio */
    margin-top: 10px;
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.remove-button:hover {
    background-color: #c82333;
}

.add-row-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 14px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
  
}

.add-row-button:hover {
    background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
    .formRow {
        flex-direction: column; /* Stack columns vertically on smaller screens */
    }

    .form-column {
        margin-right: 0; /* Remove margin on smaller screens */
        margin-bottom: 10px; /* Add space between columns vertically */
    }

    .form-column:last-child {
        margin-bottom: 0; /* Remove bottom margin from the last column */
    }
}
.preview-image {
    width: 200px;
    height: 100px;
    margin-top: 10px;
}

.preview-video {
    width: 200px;
    height: auto;
    margin-top: 10px;
}
.errMsg{
    color: red;
    font-size: 12px;
}

