.game-subheader {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 40px;
  flex-direction: column;
}

.game-subheader-right button {
  height: 48px;
  width: 180px;
  font-family: 'firaSans-regular';
}

.game-subheader-left {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0,0,0,.1); */
  padding-bottom: 16px;
}

.game-subheader-left .like-share .favourite .fav-child {
  stroke: #000;
}

.game-subheader-left .like-share .favourite:hover .fav-child {
  stroke: var(--color-theme);
}

.game-subheader-left .like-share {
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.game-subheader-left .game-rating h6 {
  font-weight: 600;
}

.game-subheader-right {
  padding: 24px 0 0;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

/* .game-subheader-right .invite{
  width: 160px;
} */
.game-subheader-right .invite:nth-child(2) {
  /* width: 240px; */
}

.game-subheader-right a {
  display: block;
}

.game-subheader-right .play-btn-conatiner {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
}

.game-subheader-right .play-game-btn {
  /* position: absolute; */
  right: 0;
  width: 180px;
}

.game-subheader-right .download .play-game-btn {
  width: fit-content;
  padding: 11px 11px;
}

.org-roles {
  display: flex;
  justify-content: space-between;
}

.game-subheader-right .org-roles .play-game-btn {
  position: unset;
}

.game-subheader-right .btn-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #E25569;
  margin-right: 5px;
  padding: 8px;
}

/* upgrade plan */
.game-subheader-right .btn-detail .download-btns {
  display: flex;
}

.btn-detail {
  position: relative;
  width: 100%;
}

.btn-detail .note-info {
  position: absolute;
  margin-top: 4px;
  left: 208px;
}

/* toogle switch */
.game-title-wrapper .toggle-btn {
  display: flex;
  align-items: center;
}

.game-title-wrapper .game-status {
  display: flex;
  margin-right: 12px;
}

.game-title-wrapper .game-status h5 {
  font-size: 16px;
  line-height: 24px;
  color: #23282E;
  font-weight: normal;
  margin-left: 8px;
}

/* The switch - the box around the slider */
.game-title-wrapper .switch {
  position: relative;
  display: inline-block;
  height: 24px;
  border-radius: 4px;
  width: 100px;
}

/* Hide default HTML checkbox */
.game-title-wrapper .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.game-title-wrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C4C4C4;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 4px;
  width: 48px;
}

.game-title-wrapper .slider:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  left: 4px;
  bottom: 3px;
  border-radius: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.game-title-wrapper .slider:after {
  content: 'offline';
  position: absolute;
  left: 54px;
  font-size: 16px;
  line-height: 24px;
  color: #C4C4C4;
  /* transition: content .2s ease-in-out; */
}

.game-title-wrapper input:checked+.slider {
  background-color: #2AB070;
}

.game-title-wrapper input:focus+.slider {
  box-shadow: 0 0 1px #2AB070;
}

.game-title-wrapper input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.game-title-wrapper input:checked+.slider:after {
  content: 'Live';
  color: #2AB070;
  font-weight: 500;
  font-family: 'firaSans-medium';
}

/* button tooltip */
.btn-tooltip{
  position: relative;
  margin-left: 16px;
}

.btn-tooltip .btn-secondry:hover+.tooltip,
.btn-tooltip .btn-outline:hover+.tooltip,
.btn-tooltip .btn-primary:hover+.tooltip,
.btn-tooltip .btn-lightbg:hover+.tooltip {
  display: flex;
  justify-content: center;
}

.btn-tooltip .tooltip {
  width: 224px;  
  position: absolute;
  left: 50%;
  top: -55px;
  background: #404040;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}
/* 
.btn-limit-tooltip .tooltip {
  width: 265px;
  position: absolute;
  left: 40%;
  top: -45px;
  background: #404040;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
} */

@media screen and (max-width: 330px) {
  .btn-tooltip .tooltip{
    width: 224px !important;  

  }
}

.btn-tooltip .tooltip:before{
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #404040;
  left: 43%;
  bottom: -12px;
  transform: rotate(-90deg);
}

.btn-tooltip .tooltip.center {
  text-align: center;
  width: 148px;
}

.btn-tooltip .tooltip span{
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.btn-tooltip .tooltip button{
  width: 62px;
  min-width: 62px;
  height: 22px;
  left: 146px;
  top: 11px;
  background: #E25569;
  border-radius: 4px;
  border: 0px solid;
  outline: 0;
  box-shadow: none;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  justify-content: center;
  margin-bottom: 0;
}

.game-info-tabs-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e1e6ea;
  border-radius: 5px;

  /* margin-left: 15px; */
}

.game-info-tabs-cont p {
  padding: 10px;
}

.game-info-tabs-cont>p>span {
  color: var(--color-theme);
}

.game-info-tabs-cont-mobile {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e1e6ea;
  border-radius: 5px;
  /* margin-left: 15px; */
}

.game-info-tabs-cont-mobile p {
  padding: 10px;
}

.game-info-tabs-cont-mobile > p > span {
  color: var(--color-theme);
}


@media screen and (max-width: 381px) {
  .game-info-tabs-cont-mobile p {
    font-size: 12px;
  }
}

@media screen and (min-width: 381px) and (max-width: 449px) {
  .game-info-tabs-cont-mobile p {
    padding: 8px;
    font-size: 14px;
  }
  .game-info-tabs-cont p {
    padding: 8px;
    font-size: 14px;
  }
}

@media screen and (min-width: 449px) and (max-width: 975px) {
  .game-info-tabs-cont-mobile p {
    padding: 5px;
    font-size: 15px;
  }
  .game-info-tabs-cont p {
    padding: 5px;
    font-size: 15px;
  }

}

@media screen and (max-width: 767px) {
  .game-info-tabs-cont-mobile {
    display: flex;
    height: 48px
  }

  .game-info-tabs-cont-mobile {
    margin-bottom: 16px;
  }

  .game-info-tabs-cont-mobile p {
    padding: 10px;
  }

  .game-info-tabs-cont {
    display: none;
  }

  .game-info-tabs-cont {
    margin-bottom: 16px;
  }

  .game-info-tabs-cont p {
    padding: 10px;
  }


  .game-subheader {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .game-subheader-right {
    padding: 0;
    margin-top: 20px;
  }

  .game-subheader-right .btn-detail .download-btns {
    flex-direction: column;
  }

  .game-subheader-right .invite,
  .game-subheader-right .invite:nth-child(2) {
    width: 100%;
  }

  .game-subheader-left {
    border-bottom: 0px;
  }

  .game-subheader-left,
  .game-subheader-right,
  .btn-group {
    flex-direction: column;
  }

  .game-subheader-right .play-btn-conatiner {
    position: relative;
  }

  .game-subheader-right .play-game-btn,
  .game-subheader-right .download .play-game-btn {
    width: 100%;
  }

  .game-subheader-left .game-rating h6,
  .game-subheader-left .game-rating .star-ratings {
    margin-right: 8px;
  }

  .game-subheader-left .game-rating span::before {
    right: -10px;
  }

  .game-subheader-left .game-rating span {
    margin-right: 16px;
  }

  .game-subheader-left .like-share svg {
    margin-right: 5px;
  }

  .game-subheader-right button {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
    margin-left: 0 !important;
  }

  .game-subheader-left .like-share {
    margin-top: 16px;
  }

  .btn-detail .note-info {
    width: 200px;
    top: 164px;
    left: 0;
  }

  .btn-group {
    padding: 10px 0 8px;
  }

  .game-subheader-right .btn-group a {
    margin-left: 0;
  }

  /* toogle switch */
  .toggle-btn {
    justify-content: space-between;
  }

  .btn-tooltip {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game-subheader-right button {
    min-width: 145px;
  }

  .btn-detail .note-info {
    left: 160px;
  }

}