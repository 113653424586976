.newSignupForm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: 72px;
    display: flex;
    flex-direction: row;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: 72px;
    justify-content: center;
    @media (max-width: 800px) {
      flex-direction: column;
    }

    form {
   
      flex: 4 1;
      margin: 2.5rem;
   
      display: block;
      /* flex-direction: column; */
      justify-content: revert;

      label {
        display: flex;
        align-items: flex-start; /* Align items to start */
        margin: 15px;
        gap: 15px; /* Space between label and input container */

        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          margin: 0;       
          gap: 10px; /* Adjust gap for smaller screens */
        }

        .fname {
          flex: 0 0 25%; /* Label width */
          font-weight: bold;
          font-size: 16px; /* Adjust font size if needed */
          margin-top: 12px;
        }

        .input-container {
          flex: 1 1 auto; /* Take up remaining space */
          display: flex;
          flex-direction: column; /* Stack input and error message vertically */
          @media (max-width: 600px) {
        width: 100%;
          }
          input {
            width: 100%;
            height: 40px;
            padding: 15px;
            margin-bottom: 0.25rem; /* Space between input and error message */
            border: 1px solid rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            font-size: 16px; /* Adjust font size if needed */

            @media (max-width: 600px) {
              width: 100%; /* Full width on small screens */
            }
          }

          .errmessage {
            color: #d9534f; /* Red color for error messages */
            font-size: 0.875rem; /* Adjust font size */
            margin-top: 0.25rem; /* Space between input and error message */
            display: block;
          }
        }
      }

      .signupbtn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;

        .btn {
          background-color: initial;
          background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
          border-radius: 6px;
          box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
          color: #FFFFFF;
          cursor: pointer;
          display: inline-block;
          font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
          height: 40px;
          line-height: 40px;
          outline: 0;
          overflow: hidden;
          padding: 0 20px;
          pointer-events: auto;
          position: relative;
          text-align: center;
          touch-action: manipulation;
          user-select: none;
          -webkit-user-select: none;
          vertical-align: top;
          white-space: nowrap;
          width: auto;
          z-index: 9;
          border: 0;
          transition: box-shadow .2s;


          &:hover {
            opacity: 0.8;
          }
        }
      }

      .loginbtn-container {
        border-top: 2px solid #dadada;
        padding: 15px 0;
        text-align: center;

        p {
          color: #888;
          margin-bottom: 10px;
        }

        .btn {
          background-color: initial;
          background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
          border-radius: 6px;
          box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
          color: #FFFFFF;
          cursor: pointer;
          display: inline-block;
          font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
          height: 40px;
          line-height: 40px;
          outline: 0;
          overflow: hidden;
          padding: 0 20px;
          pointer-events: auto;
          position: relative;
          text-align: center;
          touch-action: manipulation;
          user-select: none;
          -webkit-user-select: none;
          vertical-align: top;
          white-space: nowrap;
          width: auto;
          z-index: 9;
          border: 0;
          transition: box-shadow .2s;

          &.btn-primary {
            background-color: #007bff;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .checkoutcard {
      min-width: 445px;
      height: 540px;
      margin-top: 3rem;
      width: 35%;
      background: #ffffff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 6px;
      padding: 32px;

      @media (max-width: 800px) and (min-width: 400px) {
        min-width: 380px;
        margin: 0 auto; 
        width: auto;  
        margin-bottom: 2rem;   
      }

      @media (max-width: 700px) {
        min-width: 445px;
        width: 100% !important;
        margin: 0;   
        margin-bottom: 2rem;         
      }

      .checkoutcardheading {
        display: flex;
        justify-content: space-between;

        .premium-plan-left {
          h5 {
            font-weight: 500;
            font-size: 32px;
            line-height: 38px;
            color: #23282e;
            margin-bottom: 8px;
            font-family: "firaSans-medium";
          }

          span {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.32px;
            color: #23282e;
          }
        }

        .premium-plan-right {
          width: 110px;
          height: 126px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .premium-review {
        .ribbon-3 {
          position: relative;
          width: 60%;
          height: 50px;
          background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
          left: -11%;
          color: #fff;
          text-align: center;
          line-height: 50px;
          border-radius: 0px 9px 9px 0px;
          font-size: 18px;
          font-weight: 700;

          @media (max-width: 600px) {
            left: -13%;         
          }

          &::before {
            content: "";
            position: absolute;
            top: -8px;
            left: 0;
            width: 0;
            height: 0;
            border-bottom: 8px solid #E62C03;
            border-left: 8px solid transparent;
          }
        }

        .premium-review-group {
          .premium-review-card1 {
            display: flex;
            justify-content: space-between;
            margin: 16px 0;

            span {
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0.32px;
              color: #23282e;
            }

            h5 {
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0.32px;
              color: #23282e;
              font-family: "firaSans-medium";
            }
          }

          .subtotal {
            .premium-review-card {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.5rem;

              span {
                font-size: 0.9rem;
                color: #666;
              }

              h5 {
                margin: 0;
              }

              &:last-child {
                h5 {
                  &:first-child {
                    text-decoration: line-through;
                    color: #666;
                  }
                }
              }
            }
          }
        }

        .premium-total {
          display: flex;
          justify-content: space-between;
          margin: 40px 0;

          span {
            font-family: "firaSans-semibold";
            font-weight: 600;
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.32px;
            color: #23282e;
          }

          h5 {
            font-family: "firaSans-semibold";
            font-weight: 600;
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.32px;
            color: #23282e;
          }
        }
      }
    }
  }
}
input:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

@media (max-width: 700px) {
  .newSignupForm .d-flex  .checkoutcard {min-width: unset!important;}       
}