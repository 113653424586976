/* for drag list */
.drag-list {
  user-select: none;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  grid-gap: 20px;
  justify-content: center;
}

.show-all-modal .drag-list {
  grid-template-columns: 18% 18% 18% 18% 18%;
  /* justify-content: center; */
}

.show8,
.show12 {
  grid-template-columns: 23% 23% 23% 23%;
}

.show10,
.show15 {
  grid-template-columns: 18% 18% 18% 18% 18%;
}

.drag-item {
  height: 160px;
  cursor: grab;
  font-size: 20px;
  user-select: none;
  border-radius: 10px;
  z-index: inherit;
}

.show8 .drag-item,
.show10 .drag-item {
  height: 160px;
}

.show12 .drag-item,
.show15 .drag-item {
  height: 115px;
}

.zIndex10 {
  z-index: 10;
}

.title-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 5%;
}

.game-title {
  color: #23282e;
  font-size: 18px;
  font-family: "firaSans-semibold";
  text-transform: capitalize;
  font-weight: 600;
}

/* grid */
.slogansRearangeSection {
  min-height: 120px;
  max-height: 400px;
  height: auto;
}

.slogansRearangeSection .drag-list {
  display: flex !important;
  flex-wrap: wrap;
  margin: 0;
  height: 100px;
  justify-content: flex-start;
  grid-gap: 15px;
}

@media screen and (max-width: 1100px) {
  .show-all-modal .drag-list {
    grid-template-columns: 23% 23% 23% 23%;
  }
}

@media screen and (max-width: 900px) {
  .drag-list {
    grid-template-columns: 32% 32% 32%;
    grid-gap: 16px;
  }

  .drag-item {
    height: 150px !important;
  }

  .slogansRearangeSection .drag-item {
    height: 100px !important;

  }

  .game-title {
    margin-top: 70px;
    font-size: 16px;
  }

  .show-all-modal .drag-list {
    grid-template-columns: 32% 32% 32%;
  }
}

@media screen and (max-width:676px) {
  .drag-list {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 600px) {
  .drag-list {
    grid-template-columns: 48% 48%;
    grid-gap: 10px;
    margin: 0 -20px;
  }

  .drag-item {
    height: 150px;
  }

  .show-all-modal .drag-list {
    grid-template-columns: 48% 48%;
  }

  .drag-item {
    height: 120px !important;
  }
}

@media screen and (max-width: 400px) {
  .drag-item {
    height: 100px !important;
  }

  .game-title {
    margin-top: 30px;
    margin-left: 10px;
    /* font-size: 14px; */
  }

  .show-all-modal .drag-item {
    height: 80px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 990px) {
  .drag-list {
    grid-template-columns: 32% 32% 32%;
  }

  .drag-item {
    height: 150px;
  }
}