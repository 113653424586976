.testimonial-carousel {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2rem 0;



  .carousel-container {

    /* overflow: scroll; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 480px;
    width: 70% !important;

    @media (max-width: 600px) {
      width: 100% !important;

    }
  }

  .carousel-track {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 100%;
    transition: transform 0.5s ease;
    will-change: transform;

  }

  .carousel-slide {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;
    transition: opacity 0.5s ease;

    .carouseImg {
      width: 103px;
      height: 103px;
      border-radius: 50%;
      object-fit: cover;
      filter: blur(1px);
      opacity: 0.2;
      transition: all 0.3s ease;
      padding: 8px;

      @media (max-width: 600px) {
        width: 80px;
        height: 80px;
      }
    }

    .skeletoncarouseImg {
      width: 103px;
      height: 103px;
      border-radius: 50%;
      padding: 8px;

      @media (max-width: 600px) {
        width: 80px;
        height: 80px;
      }
    }

    .carousel-text {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 0;
      padding: 0 1rem;
      box-sizing: border-box;
      z-index: 2;
      margin: 1rem;
      line-height: 25px;

      .comments {

        display: inline-block;
        box-sizing: border-box;
        position: relative;
        justify-content: center;

        img {
          position: absolute;
          top: 11px;
          left: 0px;
          width: 72px;
          height: 37px;
          z-index: -2;

          @media (max-width: 600px) {
            display: none;
          }
        }


      }

      @media (max-width: 600px) {
        margin: 10px;
      }

      h3,
      h4,
      p {
        margin: 0;
      }

      h3 {
        color: #303030;
        line-height: 22px;
        /* font-family: 'firaSans-medium'; */
        font-size: 20px;
        margin: 0px auto 0px;
        /* color: #5A3733; */
        font-weight: 600;
        line-height: 30px;

        @media (max-width: 600px) {
          margin-top: 0;
        }
      }

      h4 {
        text-transform: capitalize;
        color: #868686;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        font-weight: 400;
        margin: 0.5rem;

      }

      p {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 17px;
        line-height: 24px;
        display: block;
        z-index: 2;
        /* opacity: 0.8; */
        color: #4f4f4f;
        text-align: center;
        padding-left: 2rem;

        @media (max-width: 600px) {
          margin: 1rem 0;
          text-align: justify;
        }
      }
    }

    &.center-image {
      img {
        filter: none;
        opacity: 1;
        width: 135px;
        height: 135px;
        margin-top: -9px;

        @media (max-width: 600px) {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .btn-container-carousel {


    .control {
      margin: 0 16px;
      border: none;
      padding: 6px 15px;
      cursor: pointer;
      z-index: 1000;
      font-size: 16px;
      font-weight: 400;
      border-radius: 50%;

      outline: 0;
      min-width: 24px;
      min-height: 24px;
      opacity: 1;
     

      img {
        padding: 11px;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
         transition: all 0.3s ease;
        /* background-color: #000000; */

        &:hover {
          background-color: rgb(0, 0, 0, 0.7);
        }
      }
    }

    .prev {
      margin-left: 1rem;
    }

    .next {
      margin-right: 1rem;
    }
  }

}

.small-image {
  margin-top: 20px !important;
  height: 70px !important;
  width: 80px !important;

  @media (max-width: 600px) {
    margin-top: 5px !important;
    height: 70px !important;
    width: 80px !important;
  }
}

@media (max-width: 600px) {
  .testimonial-carousel{
    display: none!important;
  }
  .testimonial-carousel .img-container .heading {
    font-size: 22px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 3rem;
  }

  /* .testimonial-carousel .img-container img{
 display: none;
  } */
  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carouseImg {
    height: 120px;
    width: 120px;
    /* font-size: 22px; */
    line-height: 46px;
    /* text-align: center; */
    border-radius: 50%;
    margin: 0 auto;

  }

  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .skeletoncarouseImg {
    height: 120px;
    width: 120px;
    /* font-size: 22px; */
    line-height: 46px;
    /* text-align: center; */
    border-radius: 50%;
    margin: 0 auto;

  }

  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carousel-text h3 {
    color: #303030;
    line-height: 22px;
    /* font-family: 'firaSans-medium'; */
    font-size: 20px;
    margin-top: 2rem;
    /* color: #5A3733; */
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 1rem;
  }
  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carousel-text h4 {
    color: #868686;
    line-height: 22px;
    /* font-family: 'firaSans-medium'; */
    font-size: 18px;
    margin-top: 1rem;
    /* color: #5A3733; */
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 1rem;
  }
  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carousel-text .comments img {
    display: none;
  }

  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carousel-text .comments p {
    text-align: justify;
    width: 100%;
    height: 300px;
    /* overflow-y: scroll; */
  }

  .testimonial-carousel .carousel-container .carousel-track .carousel-slide .carousel-text .btn-container-carousel .control {
    margin: 0 16px;
    border: none;
    padding: 6px 8px;
    cursor: pointer;
    z-index: 1000;
    font-size: 16px;
    font-weight: 400;
    border-radius: 50%;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    outline: 0;
    min-width: 24px;
    min-height: 24px;
    opacity: 1;
  }
}

@media (max-width: 640px) {

  .diferenceImg {
    position: absolute !important;
    top: -9% !important;
    left: 8% !important;
    width: 22px !important;
  }
}

