/* Override the selected range background color */
/* .rdrSelection {
    color: red !important;
} */

/* Override the hover color for dates */
.rdrDayHovered .rdrDayNumber span {
    /* background-color: rgba(255, 0, 0, 0.5) !important; */
}
.rdrDateDisplayWrapper .rdrDateDisplay{
    color: var(--background-theme) !important;

}

/* Override the color of range borders */
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    color: var(--background-theme) !important;
}

.rdrDay, .rdrStartEdge, .rdrInRange, .rdrEndEdge {
 color: var(--background-theme) !important;
}
.rdrSelected, .rdrInRange {
    background-color: var(--background-theme) !important;
    border-radius: 0 !important;
}
.date-container{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

}

.date-container::before{
    content: "";
    position: absolute;
    left: 72%;
    top: -8%;
    transform: translateX(-29%);
    border: 18px solid;
    border-color: #0000 #0000 white;
    z-index: 50;
}

@media screen and (max-width:1024px) {
    .date-container::before{
        left: 12%;
    }
}

@media (max-width: 768px) {
    .date-container {
      left: 0;
      bottom: -488px;
    }
/*   
    .date-container ul {
      width: 100%; 
    }
    .date-container ul li{
    font-size: 10px;
      } */
  
    .date-container .rdrCalendarWrapper {
      width: 100%; /* Ensure the calendar takes full width */
    }
  }
  