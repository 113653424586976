.breadcrumb {
  margin-bottom: 16px;
  display: flex;
}
.breadcrumb a {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.32px;
  color: #000000;
  position: relative;
  padding-right: 20px;
}
.breadcrumb a::before {
  content: ">";
  position: absolute;
  font-size: 14px;
  line-height: 17px;
  right: 6px;
}
.breadcrumb a:last-child {
  padding-right: 0;
}
.breadcrumb a:last-child:before {
  content: none;
}
.breadcrumb a.active {
  cursor: default;
}

@media screen and (max-width: 767px) {
  .breadcrumb a {
    font-size: 12px;
  }
  .breadcrumb {
    margin-bottom: 16px;
  }
}
