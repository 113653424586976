.btn.btn-primary {
  position: unset;
}

hr.seperator {
  font-size: 10px;
  transform: scaleY(1.5);
  font-weight: bold;
  margin: 20px 0px;
  opacity: 0.7;
}

/* .banner-section {
  position: relative;
} */



.banner-section .banner-edit {
  user-select: none;


  padding: 0 0 20px;
}

.banner-section .input-icon {
  width: 50%;
  padding-right: 0px;
  height: 50px;
  margin: auto;
  position: absolute;
  bottom: 0px;
  /* Position at the bottom of the banner section */
  left: 50%;
  /* Align horizontally in the middle */
  transform: translateX(-50%);
  z-index: 10;
  /* Adjust horizontal positioning */
}

.banner-section .input-icon {
  border-radius: 40px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  backdrop-filter: blur(8px);
  opacity: 0.9;
}

.banner-section .input-icon input ::placeholder {
  font: bold !important;
}


.search-buttons {
  width: 50%;
  padding-right: 0px;
  height: 50px;
  margin: auto;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  /* Adjust as needed */
}

.no-data-home {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.no-data-home h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #23282E;
  opacity: 0.5;
  text-align: center;
}


/* for admin panel button group*/
.admin-dashboard-btn-group {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.admin-dashboard-btn-group .group-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 20px;
  border-radius: 5px;
  font-family: "firaSans-semibold";
  font-size: 18px;
  color: #e25569;
  border: 1px solid #e25569;
  /* background: white; */
  cursor: pointer;
}

.admin-dashboard-btn-group .group-item.disabled {
  cursor: not-allowed;
}

.admin-dashboard-btn-group .active {
  color: white;
  background-color: #8baeff;
}

.admin-homepage .add-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  flex-direction: unset;
}

.admin-homepage .add-save button.btn {
  height: 40px;
  width: 120px;
}

/* slogans list */
.banner-editor div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slogans-list {
  padding: 20px 0;
}

li.slogans-item {
  list-style-type: disclosure-closed;
  padding: 15px;
  list-style-position: inside;
  background: #fff;
  border-radius: 5px;
  margin: 10px 0;
  cursor: grab;
}

li.slogans-item:nth-child(odd)::marker {
  color: rgb(160, 160, 190);
}

li.slogans-item:nth-child(even)::marker {
  color: rgb(0, 200, 200);
}

.slogans-item span {
  line-height: 35px;
}

.slogans-item .btn img {
  margin: 0;
}

.slogans-item .btn span.desktop {
  display: block;
}

.slogans-item .btn svg.mobile,
.slogans-item .btn img.mobile {
  display: none;
}

.slogans-item .btn {
  float: right;
  height: 35px;
  width: 80px;
  margin-left: 10px;
  color: #fff;
  border-radius: 35px;
}

.slogans-item .btn:hover {
  box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}

.slogans-item .btn.btn-edit {
  background: rgb(160, 160, 190);
}

.slogans-item .btn.btn-delete {
  background: linear-gradient(85.42deg, rgb(0, 200, 215) 0%, rgb(0, 220, 190) 100%);
  /* background: rgb(0,200,200); */
}

.banner-editor div.add-slogan {
  display: flex;
  justify-content: center;
}

.banner-editor .btn.btn-add-slogan {
  background: linear-gradient(85.42deg, rgb(0, 200, 215) 0%, rgb(0, 220, 190) 100%);
  height: 40px;
  color: #fff;
  margin: auto;
}

.banner-editor .btn:hover {
  box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}

/**/

.banner-section .banner-edit .banner-item {
  /* background-color: aqua; */
  border-radius: 10px;
  position: relative;
}

.banner-editor h1 {
  font-weight: bold;
  padding: 10px 3px;
}

.banner-edit .banner-item .container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.banner-edit .banner-item.add .container {
  padding: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}

.banner-edit .banner-item.add .container img {
  position: relative;
  width: 100px;
  height: 120px;
  left: inherit;
  top: inherit;
}

.banner-edit .banner-item .container .btn-group {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: inherit;
  padding: 5px 8px;
}

.banner-edit .banner-item .container .btn-primary,
.show-all-modal .container .btn-group .btn-primary,
.add-game-in-slogan-modal .container .btn-group .btn-primary {
  padding: 5px 8px;
  font-weight: bolder;
}

.banner-edit .banner-item .container .btn-primary img {
  width: 100%;
  height: 100%;
  padding: 10% 5%;
}

.sortable-list .Add {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* position: absolute; */
  /* top: 0;
    left: 0; */
  opacity: 0.6;
  padding: 20% 32%;
  border-radius: 10px;
}

.admin-save {
  display: flex;
  justify-content: flex-end;
}

.banner-edit .banner-item .container .name {
  color: white;
  font-weight: bolder;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 15px;
  font-size: 150%;
  z-index: inherit;
}

.banner-edit .banner-item .container .name span {
  /* background: -webkit-linear-gradient(rgb(99, 0, 0), rgb(255, 255, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  color: #ffffff;
  font-size: 18px;
  font-family: "firaSans-semibold";
  text-transform: capitalize;
  font-weight: 600;
}

.banner-edit .banner-item .Add img {
  cursor: pointer;
  width: 30%;
  height: 40%;
  left: 35%;
  top: 30%;
  opacity: 0.5;
  border-radius: 10px;
}

/*Upload banner*/

.banner-upload {
  padding: 0 0 60px;
}

.banner-upload .banner-grp-title {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.banner-upload .banner-grp-title p {
  font-size: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.banner-upload .banner-grp-title p span.size {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  color: #f2545b;
}

.banner-upload .banner-grp-title .head1,
.banner-upload .banner-grp-title .head5 {
  visibility: hidden;
}

.banner-upload .banner-grp-title .head1 {
  width: 50%;
  margin: 0 1%;
}

.banner-upload .banner-grp-title .head2,
.banner-upload .banner-grp-title .head3 {
  width: 70%;
  margin: 0 1%;
}

.banner-upload .banner-grp-title .head4 {
  width: 96%;
  margin: 0 2%;
}

.banner-upload .banner-grp-title .head5 {
  width: 45px;
  height: 45px;
}

.banner-upload .btn-grp {
  display: flex;
  justify-content: center;
}

.banner-upload .preview {
  margin-top: -30px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #e25569;
}

.banner-upload .preview:hover {
  font-weight: 600;
}

.banner-upload .preview .dropdown-arrow {
  display: inline-flex;
  height: 10px;
  transition: all ease 500ms;
}

.banner-upload .preview .dropdown-arrow.rotate {
  transform: rotate(180deg);
}

/*Banner Create modal */
.banner-create-modal,
.banner-delete-modal,
.add-game-in-slogan-modal,
.confirm-slogan-sort-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* .banner-create-modal,
.banner-delete-modal,
.add-game-in-slogan-modal,
.confirm-slogan-title-modal {
  position: absolute;
  background-color:rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
} */
/* .banner-create-modal .container,
.confirm-slogan-title-modal .container {
  background-color: white;
  width: 600px;
  min-height: 200px;
  border-radius: 20px;
  padding: 10px 20px;

} */
.confirm-slogan-title-modal .btn-group {
  display: flex;
  justify-content: center;
}

.banner-create-modal .btn-group .banner-delete-modal .btn-group,
.add-game-in-slogan-modal .btn-group,
.confirm-slogan-sort-modal .btn-group,
.banner-item .btn-group,
.show-all-modal .btn-group {
  flex-direction: unset;
}

.banner-create-modal .container,
.add-game-in-slogan-modal .container {
  background-color: white;
  width: 600px;
  min-height: 300px;
  border-radius: 20px;
  padding: 10px 20px;
}

.banner-delete-modal .container,
.confirm-slogan-sort-modal .container {
  background-color: white;
  width: 600px;
  min-height: 100px;
  border-radius: 20px;
  padding: 15px 60px;
}

.show-all-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 8% 7% 5%;
  overflow-y: hidden;
  z-index: 10;
}

.show-all-modal .container {
  background-color: #eef0f1;
  width: 100%;
  min-height: 530px;
  border-radius: 20px;
  padding: 15px 20px;
  overflow-y: auto;
  z-index: 1;
}

.show-all-modal .show-all-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.show-all-modal .show-all-title .back-arrow {
  position: absolute;
  left: 0;
  margin-top: 0;
}

.back-arrow {
  cursor: pointer;
}

.show-all-modal .container .btn-group,
.add-game-in-slogan-modal .container .btn-group {
  display: flex !important;
  justify-content: flex-end;
}

.show-all-modal .container .title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: #e25569;
}

.show-all-modal .container .show-all-items {
  margin: 10px 0px;
}

.banner-delete-modal .container span,
.confirm-slogan-sort-modal .container span {
  font-size: 20px;
}

.banner-delete-modal .container .btn-group,
.confirm-slogan-sort-modal .container .btn-group {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.banner-create-modal .container .close,
.drag-item .close {
  display: flex;
  justify-content: flex-end;
}

.banner-create-modal .container .close span,
.drag-item .close span {
  font-size: 28px;
  font-weight: 900;
  cursor: pointer;
  background: -webkit-linear-gradient(rgb(255, 115, 0), rgb(255, 134, 35));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-create-modal .container .form,
.add-game-in-slogan-modal .container .form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-game-in-slogan-modal .container .form {
  padding: 10%;
}

.banner-create-modal .container .form .group,
.add-game-in-slogan-modal .container .form .group {
  display: flex;
  align-items: center;
  margin: 10px;
}

.banner-create-modal .container .form .form-control,
.add-game-in-slogan-modal .container .form .form-control {
  width: 100%;
  margin: 10px;
}

.banner-create-modal .container .form .select,
.add-game-in-slogan-modal .container .form .select {
  margin-bottom: 28px;
  min-width: 300px;
  position: relative;
}

.slick-list>.slick-track {
  margin-left: initial;
  display: flex;
}

.slick-list {
  overflow: initial !important;
}


.error-message {
  color: #e75000;
  font-family: "firaSans-regular";
  font-style: inherit;
  font-weight: 100;
  font-size: 10px;
}

.info-message {
  color: #547ef2;
  font-family: "firaSans-regular";
  font-style: inherit;
  font-weight: 100;
  font-size: 15px;
}

.drag-message {
  color: #f2545b;
  font-family: "firaSans-regular";
  font-style: inherit;
  font-weight: 100;
  font-size: 13px;
}

/* upload csv section */
.upload-csv-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #FFEDEF;
}

.upload-csv-title {
  display: flex;
  align-items: center;
}

.upload-csv-section h5 {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #23282E;
  margin-left: 12px;
  margin-right: 4px;
}

.upload-csv-butn {
  display: flex;
  align-items: center;
}

.upload-csv-butn img {
  margin-right: 24px;
}

.upload-csv-section button {
  width: 79px;
  height: 22px;
  background: #E25569;
  border-radius: 4px;
  border: 0px solid;
  outline: 0;
  box-shadow: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'firaSans-medium';
}

/* game liist padding adjustment */
.gameListHover:hover {
  margin: 0 10px !important;
  z-index: 1;
}

.gameListHover-webinar:hover {
  /* margin: 0 10px !important; */
  z-index: 1;
  scale: 1.1;
}


/*  SIDE CARD VIEW CSS */

:root {
  --switches-bg-color: white;
  --switches-label-color: #7994b2;
  --switch-bg-color: #1b9b9b;
  --switch-text-color: white;
}

.card {
  width: 400px;
  height: 400px;
  gap: 10px !important;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  background: #e1edfc;
  left: 69.6%;
  top: 40%;
  position: fixed;
  background: "#E1EDFC";
  z-index: 10;
  border-radius: 15px;
}

.sideView-container {
  margin-left: 22px;
  margin-top: 20px;
}

/* .pollTypeLabel{
  margin-top: 20px; 

  border: 2px solid transparent; 
  padding: 4px; 
  background-color: white; 
  border-radius: 8px; 

} */
/* resize font-size on html and body level. html is required for widths based on rem */

/* a container - decorative, not required */
.container {
  padding: 10px;
}

/* p - decorative, not required */

/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switches-container {
  width: 13rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  line-height: 2.5rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9rem;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
/* switch box highlighter */
.poll-switch {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
  width: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.poll-switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9rem;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .poll-switch div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .poll-switch div:nth-of-type(2) {
  opacity: 1;
}

.sideviewCard {
  box-shadow: 0px 2px 2px #dce3f2cf;
  height: 290px;
  width: 80px;
  right: 14px;
  position: fixed;
  background: #e1edfc;
  z-index: 10;
  border-radius: 15px;
  opacity: 1;
}

.sideInnerviewCard {
  width: 60px;
  height: 60px;
  background-color: #adcece;
  padding: 13px;
  margin: 10px;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
}

/* new */

.inputTextField {
  width: 324px;
  height: 56px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: #5f638a;
  text-overflow: ellipsis;
  background-color: #f6faff;
  text-align: center;
}

.inputTextField70 {
  width: 70%;
  height: 56px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: #5f638a;
  text-overflow: ellipsis;
  background-color: #f6faff;
  text-align: center;
}

.input:invalid {
  animation: justshake 0.3s forwards;
  color: red;
}


.polladdquestion {
  justify-content: center;
  display: flex;
  width: 100%;
}

.polladdquestion-choice {
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-evenly;
}

.poll-choice-container {
  margin-top: 30px;
  overflow: auto;
  height: 141px;
}

.poll-padding10 {
  padding: 10px;
}

.poll-answer-container {
  margin-top: 30px;
  overflow: auto;
  height: 200px;
}

.input-choice-text-field {
  width: 250px;
  height: 39px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  text-overflow: ellipsis;
  text-align: center;
  background-color: #f6faff;
  color: #5f638a;
}

.input-choice-bintext-field {
  width: 39px;
  height: 39px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
}

.poll-trashbin {
  width: 39px;
  height: 39px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  background-color: #f6faff;
}

.poll-trashbin-icon {
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
}

.add-choice {
  display: flex;
  justify-content: center;
  padding: 4%;
  margin-top: 2%;
}

.add-choice-icon {
  height: 40px;
  cursor: pointer;

}

.arrowcontainer {
  cursor: pointer;
  background: #bec1c5;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.arrowcontainer-onchange {
  cursor: pointer;
  background: #1b9b9b;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.poll-action-container {
  display: flex;
  justify-content: space-between;
}

.poll-scroll-div {
  padding: 10px;
  width: 80%;
  display: flex;
  align-items: center;
}

.poll-scroll-innerdiv {
  width: 100%;
  border-radius: 50px;
  height: 10px;
  background-color: #a7bdef;
  display: inline-flex;
}

.poll-button-style {
  background-color: rgb(95, 99, 138);
  border-radius: 20px;
  width: 100px;
  height: 40px;
  color: white;
}

.poll-button-style:hover {
  background-color: rgb(61, 64, 91);
}

.createPollTitle,
.pollTitleLabel,
.selectPollType {
  /* color: var(--color-theme); */
  color: rgb(95, 99, 138);
}

.createpoll-button-style {
  background-color: #1B9B9B;
  border-radius: 20px;
  width: 150px;
  height: 40px;
  color: white
}

.createpoll-button-style:hover {
  background-color: #074d4d;
}

.poll-count {
  color: #5F638A;
  float: right;
  padding: 10px;
  font-size: 27px;
}

.poll-list-border {
  width: 324px;
  height: 56px;
  background: white;
  border: 0.2px solid grey;
  border-radius: 4px;
  display: flex;
}

.poll-list-name {
  margin-left: 3%;
  color: rgb(95, 99, 138);

}

.poll-list-count {
  padding: 2px;
  border-bottom: 1px solid grey;
  margin: 5px;
  color: rgb(95, 99, 138);
}

.poll-list-w50 {
  width: 50% !important;
}

.poll-button-container {
  display: flex;
  justify-content: center;
}

.pollvisibility-button-container {
  background-color: rgb(240, 244, 255);
  ;
  display: flex;
  justify-content: center;
}

.padding-2 {
  padding: 2px;
}

.padding15 {
  padding: 15px;
}

.padding10 {
  padding: 10px;
}

.poll-scrollinnerdiv-width {
  background-color: white;
  width: 21%;
  height: 12px;
  border-radius: 10px;
}

.poll-arrowsize {
  height: 20px;
  width: 20px;
}


.poll-result-announcementdiv {

  width: 400px;
  height: 129px;
  border: none;
  border-radius: 20px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: black;
  background-color: #f6faff;
  text-align: center;
  padding: 30px;
}

.poll-result-div {
  align-items: center;
  display: flex;
  width: 100%;
}

.poll-result-container {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.poll-result-button-style {
  background-color: #8BAEFF;
  border-radius: 20px;
  width: 100px;
  height: 40px;
  color: white;
}

.poll-addmore-question-container {
  height: 30%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.poll-addmore-question-div {
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 10px;
}

.collapse-button-style {
  width: 324px;
  height: 56px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: #5f638a;
  text-overflow: ellipsis;
  background-color: #f6faff;
  text-align: center;
  display: flex;
}


/* COLLAPSE STYLE */

.poll-addmorequestion-collapse-container {
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #f6faff;
  width: 94%;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
}


/* .tab__label,
.tab__close {
  display: flex;  
  cursor: pointer;
} */

.tab__label {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
}



.poll-addquestion-collapse-outerdiv {
  color: #5F638A;
  margin-left: 7%;
  margin-right: 7%;
}

/* .poll-addquestion-collapse-outerdiv:hover{
  background-color: #FFE74C;
} */

.tab input {
  position: absolute;
  opacity: 0;
  /* z-index: -1; */
}

.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s;
  background-color: white;
}

.tab input:checked~.tab__content {
  max-height: 30rem;
  padding: 7px;
}

.image-30 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.image-11 {
  height: 11px;
  width: 11px;
}

/* Visual styles */


/* ARROW STYLE */
.tab__label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}

.tab input:checked+.tab__label::after {
  transform: rotate(270deg);
}

/* .tab__content p {
  margin: 0;
  padding: 1rem;
} */
.tab__close {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

/* Arrow animation */
.tab input:not(:checked)+.tab__label:hover::after {
  animation: bounce .5s infinite;
}

@keyframes bounce {
  25% {
    transform: rotate(90deg) translate(.25rem);
  }

  75% {
    transform: rotate(90deg) translate(-.25rem);
  }
}

/* ______________________________________________________ Oollvisibility Inner Container Div */
.tab__label1 {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid grey;

}

.tab__label1:hover {
  background-color: #FFE74C;
}

.poll-invite-button-style {
  background-color: rgb(95, 99, 138);
  border-radius: 3px;
  width: 95px;
  height: 22px;
  color: white;
  font-size: 10px;
}



.poll-invite-textarea {
  width: 100%;
  resize: none;
  height: 91px;
  max-height: 91px;
  border: 1px solid rgb(224, 226, 242);
  padding: 7px;

}

.poll-visibility-container-innerdiv {
  height: 23px;
  background-color: rgb(240, 244, 255);
  display: flex;
  align-items: center;
  justify-content: end;
}




/* Graph Container ____________ */


.poll-graph-card {
  gap: 10px !important;
  /* transition: all 0.3s ease-in-out !important; */
  background: #E1EDFC;
  right: 100px;
  overflow: scroll;
  /* top: 40%; */
}

.poll-graph-container {
  height: 60px;
  color: #5F638A;
}

.cancas-setup {
  display: block;
  box-sizing: border-box;
  height: 264px;
  width: 356px;
}

.canvas-bottom-div {
  height: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background: #DFB2F4;
}

::-webkit-scrollbar {
  width: 5px;
  /* Adjust this value to make the scrollbar thinner or wider */
}

/* .collapsible:hover {
  background-color: #555;

} */

/* @keyframes justshake {
  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX-(5px);
  }
} */


@media(max-width: 320px) {
  .poll-graph-card {
    gap: 10px !important;
    width: 220px;
    /* transition: all 0.3s ease-in-out !important; */
    /* top: 20%; */
  }
}

@media(min-width: 1024px) {
  .gameListHover:hover .gameCardDetails {
    display: flex !important;
    align-items: end;
    background-image: linear-gradient(0deg, black, transparent);
    height: 100px;
  }

  .gameListHover-webinar:hover .gameCardDetails {
    display: flex !important;
    align-items: end;
    height: 100px;
  }
}

@media (min-width: 992px) {
  .gameList {
    padding: 0 15px !important;
  }
}

/* game liist padding adjustment */
/* Slogan Card css */
@media screen and (max-width: 991px) {
  .search-buttons {
    width: auto;
  }

  .banner-edit .banner-item {
    height: 160px;
  }

  .admin-dashboard-btn-group {
    margin-bottom: -20px;
  }

  .admin-dashboard-btn-group .group-item {
    padding: 13px;
    font-size: 17px;
  }

  .show-all-modal {
    padding: 65px 0 0;
  }

  .show-all-modal .container {
    border-radius: 0;
    background: #fff;
    padding: 0 20px;
  }

  .show-all-modal .show-all-title {
    padding: 20px 0 5px;
  }
}

@media screen and (max-width: 767px) {

  /* .confirm-slogan-title-modal .btn-group{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  #sloganTitle-save-btn{
    width: 50%;
  }
  #sloganTitle-cancle-btn{
    width: 50%;
  } */
  .upload-csv-section {
    flex-direction: column;
    height: 76px;
  }

  .upload-csv-section h5 {
    font-size: 13px;
    line-height: 18px;
  }

  .upload-csv-butn img {
    margin-right: 12px;
  }

  .upload-csv-butn {
    margin-top: 8px;
  }

  .admin-dashboard-btn-group .group-item {
    padding: 10px;
    font-size: 16px;
  }

  .admin-homepage .add-save {
    justify-content: space-between;
  }

  .admin-homepage .add-save button.btn {
    width: 100%;
  }

  .banner-edit .banner-item.add .container img {
    height: 100px;
    width: 80px;
  }

  .no-data-home h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 680px) {
  .admin-dashboard-btn-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 50% 50%;
  }

  .admin-dashboard-btn-group .group-item {
    width: 100%;
  }

  .drag-item .close span {
    font-size: 25px;
  }

  .show-all-modal .container {
    padding: 0 35px;
  }

  .show-all-modal .show-all-title {
    margin: 0 -15px;
  }

  .banner-edit .banner-item.add .container img {
    height: 70px;
    width: 60px;
  }

  .slogans-item .btn {
    width: 40px;
    border-radius: 5px;
  }

  .slogans-item .btn svg {
    margin: 0;
  }

  .slogans-item .btn span.desktop {
    display: none;
  }

  .slogans-item .btn svg.mobile,
  .slogans-item .btn img.mobile {
    display: flex;
  }
}

@media screen and (max-width:500px) {
  .no-data-home {
    height: 50px;
  }

  .no-data-home h4 {
    font-size: 14px;
  }

  .banner-grp-title .head4 {
    display: none !important;
  }

  .banner-upload .preview {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width:400px) {
  .drag-item .close span {
    font-size: 20px;
  }

  .banner-editor div.head {
    display: block;
  }
}

/* update the max-width for container */
@media (min-width: 640px) {
  #dashboard-element .container {
    max-width: 750px;
  }
}

@media (min-width: 768px) {
  #dashboard-element .container {
    max-width: 1015px;
  }
}

@media (min-width: 1024px) {
  #dashboard-element .container {
    max-width: 1300px;
  }
}

@media (min-width: 1280px) {
  #dashboard-element .container {
    max-width: 1520px;
  }
}

@media (min-width: 1536px) {
  #dashboard-element .container {
    max-width: 1900px;
  }
}

/* PollGraph-SideviewCard */

@media screen and (max-width:992px) and (min-width: 360px) {
  .sideInnerviewCard {
    width: 60px;
  }
}

@media screen and (max-width:992px) and (min-width: 640px) {
  .sideviewCard {
    z-index: 11;
  }
}


@media screen and (max-width:600px) {
  .sideviewCard {
    right: 5px;
  }

  .poll-graph-card {
    width: 310px;
    right: 90px;
  }
}

@media screen and (max-width:424px) {
  .poll-graph-card {
    right: 90px;
  }

  .poll-graph-card {
    width: 270px;
  }
}

@media screen and (max-width:374px) {
  .poll-graph-card {
    width: 220px;
  }
}

@media screen and (max-width:360px) {
  .sideviewCard {
    width: 70px;
  }

  .poll-graph-card {
    right: 80px;
  }

  .sideInnerviewCard {
    width: 50px;
  }
}

.pointSystembg {
  background: linear-gradient(199.55deg, #6A5AE0 -22.28%, #A89DFF 104.7%);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.multi-button {
  display: inline-flex;
  padding: 2px 2px;
  border-radius: 6px;
  background: #11115726;
  /* border: 2px solid #ddd; */
  gap: 5px;
}

/* Styles for the cut button */
.cut {
  margin: 5px;
  height: 33px;
  width: 100px;

  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  /* border: 2px dotted black; */
  border-radius: 6px;
  cursor: pointer;
}

.cut:hover {
  color: #aecfc8;
  background-color: black;
  border: 2px dotted #aecfc8;
}

/* Styles for the copy button */
.copy {
  margin: 5px;
  height: 33px;
  width: 100px;
  font-size: 14px;
  line-height: 21px;
  border-radius: 25px;
  cursor: pointer;
}

.copy:hover {
  color: #aec6cf;
  background-color: black;
  border: 2px dashed #aec6cf;
}

/* Styles for the paste button */
.paste {
  margin: 5px;
  height: 60px;
  width: 180px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  background-color: #aeb6cf;
  border-radius: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.paste:hover {
  color: #aeb6cf;
  background-color: black;
  border: 2px solid #aeb6cf;
}

.border-btm {
  border-bottom: 1.55px solid #d1d5db;
}

.number-bg {
  border: 1.6px solid #d1d5db;
  font-size: 15px;
}

.titleContainerbg {
  background: linear-gradient(100.75deg, #C6FBFB 2.07%, #CCFFFF 100%);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}
.titleContainerbgMob {
  background: linear-gradient(100.75deg, #C6FBFB 2.07%, #CCFFFF 100%);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  padding:0.5rem;
}
.streakContainerBg {
  background: linear-gradient(139.18deg, #E6F4FC 20.5%, #7FCFFF 79.97%, #E6F4FC 105.73%);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.streakTitle {
  color: #23282E;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.streakPara {
  color: #23282E;
  font-size: 16px;
  opacity: 0.6;
  /* font-weight: 600; */

}

.streakBorder {
  border: 2px solid #65A4EE;
  border-radius: 20px;
}

.streakdiv {
  background-color: #65A4EE;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}

.streakBorderColor {
  border: 1px solid #65A4EE;
}

.streakYellowBg {
  box-shadow: 0px 3px 6px #00000029;
  background: linear-gradient(140.48deg, #FCF6EB 18%, #FFD954 100%);

  border-radius: 8px;
  color: #000;
}
.whitebgwithborder{
  box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;
opacity: 1;
}
.streakTaskTitle {
  color: #23282E;

}

.streakProgress {
  background: linear-gradient(90deg, #F6CA45 0%, #F6CA45 68%, #F6CA45 100%);

}

.f-16 {
  font-size: 16px;
}

.streakRightSection {
  background: linear-gradient(130.31deg, #F0E2F0 0%, #A0DAEB 100%);
  border-radius: 20px;
}


.badgeCardTitle {
  letter-spacing: 0px;
  color: #23282E;
  font-weight: 600;
  font-size: 18px;
}

.badgeLightCardPara {
  text-align: left;
  font: normal normal normal 18px / 22px Fira Sans;
  letter-spacing: 0px;
  color: #23282E;
  opacity: 0.5;
  margin-top: 3px;
  font-size: 16px;
}

.badgeNormalCardPara {
  font-weight: 600;
  text-align: left;
  font: normal normal medium 18px/22px Fira Sans;
  letter-spacing: 0px;
  color: #23282E;
  opacity: 1;
  margin-top: 3px;
  font-size: 16px;
}


.progress-bar {
  width: 100%;


  border-radius: 8px;
  opacity: 1;
  -webkit-appearance: none;
  /* Remove default appearance */
  appearance: none;
}

/* Style for Webkit browsers (Chrome, Safari) */
.progress-bar::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 8px;

}

.progress-bar::-webkit-progress-value {
  background: linear-gradient(90deg, #FCE38A 0%, #F38181 100%);
  border-radius: 8px;
}

/* Style for Firefox */
.progress-bar::-moz-progress-bar {
  background: linear-gradient(90deg, #FCE38A 0%, #F38181 100%);
  border-radius: 8px;
}

.car-image {
  width: 140px;
  /* Adjust the size of the car image */
  height: auto;
  transform: translateY(-50%);
  /* Vertically center the image with the progress bar */
}

.badgedetailsCardShadow {

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 34px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.custom-font-size-16 {
  font-size: 16px !important;
}

.border-16 {
  border-width: 1.6px;
  color: #23282E !important;
}

.border-right {
  border-right: 1.6px solid #d1d5db !important;
}

.border-right-black {
  border-right: 1.6px solid #23282E !important;
}

.playcardSubitem{
  font-size: 9px;
    /* width: 56px; */
    height: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 15px 6px;
    box-shadow: 0px 4px 34px #00000029;
    font-weight: 500;
}

.playNowBtn{
  width: 132px;
height: 39px;
/* UI Properties */
background: #D83307 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 34px #00000029;
border-radius: 6px;
opacity: 1;
}

.highlight {
  color: var(--background-theme);
  background: none;
}

.teamDetailsBorder{
  border: 1px solid #707070;
}
.captainRow{
  background: transparent linear-gradient(270deg, #42E695 0%, #3BB2B8 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 34px #00000029;
color: #fff!important;
border: none!important;
}
.teamListScroll {
  height: 280px;
  overflow-y: auto;
  scrollbar-width: auto; /* For Firefox: ensures scrollbar is visible */
  scrollbar-color: auto; /* Optional: default scrollbar color */
}

/* For webkit browsers (Chrome, Edge, Safari) */
.teamListScroll::-webkit-scrollbar {
  width: 4px; /* Adjust scrollbar width */
}

.teamListScroll::-webkit-scrollbar-thumb {
  background-color: #9b9999; /* Adjust scrollbar thumb color */
  border-radius: 15px; /* Optional: rounded scrollbar thumb */
}

.teamListScroll::-webkit-scrollbar-thumb:hover {
  background-color: #605d5d; /* Darker on hover */
}

.teamListScroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: scrollbar track color */
}

.taskScroll {
  height: 250px;
  overflow-y: auto;
  scrollbar-width: auto; /* For Firefox: ensures scrollbar is visible */
  scrollbar-color: auto; /* Optional: default scrollbar color */
  padding: 0 1rem;
}

/* For webkit browsers (Chrome, Edge, Safari) */
.taskScroll::-webkit-scrollbar {
  width: 4px; /* Adjust scrollbar width */
}

.taskScroll::-webkit-scrollbar-thumb {
  background-color: #9b9999; /* Adjust scrollbar thumb color */
  border-radius: 15px; /* Optional: rounded scrollbar thumb */
}

.taskScrolltaskScroll::-webkit-scrollbar-thumb:hover {
  background-color: #605d5d; /* Darker on hover */
}

.taskScroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: scrollbar track color */
}

.teamDetailsListScroll {
  height: 294px;
  overflow-y: auto;
  scrollbar-width: auto; /* For Firefox: ensures scrollbar is visible */
  scrollbar-color: auto; /* Optional: default scrollbar color */
}

/* For webkit browsers (Chrome, Edge, Safari) */
.teamDetailsListScroll::-webkit-scrollbar {
  width: 4px; /* Adjust scrollbar width */
}

.teamDetailsListScroll::-webkit-scrollbar-thumb {
  background-color: #9b9999; /* Adjust scrollbar thumb color */
  border-radius: 15px; /* Optional: rounded scrollbar thumb */
}

.teamDetailsListScroll::-webkit-scrollbar-thumb:hover {
  background-color: #605d5d; /* Darker on hover */
}

.teamDetailsListScroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: scrollbar track color */
}

.teamdetailsmobileTitle{
  font-size: 16px;
    color: #23282E;
    font-weight: 600;
}

.badge-detail-shdw-yellow {
  position: absolute;
  top: 0;
  left: 65%;
  width: 34%;
  border-radius: 50%;
  box-shadow: -64px 14px 86px 61px #fff9c4a1;
}

.badge-detail-shdw-blue{
  position: absolute;
  top: 0;
  left: 5%;
  width: 34%;
  border-radius: 50%;
  box-shadow: -9px 21px 124px 38px #C5CAE9;
}

.badge-detail-shdw-pink1{
  position: absolute;
  bottom: 14%;
  left: 2%;
  width: 15%;
  border-radius: 50%;
  box-shadow: -2px 37px 78px 29px #C5CAE9;
}
/* .badge-detail-shdw-pink{
  position: absolute;
  top: 40%;
  left: 5%;
  width: 34%;
  border-radius: 50%;
  box-shadow: -9px 21px 124px 38px #1e32b5;
} */

.gradient4 {
  background-image: linear-gradient(280deg, hsl(125deg 0% 100% / 96%) 0%, hsl(113deg 32% 96% / 1%) 55%, hsl(33deg 32% 93% / 0%) 76%, hsl(241deg 32% 89% / 61%) 88%, hsl(240deg 31% 86% / 49%) 96%, hsl(240deg 31% 82% / 0%) 100%);
}