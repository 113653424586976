.analytics-card {
    background: #F8F9FD;
    display: block;
    width: fit-content;
    min-width: 250px;
    padding: 12px 25px 10px 20px;
    border-radius: 10px;
    margin: 10px 0px 0px 10px;
    box-shadow: 4px 4px 4px 4px rgb(0 0 0 / 0.05);
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.analytics-card .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #23282e;
    text-transform: capitalize;
}

@media screen and (max-width: 770px) {
    .analytics-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 25px;
        margin: 10px 0px 0px 0px;
    }
}