.locked-screen{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.locked-screen .title{
    height: 20%;
    width: 100%;
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgb(255, 175, 32); */
    background: var(--background-theme);
}

.locked-screen .title h1{
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-family: 'firaSans-semibold';
}
.locked-screen .message-container{
    width: 60%;
    height: 30%;
    padding: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
}

.locked-screen .message-container h4{
    font-family: 'firaSans-regular';
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
}

@media screen and (max-width:991px) {
    .locked-screen .message-container{
        width: 80%;
        font-size: 20px;
        border-radius: 10px;
    }
    .locked-screen .message-container h4{
        font-size: 20px;
    }
}