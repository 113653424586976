.faq-banner {
    width: 100%;
    height: 520px;
    background: #fff7ef;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-banner h5 {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #23282e;
    margin-bottom: 16px;
    font-family: "firaSans-medium";
}

.faq-banner p {
    font-size: 18px;
    line-height: 22px;
    color: #23282e;
}

.faq-banner-right {
    width: 360px;
    height: 360px;
    margin-left: 136px;
    margin-top: 50px;
}

.faq-banner-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.faq-banner-left .input-icon {
    margin: 0;
    margin-top: 32px;
    width: 100%;
    height: 41px;
    border: 0;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

/* choose help */
.choose-container {
    padding-top: 48px !important;
}

.choose-help {
    display: flex;
    justify-content: space-between;
}

.choose-container h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #23282e;
    margin-bottom: 32px;
    font-family: "firaSans-medium";
}

.choose-help-card {
    width: 304px;
    height: 134px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.choose-help-card.active {
    border: 2px solid var(--color-theme);
}

.choose-help-card h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #23282e;
    margin-top: 16px;
    font-family: "firaSans-medium";
}

.choose-help-card.active h6 {
    color: var(--color-theme);
}

/* accordion card */
.choose-accordion {
    margin-top: 48px;
}

.choose-accordion-card {
    width: 100%;
    min-height: 51px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 16px;
}

.choose-accordion-card .accor-header {
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose-accordion-card .accor-header h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    font-family: "firaSans-medium";
    width: calc(100% - 40px);
}

.choose-accordion-card .accor-body {
    padding: 8px 16px;
    background: #fff9f3;
    border-radius: 6px;
    margin: 6px 16px 16px;
}

.choose-accordion-card .accor-body p {
    font-size: 16px;
    line-height: 24px;
    color: #23282e;
    opacity: 0.6;
}

.choose-accordion-card .accor-body p a {
    display: unset;
    text-decoration: underline;
    font-weight: bold;
}

.choose-accordion-card .collapse {
    display: none;
}

.choose-accordion-card .collapse.show {
    display: block;
}

.choose-accordion-card .accor-header span.span {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(#000, #000), linear-gradient(#000, #000);
    background-position: center;
    background-size: 40% 2px, 2px 40%;
    background-repeat: no-repeat;
}

.choose-accordion-card .accor-header[aria-expanded="true"] span.span {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(red, red), linear-gradient(red, red);
    background-position: center;
    background-size: 40% 2px, 0px 40%;
    background-repeat: no-repeat;
}

/* can't find */
.cant-find {
    margin-top: 48px;
}

.cant-find h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #23282e;
    font-family: "firaSans-medium";
    margin-bottom: 24px;
}

.cant-find p {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    margin-bottom: 16px;
}

.cant-find span {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
}

.cant-find span a {
    color: var(--color-theme);
    margin-left: 5px;
    display: inline;
}

@media screen and (max-width: 991px) {
    .faq-banner {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 16px;
    }

    .faq-banner h5 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 8px;
    }

    .faq-banner p {
        font-size: 16px;
        line-height: 19px;
    }

    .faq-banner-right {
        width: 240px;
        height: 240px;
        margin: auto;
        margin-top: 70px;
    }

    .faq-banner-left .input-icon {
        margin-top: 24px;
    }

    .choose-container h5 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .choose-container {
        padding-top: 40px !important;
    }

    .cant-find {
        margin-top: 32px;
    }

    .cant-find h6 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    .choose-help {
        overflow: auto;
    }

    .choose-help-card {
        flex: none;
        width: 264px;
        height: 134px;
        margin-right: 16px;
    }
}

.highlight {
    color: var(--color-theme);
    background-color: white;
}

.font-40 {
    font-size: 40px;
}

.section8 {
    height: 597px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    /* Added */
    justify-content: center;
    /* Added */
    align-items: center;
    /* Added */

    .games-view-plan-section {
        text-align: center;
        padding: 6rem;
        color: #fff;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            line-height: 52px;
            color: white;
            margin: 1rem;
            font-size: 39px;
            font-weight: 600;
            text-align: center;
            margin: 0 auto;
        }

        p {
            letter-spacing: 0.32px;
            text-align: center;
            font-size: 25px;
            color: #D0D2FF;
            margin: 1rem 0 2rem 0;
            line-height: 25px;
            font-weight: 400;

            .discount {
                color: var(--color-theme);
            }
        }

        .inputs-container {
            width: 746px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            gap: 15px;

            .input-group {
                width: 100%;
            }

            input {
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                background: #f6f7f9;
                border: none;
                height: 50px;
                color: #000;
                font-size: 16px;
                border-radius: 6px;
                line-height: 24px;
                box-shadow: 0px 4px 54px 0px #C3C9FF59;
            }
        }

        .user-btn {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-top: 1.5rem;

            .btn {
                position: relative;
                z-index: 1;
                box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
                width: 160px;
                height: 50px;
                padding: 14px 15px;
                height: 55px;

                &.red {
                    background-color: var(--color-theme);
                    color: #fff;
                    font-size: 18px;
                    line-height: 21px;

                    &:hover {
                        background-color: #c62828;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .logoSectionBookEvent .react-multi-carousel-list ul {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .f-18 {
        font-size: 16px !important;
        padding-right: 0px !important;
    }

    .section8 .games-view-plan-section {
        padding: 2rem;
    }

    .section8 .games-view-plan-section h2 {
        font-size: 22px;
    }

    .section8 .games-view-plan-section p {
        margin: 0 1rem;
    }

    .section8 .games-view-plan-section .inputs-container {
        flex-direction: column;
        width: 100%;
        margin-top: 2rem;
    }

    .section8 .games-view-plan-section input {
        margin: 8px 0;
    }

    .section8 .games-view-plan-section .user-btn {
        flex-direction: column;
        margin-top: 2rem;
        display: block;
    }
}

.section10 {
    height: unset;

    @media (max-width: 600px) {
        height: auto;
        flex-direction: column;
        padding: 1rem;
    }

    padding: 3rem;
    /* Default padding for larger screens */

    @media (max-width: 900px) {
        padding: 2rem;
        /* Padding for screens 900px or smaller */
    }

    @media (max-width: 600px) {
        padding: 1.5rem;
        /* Padding for screens 600px or smaller */
    }

    @media (max-width: 400px) {
        padding: 1rem;
        /* Padding for very small screens */
    }

    .games-accordion-bookevent {
        display: flex;
        justify-content: space-between;
        padding: 0rem 3rem 1rem;

        @media (max-width: 600px) {
            display: block;
            padding: 0;
            /* Reset internal padding if needed */
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }

        .title-wrapper {
            width: 50%;

            @media (max-width: 900px) {
                width: 100%;

            }

            .section-wrapper {
                font-size: 2.5rem;
                color: #333;
                margin: 0 0 3rem 0;
                font-weight: 800;

                .title-wrapper1 h2 {
                    font-weight: 600;
                    font-size: 34px;
                    color: #303030;
                    margin: 0 0 1rem 0;
                    line-height: 45px;
                }

                .title-wrapper1 h2 .text-highlight {
                    color: var(--color-theme);
                    font-weight: bold;
                }

                .title-wrapper1 .description {
                    font-weight: 400;
                    margin-bottom: 1rem;
                    font-size: 16px;
                    line-height: 25px;
                    color: #868686;
                }

                @media (max-width: 600px) {
                    .title-wrapper1 h2 {
                        font-size: 22px;
                        text-align: center;
                    }

                    .title-wrapper1 .description {

                        margin-bottom: 2rem;

                    }
                }


                .explore-more {
                    text-align: left;

                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                    }

                    .black {
                        display: inline-flex;
                        font-size: 16px;
                        letter-spacing: 0px;
                        /* font-weight: 600; */
                        line-height: 16px;
                        text-decoration: none !important;
                        /* text-transform: uppercase; */
                        background-color: #242830;
                        color: #fff !important;
                        border: none;
                        /* border-radius: 2px; */
                        cursor: pointer;
                        justify-content: center;
                        padding: 16px 32px;
                        height: 48px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
        }

        .image-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .button-group {
                width: 100%;
                margin-bottom: 20px;
                padding: 0 0 0 2rem;
                /* Default padding */


                @media (max-width: 600px) {
                    padding: 0;
                }

                .button-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: space-between;
                    margin: 0 0 2rem 0;

                    .button {
                        padding: 10px 20px;
                        font-size: 16px;
                        border: none;
                        border-radius: 8px;
                        cursor: pointer;
                        transition: background-color 0.3s, box-shadow 0.3s;

                        &.btn {
                            &.red {
                                background-color: var(--color-theme);
                                color: #fff;

                                &:hover {
                                    background-color: #e64a19;
                                }
                            }

                            &.white {
                                background-color: #fff;
                                color: #333;
                                box-shadow: 0 5px 19px var(--color-theme)52;

                                &:hover {
                                    background-color: #f4f4f4;
                                }
                            }
                        }

                        &.shdw {
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }


    }
}

.carouselTravserseButton {
    width: 133px !important;
    height: 50px !important;
    font-size: 21px;
}

.font-21 {
    font-size: 21px;

}

.filterBtn {
    width: 143px;
    height: 47px;
    /* UI Properties */
    background: #FDF1F3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
}

.section1 .image-container .react-multi-carousel-list {
    width: 100%;
    margin: 0 auto;
}

/* .categoriesSection .react-multi-carousel-list  {
 display: flex;
 justify-content: center!important;
} */

.logoSectionBookEvent .react-multi-carousel-list ul {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.gradient_1 {
    background-image: linear-gradient(79deg, rgba(225, 245, 254, 0) 0%, rgba(235, 238, 240, 0.3) 50%, rgba(255, 253, 231, 0.2) 100%);

}

.gradient_2 {
    background-image: linear-gradient(to bottom right, rgb(255 227 227 / 30%), rgba(224, 244, 255, 0.3));

}




.borderright {
    border-right-width: 3px;
    padding: 2px;
}

.font-bold {

    font-weight: 600 !important;
}

.filter-active {
    text-decoration: underline;
    font-weight: 600 !important;
}

.f-18 {
    font-size: 18px;
}

.textDark {
    color: var(--color-theme);
}

.prevbtn {
    border: 2px solid var(--color-theme) !important;
    background: white !important;
    color: var(--color-theme) !important;
    font-weight: 600 !important;
}

.nextbtn {
    background: var(--color-theme) !important;
    border: 2px solid var(--color-theme) !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.description1 {
    font-size: 15px;
    line-height: 1.7;
    color: var(--text-color);
    opacity: 0.75;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description1.expanded {
    max-height: none;
    /* Remove the height restriction when expanded */
}

.category-item {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* Adjust the value as needed */
    overflow: hidden;
    /* Ensures content fits within the border radius */
}

.shadowBookEventCard {
    border-radius: 6px;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px!important;
}


.viewDetailsBtn {
 
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
   
    z-index: 9;
    border: 0;
}

.viewDetailsBtn:hover {
    background-image: linear-gradient(var(--color-theme), #d85b00); /* Change the gradient colors on hover */
}

.bookEventCardImg {
    transition: transform 0.3s ease;
    /* Smooth transition */
    height: 200px!important;
}

.bookEventCardImg:hover {
    transform: scale(1.05);
    /* Scale the image slightly */
}

.titleHover {
    transition: text-decoration 0.3s ease; /* Smooth transition */
}

.titleHover:hover {
    text-decoration: underline; /* Underline on hover */
}

.descriptionColor{color: #6b7280!important;}

.section1 {
    /* background-color: #ff6f61; */
    font-family: Arial, sans-serif;
    position: relative;
    margin-top: 72px;
  
}

.section1 {
    .image-container {
        /* position: relative; */
        /* width: 100%; */
        height: 100%;
        /* overflow: hidden; */
    }
}

.filter-button-with-badge {
  
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
    font-weight: 700;
    font-size: 18px;
    background: #FDF1F3 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 6px;
}


.filter-button-with-badge .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 3px 10px;
    border-radius: 50%;
    background: #039BE5;
    color: white;
    font-size: 12px;
    font-weight: bold;
}
