
.emailverify {
    display: flex;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    flex-direction: column;
    width: 100%;
    min-width: 350px;
    background-color: white;
    border-radius: 12px;
    padding: 24px 0;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    height: 100vh;

    img {
        width: 100px;
        margin-bottom: 2rem;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .para {
        color: #a3a3a3;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 2rem;
    }

    .emailInput {
        min-width: 300px;
        width: auto;
        height: 40px;
        padding: 15px;
        margin-bottom: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        font-size: 16px;
    }

    .error {
        color:red;
    }

    .buttonVerify {
        margin: 2rem;
        background-color: initial;
        background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
        height: 40px;
        line-height: 40px;
        outline: 0;
        overflow: hidden;
        padding: 0 20px;
        pointer-events: auto;
        position: relative;
        text-align: center;
        touch-action: manipulation;
        user-select: none;
        -webkit-user-select: none;
        vertical-align: top;
        white-space: nowrap;
        width: auto;
        z-index: 9;
        border: 0;
        transition: box-shadow 0.2s;

   
    }

    #inputs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .otpInput {
        width: 42px;
        height: 42px;
        text-align: center;
        border: 1.5px solid #d2d2d2;
        margin: 0 10px;
        border-radius: 6px;
      }
    }

 
  
}

.emailverifyoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .centeredMessage {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;

    .messageImage {
      display: block;
      margin: 0 auto;
      width: 150px; 
      height: 150px; 
      margin-bottom: 10px;
    }

    p {
      font-size: 38px;
      color: #23282E;
      padding: 10px;
      border-radius: 8px;
      display: inline-block;

       span {
         color: var(--color-theme);
         border-radius: 4px;
      }
    }
  }
}
.timerCounter{
  color:#E62C03;
}




