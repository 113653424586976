
.link-btn-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}
.gameLink-main-cont{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    background-color: #e1edfc;
    border-radius: 10px;
}
.game-session-link-cont{
    width: 85%;
    max-width: 85%;
    height: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
}
.coplyLink-container{
    width: 15%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.linkBtn-text{
    width: 100%;
    height: 110%;
    position: absolute;
    background-color: hsl(0, 2%, 16%);
    color: white;
    font-size: 0.7rem;
    font-weight: 200;
    top: -160%;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.coplyLink-container:hover .linkBtn-text{
    display: flex;
}

.download-qr-btn{
    width: 12%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-theme);
    border-radius: 10px;
    position: relative;
}
.download-qr-btn:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}
.qrBtn-text{
    width: 100%;
    height: 80%;
    position: absolute;
    background-color: hsl(0, 2%, 16%);
    color: white;
    font-size: 0.7rem;
    font-weight: 200;
    top: -100%;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.download-qr-btn:hover .qrBtn-text{
    display: flex;
}
.save-btn-container{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-theme);
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    position: relative;
}
.save-btn-container:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.saveBtn-text{
    width: 90%;
    height: 50%;
    position: absolute;
    background-color: hsl(0, 2%, 16%);
    color: white;
    font-size: 0.7rem;
    font-weight: 200;
    top: -80%;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    
}
.save-btn-container:hover .saveBtn-text{
    display: flex;
}

.qr-download-detail{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
}
.time-cont{
    display: flex;
    flex-direction: row;
}
.time-cont > span {
    margin: 10px;
}
.qr-download-detail > h1{
    font-size: 1.2rem;
}

@media screen and (max-width: 520px) {
    .save-btn-container{
    font-weight: 200;
    padding: 5px;
    font-size: 0.8rem;

    }
}