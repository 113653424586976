.diwaliGameContainer {
    background-image: url('../assets/DDDT/BG_Desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
}

.nameInputField {
    border-radius: 26px;
    padding: 10px 20px;
    border: none;
    outline: none;
    width: 250px;
    background-color: #fcdc5e;
    color: #d80087;
    text-align: center;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.nameInputField::placeholder,
.rulesTxt,
.generateTicketBtn {
    color: #d80087;
    font-weight: 700;
    font-size: 22px;
}

.gameDesc {
    color: #fcdc5e;
}

.darkRose {
    background-color: #89096b;
}

.wordBoard {
    width: 90px;
    height: 60px;
    border: 1px solid #b18ba6;
    background-color: ghostwhite;
    font-size: 14px;
}


/* // need to check */

.pink-box {
    background-color: rgb(235, 126, 144);
}

.webinarBox {
    /* background-color: rgb(172, 15, 41); */
    margin-top: 10px;
    /* width: 500px; */
}

.buttonField {
    background: #3c0e31 !important;
    color: #ffc756 !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
    border-radius: 12px;
    text-align: center;
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 55px;
}

.selected {
    background-color: black;
    color: white;
}

.bg-custom-yellow {
    background-color: #fbfa04 !important;
}

.ticketButtons .form-select .react-select__control,
.ticketButtons .form-select .react-select__value-container {
    background: #3c0e31 !important;
    color: #ffc756 !important;
}

.ticketButtons .form-select .react-select__single-value,
.ticketButtons .form-select .react-select__placeholder {
    color: #ffc756 !important;
}

.text-danger-glow {
    color: #ff4141;
    text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.gameBox {
    width: 100px;
    height: 100px;
}

.boardContainer {
    max-width: 1500px;
}
.gameChatContainer .chatBtn{
    width: 120px !important;
}
@media screen and (max-width:1600px) {
    .boardContainer {
        max-width: 1400px;
    }
}

@media screen and (max-width:1500px) {

    .gameBox {
        width: 90px;
        height: 90px;
    }

    .boardContainer {
        max-width: 1300px;
    }
}

@media screen and (max-width:1350px) {
    .gameBox {
        width: 80px;
        height: 80px;
    }

    .boardContainer {
        max-width: 1200px;
    }
}

@media screen and (max-width:1200px) {

    .gameBox {
        width: 70px;
        height: 80px;
    }

    .boardContainer {
        max-width: 1100px;
    }
}

@media screen and (max-width:1124px) {

    .gameBox {
        width: 70px;
        height: 80px;
    }

    .boardContainer {
        max-width: 1000px;
    }

    .wordBoard {
        font-size: 13px;
    }
}

@media screen and (max-width:1050px) {

    .gameBox {
        width: 60px;
        height: 75px;
    }

    .boardContainer {
        max-width: 1000px;
    }
}

@media screen and (max-width:1024px) {

    .gameBox {
        width: 60px;
        height: 75px;
    }


}

@media screen and (max-width:991px) {
    .diwaliGameClaimContainer>div {
        width: 100%;
    }

    .gameBox {
        width: 80px;
        height: 80px;
    }

}

@media screen and (max-width:768px) {
    .boardContainer {
        /* max-width: 950px ; */
        flex-direction: column;
    }

    .wordBoardContainer,
    .ticketBoardContainer {
        width: 100% !important;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width:767px) {
    .gameChatMob{
        margin: 60px 20px 0px;
    }
}
@media screen and (max-width:650px) {
    .gameBox {
        width: 60px;
        height: 60px;
    }
   
}

@media screen and (max-width:500px) {
    .gameBox {
        width: 50px;
    }
 
}

@media screen and (max-width:500px) {
    .gameBox {
        width: 50px;
    }
    .wordBoard{
        width: 65px;
        height: 60px;
    }    
}

@media screen and (max-width:400px) {
    .gameBox {
        width: 40px;
    }
    .wordBoard{
        width: 55px;
        height: 60px;
    }
    .react-select__control {
        width: 100% !important;
    }
}