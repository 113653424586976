/* --- Play card start --- */
.playcard {
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
}

.playcard-img {
  height: 300px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
}

.playcard .btn-group {
  position: absolute;
  right: 0;
  margin: 5px;
  z-index: 11;
}

.playcard .btn-group .btn-primary {
  padding: 5px 10px;
}

.playcard-img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg,
      rgba(49, 48, 48, 0) 54.69%,
      rgba(2, 2, 2, 0.94) 100%);
  border-radius: 10px;
}

.playcard-img::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg,
      rgba(49, 48, 48, 0) 96%,
      rgba(2, 2, 2, 0.94) 200%);
  border-radius: 10px;
}

/* large card start */
.playcard-large {
  width: 200px;
  /* width: 14.5%; */
  margin-bottom: 25px;
  margin-right: 2%;
}

/* .playcard-large:nth-child(3n + 3) {
  margin-right: 0;
} */

.playcard-large .playcard-img {
  /* height: 220px;
  width: 100%; */
  height: 300px;
  width: 200px;
}

.playcard-large .playcard-img h3.created-by-field {
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 8px 0 0;
}

/* large card end */
.playcard-img {
  height: 300px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  width: 100%;
}

.playcard-img img {
  border-radius: 10px;
}

.playcard-img img,
.playcard-hover-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  aspect-ratio: 2/3;
}

.playcard-hover-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.playcard-hover-img .playback-video {
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 10px 10px 0 0;
  cursor: auto;
}

.playcard-hover-img .playback-video div {
  width: 100% !important;
  height: 100% !important;
}

.playcard-img h5 {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #ffffff;
  font-size: 18px;
  font-family: "firaSans-semibold";
  text-transform: capitalize;
  font-weight: 600;
}

.playcard-img h4 {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #ffffff;
  font-size: 18px;
  font-family: "firaSans-semibold";
  text-transform: capitalize;
  font-weight: 600;
}

/* scheduled-game Playcard */
.scheduled-game {
  font-size: 20px !important;
  bottom: 17% !important;
}

.playcard-img h3 {
  position: absolute;
  bottom: 7%;
  left: 16px;
  color: #ffffff;
  font-size: 16px;
  font-family: "firaSans-regular";
  text-transform: capitalize;
  opacity: .7;
}

.playcard .tag-label {
  position: absolute;
  top: 16px;
  right: 16px;
  visibility: hidden;
  cursor: auto;
}

/* card hover */
.playcard-hover {
  background: white;
  position: absolute;
  top: -25%;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  z-index: 9;
  width: 100%;
  min-height: 352px;
}

.playcard-large .playcard-hover {
  min-height: 392px;
  width: 300px;
}

.playcard-content {
  padding: 16px;
}

.playcard-content h5 {
  font-size: 16px;
  line-height: 19px;
  color: #23282e;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 28px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 14px !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block-ellipsis-innercard {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 14px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playcard-large .playcard-hover p {
  margin-bottom: 20px;
}

.playcard-large .play-icon img {
  height: 40px;
  width: 40px;
}

.playcard-hover-img {
  height: 156px;
  position: relative;
}

.playcard-hover-img iframe,
.playcard-hover-img video {
  border-radius: 10px 10px 0 0;
}

.playcard-hover-img img {
  border-radius: 10px 10px 0 0;
}

.play-icon {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* width: 100%; */
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  cursor: auto;
}

.play-icon img {
  height: 33px;
  width: 33px;
  cursor: pointer;
}

.play-icon img.pause-button {
  visibility: hidden;
}

.playcard-hover-img:hover .play-icon img.pause-button {
  visibility: visible;
}

/* review */
.playcard-review {
  display: flex;
  /* justify-content: ; */
  margin-bottom: 20px;
}

.playcard-review-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #23282e;
  margin-right: 15px;
}

.playcard-content h4.questions {
  display: flex;
  align-items: center;
}

.playcard-review-content h4 span {
  color: #bdbfc1;
  display: flex;
  font-size: 14px;
  margin-left: 2px;
}

/*controls */
.playcard-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 15px 0;
  margin-top: -10px;
  margin-bottom: 15px;
}

.playcard-actions .playcard-make-live {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.playcard-actions h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #23282E;
}

.playcard-actions .playcard-mode-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.playcard-actions .playcard-mode-select .select-container {
  width: 50%;
}

.playcard-actions .css-tj5bde-Svg {
  fill: unset;
}

.playcard-actions .css-leftpi-indicatorContainer {
  padding: 0px;
}

.gameStatus-toggle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

/* slider part */
.playcard-actions .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.playcard-actions .switch.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.playcard-actions .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.playcard-actions .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 4px;
}

.playcard-actions .disabled {
  cursor: not-allowed;
}

.playcard-actions .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 4px;
  -webkit-transition: .4s;
  transition: .4s;
}

.playcard-actions input:checked+.slider {
  background-color: #2AB070;
}

.playcard-actions input:focus+.slider {
  box-shadow: 0 0 1px #2AB070;
}

.playcard-actions input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/*slider end*/

/* play card action part end*/

/* bottom */
.playcard-bottom {
  display: flex;
  justify-content: space-between;
}

.playcard .playcard-hover .playcard-content .playcard-bottom .view-reports {
  width: 110px;
  padding: 5px;
}

.playcard-social {
  display: flex;
}

.playcard-social a {
  margin-right: 16px;
}

/* hover effect */
/* .playcard:hover .playcard-img img,
.playcard:hover .playcard-img::before {
  border-radius: 10px 10px 0 0;
} */

.playcard:hover .playcard-img h5 {
  visibility: hidden;
}

.playcard:hover .tag-label {
  visibility: visible;
}

.playcard:hover .playcard-hover {
  visibility: visible;
  transform: scale(1.1);
  z-index: 10;
  opacity: 1;
}

/* .playcard-large:hover .playcard-hover {
  transform: scale(1.2);
} */

/*tooltip*/
.playcard-large .btn-tooltip {
  position: relative;
  margin-left: 0px;
  margin-top: 5px;
}

.playcard-large .btn-tooltip .switch:hover+.tooltip {
  display: flex;
  justify-content: center;
}

.playcard-large .btn-tooltip .tooltip {
  width: 160px;
  position: absolute;
  top: 30px;
  left: -40px;
  background: #404040;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  display: none;
  transform: translateX(-50%);
}

.playcard-large .btn-tooltip .tooltip:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #404040;
  left: 80%;
  top: -12px;
  transform: rotate(90deg);
}

.playcard-large .btn-tooltip .tooltip span {
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0;
  z-index: 1000;
}

.playcard-large .btn-tooltip.deleted .tooltip {
  width: 160px;
  top: -55px;
  left: 0px;
}

.playcard-large .btn-tooltip.deleted .tooltip:before {
  left: 80%;
  top: 40px;
  transform: rotate(270deg);
}


/* --- Play card end --- */

@media screen and (max-width: 991px) {
  .gameslider {
    padding-left: 5px;
  }

  .playcard {
    min-width: 154px;
  }

  .playcard-img {
    /* height: 116px; */
    height: 280px;
    width: 200px;
  }

  .playcard-img h5 {
    left: 10px;
    font-size: 14px;
  }

  .playcard-img h4 {
    left: 10px;
    font-size: 16px;
  }

  .playcard .playcard-hover,
  .playcard:hover .playcard-hover {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .playcard:hover .playcard-img img,
  .playcard:hover .playcard-img::before {
    border-radius: 10px;
  }

  .playcard:hover .playcard-img h5 {
    visibility: visible;
  }

  .playcard-large {
    width: 31.5%;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .playcard-large .playcard-img {
    /* height: 100px; */
    height: 300px;
    width: 200px;
  }
  @media (max-width: 768px) {
    .playcard-large .playcard-img {
      /* Styles for mobile devices */
      height: 238px;
      width: 155px;
    }
  }
  .playcard-large:nth-child(3n + 3) {
    margin-right: 0;
  }

  /* scheduled-game Playcard */
  .scheduled-game {
    font-size: 14px !important;
    bottom: 21% !important;
  }

  .playcard-img h3 {
    position: absolute;
    bottom: 7%;
    left: 10px;
    color: #ffffff;
    font-size: 12px;
    font-family: "firaSans-regular";
    text-transform: capitalize;
    opacity: .7;
  }
}

@media screen and (max-width: 767px) {
  .playcard-large {
    width: 155px;
    height: 238px;
  }

  .playcard-large:nth-child(3n + 3) {
    margin-right: 16px;
  }

  .playcard-large:nth-child(2n + 2) {
    margin-right: 0;
  }
}