.main-wrapper {
  width: 100%;
  height: 100vh;
  padding: 114px 56px 30px 220px;
  background: #f1f1f3;
  overflow-y: auto;
}

.admin-homepage .main-wrapper {
  /* overflow: hidden; */
  padding: 90px 20px 20px 208px;
}

.main-wrapper.white {
  background: #fff;
}

@media screen and (max-width: 991px) {
  .main-wrapper {
    height: 100%;
    padding: 126px 16px 30px;
    overflow-y: initial;
  }

  .admin-homepage .main-wrapper {
    padding: 110px 20px 10px;
  }
}