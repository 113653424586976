/* modal css */
.custom-modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.259);
    align-items: center;
    justify-content: center;
    height: 100vh;
    outline: 0;
}

.custom-modal.show {
    display: flex !important;
}


.modal-body {
    position: relative;
    border-radius: 6px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}

.modal-body.server-closed {
    border-radius: 0;
}

.contact-wrapper {
    border-radius: 20px;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.fade.show {
    opacity: 1;
}

.custom-modal {
    z-index: 1072;
}

.disabled {
    opacity: 0.5;
}