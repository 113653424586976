.game-detail-heading{
    height: 48px;
    
    color: #ffffff;
    background: var(--background-theme);
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    padding: 14px 80px;
    text-align: center;
}