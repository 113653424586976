.webinars-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
    grid-gap: 20px 10px;
    margin:-10px 0 20px;
}
.webinars-wrapper .webinar-card{
    display: flex;
    justify-content: center;
}
.webinars-wrapper img{
    width: min(260px,80vw);
    cursor: pointer;
    border-radius: 10px;
    transition: all ease-in 200ms;
    max-height: 150px;
}
.webinars-wrapper img:hover{
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
    transform: scale(1.1);
}
.webinar-history-container .webinar-card {
    width: 100%;
    padding: 0 10px;
}
.webinar-history-container .webinar-card img{
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    transition: all ease-in 200ms;
    max-height: 150px;
}
.webinar-history-container .webinar-card img:hover{
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
    transform: scale(1.05);
}

.bannerUpdateCardButtons {
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50px;
    width: 45%;
}

.BannerUpdateCardModal {
    background-color: #F0F4FF !important;
}

.promoteGameThumbnail {
    width: 200px;
    height: 300px;
}

@media screen and (max-width: 500px){
    .webinar-history-container .webinar-card{
        padding: 0;
    }
    .webinar-history-container .webinar-card img{
        height: 110px;
    }
}