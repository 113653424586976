.objective-wrapper .profile-name-common{
    display: none;
}
.objective-wrapper .no-game-wrapper{
    position: relative;
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.objective-wrapper .objective-desc {
    text-align: justify;
    width: 100%;
    font-size: 16px;
    color: #23282e;
    margin-top: -20px;
    margin-bottom: 10px;
}
.objective-wrapper .objective-desc .show-more-text {
    color: #e25569;
    font-weight: 600;
    cursor: pointer;
}  

.objective-wrapper .no-game-wrapper .no-game{
    font-family: firaSans-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #23282e;
    opacity: 0.5;
    text-align: center;
}
.objective-wrapper .no-game-wrapper .no-game-span{
    font-family: firaSans-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #e25569;
    margin-top: 15px;
}

/* add edit  starts*/
.addedit-objective{
    min-height: 85vh;
    background: #fff;
    border-radius: 10px;
    padding:2% 5%;    
}
.addedit-objective .title{
    font-size: 30px;
    margin-top:10px;
    margin-bottom: 10px;
    color: #23282e;;
    font-size: 24px;
    line-height: 22px;
    text-transform: capitalize;
    font-family: "firaSans-semibold";
    font-weight: 600;    
}
.addedit-objective form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 0 30px;
}
.addedit-objective form .group{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.addedit-objective form .group .form-group{
    width: 45%;
}
.addedit-objective .form-group{
    width: 100%;
}
.addedit-objective form .form-group textarea{
    height: 60px;
}
.addedit-objective form .btn{
    width: 200px;
    margin-top: 20px;
}
.addedit-objective hr.faded{
    opacity: 0.3;
}
.addedit-objective .edit-objective{
    margin-top: 30px;
}
.addedit-objective .form-select .react-select__control, 
.addedit-objective .form-select .react-select__control--is-focused, 
.addedit-objective .form-select .react-select__value-container{
    height: unset !important;
}
/* add edit  end*/

@media screen and (max-width: 991px) {
    .objective-wrapper .sidebar{
        display: none;
    }
    .objective-wrapper .breadcrumb{
        display: none;
    }
    .objective-wrapper .profile-section{
        margin-top: -30px;
    }
    .managegames-objective-wrapper .sidebar{
        display: block;
    }
    .managegames-objective-wrapper .profile-section{
        margin-top: 10px;
    }
    .managegames-objective-wrapper .profile-section .profile-name{
        display: none;
    }
    .objective-wrapper .profile-name{
        display: none;
    }
    .objective-wrapper .profile-name-common{
        display: block;
    }
    .objective-wrapper .no-game-wrapper .no-game {
        font-size: 16px;
    }
    .objective-wrapper .no-game-wrapper .no-game-span{
    font-size: 14px;
    }
    .objective-wrapper .objective-desc{
        margin-top: -10px;
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 600px) {
    .objective-wrapper .no-game-wrapper{
      height: 50vh;
    }
    .objective-wrapper .no-game-wrapper .no-game {
      font-size: 14px;
    }
    .objective-wrapper .no-game-wrapper .no-game-span{
      margin-top: 5px;
      font-size: 12px;
    }
    .objective-wrapper .objective-desc{
        font-size: 14px;
    }
}
  
@media screen and (max-width: 400px) {
    .objective-wrapper .no-game-wrapper .no-game {
      font-size: 12px;
    }
    .objective-wrapper .no-game-wrapper .no-game-span{
      font-size: 10px;
    }
}
  