.no-game-wrapper{
  position: relative;
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-game {
  font-family: firaSans-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #23282e;
  opacity: 0.5;
  text-align: center;
}
.no-game img{
  width: 20px;
  margin-bottom: -2px;
}
.no-game-span {
  font-family: firaSans-regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #e25569;
  margin-top: 15px;
}

@media screen and (max-width: 991px) {
  .no-game {
    font-size: 16px;
  }
  .no-game img{
    width: 18px;
  }
  .no-game-span{
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .no-game-wrapper{
    height: 50vh;
  }
  .no-game {
    font-size: 14px;
  }
  .no-game-span{
    margin-top: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .no-game {
    font-size: 12px;
  }
  .no-game img{
    width: 16px;
  }
  .no-game-span{
    font-size: 10px;
  }
}

