.cardDesign {
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
}

.cardHeader {
  word-wrap: break-word;
}

.cardFullScreen {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100% !important;
  height: 100% !important;
}

.react-tagsinput-tag {
  background-color: #fb9946 !important;
  border: 1px solid #ff5733 !important;
  border-radius: 2px;
  margin: 2px;
  padding: 3px 3px;
  color: #fff !important;
}

.react-tagsinput-input {
  background-color: #f5f5f5;
  border: none;
  border-radius: 4px;
  width: 730px !important;
}

.react-tagsinput-remove {
  display: inline-block;
  margin-left: 8px;
}

.react-tagsinput span.red-background {
  background-color: red;
  color: white;
}

.toggleBtn {
  background-color: white;
  border: 1px solid lightgray;
  width: 34px;
  height: 18px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
}

.toggleBtn::before {
  position: absolute;
  content: "";
  background-color: white;
  width: 18px;
  height: 16px;
  border-radius: 8px;
  border: lightgray;
  box-shadow: 0px 0px 2px;
  transition: 0.2s;
}

input:checked + .toggleBtn {
  background-color: #ff0000a6;
}

input:checked + .toggleBtn::before {
  transform: translateX(14px);
}

.justify-content-even {
  justify-content: space-evenly;
}

.claim-table {
  border: 1px #cdd2f1 solid;
}
