.parent-div {
    position: relative;      /* Keep this if you have absolutely positioned children */
    width: 100vw;           /* Full viewport width */
    height: calc(80vh); /* Height minus the header */
    background-color: #f0f0f0; /* Light gray background */
    overflow: hidden;       /* Hide overflow content */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
  .child-div {
    position: absolute;
 
  
    transition: top 7s ease-in-out, left 7s ease-in-out;
  }
  
  .move-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    font-size: 16px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
  }
  .form-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white; /* Set text color */
    padding: 20px;
    border-radius: 8px;
  
    transition: filter 2s ease; /* Smooth transition for blur effect */
   
  }
  
  .form-overlay h2 {
    text-align: center;
    font-size: 39px; /* Adjust font size as needed */
    font-weight: 600;
  }
  
  .form-overlay p {
    text-align: center;
    color:  #D0D2FF;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
  
  .form-overlay div {
    display: flex;
    justify-content: center;
  }
  
  .discountforminput {
    color: black;
    height: 46px;
    border-radius: 12px;
    padding-left: 10px;
    width: 100%;
    box-shadow: #fcfcfc 0px 0px 13px -2px;
}
  
  .form-overlay button {
    min-width: 150px;
    height: 55px;
    margin-top: 10px;
    padding: 10px 15px; /* Add padding for buttons */
  }
  
  .blur1 {
 opacity: 0.7;
  }
  .button-view-plans {
    background-color: var(--color-theme)!important;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    letter-spacing: 0px;
    /* font-weight: 600; */
    line-height: 16px;
    text-decoration: none !important;
   
    color: #fff !important;
    border: none;
    /* border-radius: 2px; */
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    height: 48px;
    text-align: center;
    white-space: nowrap;
}

.button-find-now {
    background-color: var(--color-theme);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    position: relative; /* For absolute positioning of the image */
    border: none;
    cursor: pointer;
    letter-spacing: 1px;
}


.bgred500{background-color: var(--color-theme);}
.textRed{color:var(--color-theme);background-color: white;}

.satellite:hover {
  transform: scale(1.1);
}
.blurP{opacity: 0.8;}
.random-image {
  transition: transform 0.3s ease;
}

.scaled {
  transform: scale(1.5); /* Adjust the scale factor as needed */
}