.newplans-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    /* padding-top: 40px; */
    /* height: 100%; */

}
.newplans-container .newplan-options-container-standard-premium-container{
    display: flex;
    align-items: center;
    width: 90%;
    height: 48px;
    justify-content: center;
    margin-top: 50px;
    flex-direction: row; 
}

.newplans-container .newplan-options-container-standard-premium-container .plan-types-btn-cont-standard-premium{
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 250px;
    background: #FFF7F8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 10px;
    height: 63px;
}
.heading-info-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 73px;
}

.heading-info-cont>h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    font-family: "FiraSans-Regular";
    text-align: left;
}

.heading-info-cont>h1>span {
    color: var(--color-theme_7);
}

.heading-info-cont>span {
    color: #848484;
    font-family: "FiraSans-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.3200000524520874px;
    text-align: left;

}

.newplan-options-container {
    display: flex;
    align-items: center;
    width: 90%;
    height: 48px;
    justify-content: space-between;
    margin-top: 50px;
    flex-direction: row;

}

.plan-types-btn-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 486px;
    background: #FFF7F8 0% 0% no-repeat padding-box;
border-radius: 6px;
padding: 10px;
height: 63px;
}

.plan-option-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    /* border: 1px solid var(--color-theme_7); */
    height: 48px;
    border-radius: 12px;
    font-family: "FiraSans-Regular";
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    letter-spacing: 0.5200000524520874px;
    /* transition: background-color 0.1s, color 0.1s;
    transition-delay: 0.1s; */
    transition: ease 0.3s;

}

.plan-option-btn:hover {
    background: var(--color-theme_7);
    color: white;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}

.plan-user-option-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 278px;
    height: 100%;

}

.user-selection-cont {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

select {
    background-color: white;
    border: 1px solid #DCDDDE;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 0.3200000524520874px;
    font-weight: 400;
    color: #333;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: none;
}

select:focus {
    outline: none;
}

.plan-info-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 90%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.plan-box-container {
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 290px;
    height: 615px;
    position: relative;

}

.cat-leg1-cont {
    width: 21.7px;
    height: 13px;
    position: absolute;
    top: -4px;
    left: 26px;
    z-index: 10;
    opacity: 0;
    transition: 0.5s ease;

}

.cat-leg2-cont {
    width: 21.7px;
    height: 13px;
    position: absolute;
    top: -4px;
    z-index: 10;
    right: 130px;
    opacity: 0;
    transition: 0.5s ease;

}

.cat-face-cont {
    width: 89.24px;
    height: 58.45px;
    position: absolute;
    top: 0px;
    left: 49px;
}

.cat-tail-cont {
    position: absolute;
    width: 39.4px;
    height: 79.77px;
    top: 0px;
    right: 107px;

}

.cat-container {
    width: 80%;
    height: 30px;
    position: absolute;
    transition: top 0.9s ease;
    transition-duration: 2.3s;
    z-index: -1;

}

/* .plan-box-container:hover .cat-leg1-cont,
.plan-box-container:hover .cat-leg2-cont {
    opacity: 1;
}

.plan-box-container:hover {
    animation: conatinerMargin 1.1s forwards 1;
    animation-delay: 0.5s;

}

.plan-box-container:hover .cat-face-cont {
    animation: riseCatFace 1.1s forwards 1;
    animation-delay: 0.5s;


}

.plan-box-container:hover .cat-tail-cont {
    animation: riseCatTail 0.7s forwards 1;
    animation-delay: 1.4s;
} */

.plan-box-container.hover-animation .cat-leg1-cont,
.plan-box-container.hover-animation .cat-leg2-cont,
.plan-box-container.hover-animation-ourpick .cat-leg1-cont,
.plan-box-container.hover-animation-ourpick .cat-leg2-cont {
    opacity: 1;
}

/* .plan-box-container.hover-animation {
    animation: conatinerMargin 0.9s forwards 1;
    animation-delay: 0.5s;

} */

.plan-box-container.hover-animation .cat-face-cont,
.plan-box-container.hover-animation-ourpick .cat-face-cont {
    animation: riseCatFace 0.9s forwards 1;
    animation-delay: 0.5s;


}

.plan-box-container.hover-animation .cat-tail-cont,
.plan-box-container.hover-animation-ourpick .cat-tail-cont  {
    animation: riseCatTail 0.5s forwards 1;
    animation-delay: 1.2s;
}

.plan-btn{
    color: var(--color-theme_7);
    border: 1px solid var(--color-theme_7);
    width: 100%;
    height: 51px;
    font-size: 16px;
    margin-top: 25px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    font-size: 16px;
    position: relative;
    transition: all 0.2s linear;
    font-family: "FiraSans-Medium";
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.plan-btn:hover{
    color: white;
    background: var(--background-theme_2);
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}
.hover-animation .plan-btn,
.hover-animation-ourpick .plan-btn{
    color: white;
    background: var(--background-theme_2);
    width: 100%;
    height: 51px;
    font-size: 16px;
    margin-top: 25px;
    /* cursor: pointer; */
    border-radius: 6px;
    outline: none;
    font-size: 16px;
    position: relative;
    transition: all 0.2s linear;
    font-family: "FiraSans-Medium";
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}
.hover-animation .plan-btn .disabled,
.hover-animation-ourpick .plan-btn .disabled{
    cursor: not-allowed !important;
    opacity: 0.4 !important;
}


  .btn-tooltip:hover .tooltip{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}


@keyframes conatinerMargin {
    0% {
        margin-top: 50px;


    }

    100% {
        margin-top: 100px;

    }
}


@keyframes riseCatFace {
    0% {
        top: 0%;


    }

    100% {
        top: -57px;

    }
}

@keyframes riseCatTail {
    0% {
        top: 0%;


    }

    100% {
        top: -56px;

    }
}


.plan-box-container.reverseHover-animation .cat-leg1-cont,
.plan-box-container.reverseHover-animation .cat-leg2-cont {
    opacity: 0;
}

.plan-box-container.reverseHover-animation .cat-face-cont {
    animation: reverseRiseCatFace 0.9s forwards 1;
    animation-delay: 0.5s;


}

.plan-box-container.reverseHover-animation .cat-tail-cont {
    animation: reverseRiseCatTail 0.5s forwards 1;
    animation-delay: 1.2s;
}

@keyframes reverseRiseCatFace {
    0% {
        top: -57px;


    }

    100% {
        top: 0%;

    }
}

@keyframes reverseRiseCatTail {
    0% {
        top: -56px;


    }

    100% {
        top: 0%;

    }
}


.plan-cart-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 285px;
    height: 615px;
    border: 1px solid #DCDDDE;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;
    transition: ease 0.3s;
    background-color: white;
    z-index: 3;


}


.plan-box-container:hover .plan-cart-box {
    border: 1px solid var(--color-theme_7);
    /* box-shadow: rgba(0, 0, 0, 0.5) 0px 8px 24px 2px; */
}

.plan-box-container.hover-animation  .plan-cart-box,
.plan-box-container.hover-animation-ourpick  .plan-cart-box {
    border: 1px solid var(--color-theme_7);
}


.plan-type-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    background: var(--color-theme_7);

}

.plan-type-info>span {
    font-size: 15px;
    font-weight: 500;
    font-family: "FiraSans-Regular";
    line-height: 19.2px;
    color: white;

}

.per-user-price-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    flex-direction: row;
    border-bottom: 1px solid #CDCDCD;


}

.currency-symbol-cont {
    display: flex;
    /* align-items: center;
    justify-content: flex-start; */
    height: 39px;
    color: #23282E99;
    align-items: flex-start;
    margin-top: -10px;
    margin-right: 6px;


}

.per-user-price-cont>span {
    font-family: "FiraSans-Regular";
    font-size: 50px;
    font-weight: 700;
    line-height: 57.2px;
    letter-spacing: 0.3200000524520874px;
    text-align: left;


}

.per-person-info {
    display: flex;
    align-items: flex-end;
    /* justify-content: flex-end; */
    height: 39px;
    margin-left: 5px;



}

.per-person-info>span {
    font-family: "FiraSans-Regular";
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #23282E99;

}

.plan-description-cont {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    height: 361px;
    width: 243.4px;
    flex-direction: column;
    margin-top: 20px;
    position: relative;

}

.our-pick {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 93px;
    height: 32px;
    background: var(--color-theme_7);
    border-top-left-radius: 46px;
    border-bottom-left-radius: 46px;
    top: -2px;
    right: -20px;

}

.our-pick>span {
    font-family: "FiraSans-medium";
    font-size: 15px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    color: white;

}

.plan-description-cont>h3 {
    font-size: 20px;
    font-weight: 600;
    font-family: "FiraSans-Regular";
    margin-bottom: 15px;
}

.plan-descr-info {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 12px;

}

.content-check-cont {
    width: 24px !important;
    height: 24px !important;
    transition: 1s ease;


}

.content-checkhover-cont {
    width: 20px !important;
    height: 20px !important;
    /* display: none; */
}

/* .plan-box-container:hover .content-check-cont {
    display: none;
}

.plan-box-container:hover .content-checkhover-cont {
    display: block;
} */

.content-check-cont>img {
    width: 20px !important;
    height: 20px !important;
}


.plan-purchase-btn {
    width: 100%;
    height: 51px;
    background-color: var(--background-theme);
    color: white;
    font-size: 16px;
    margin-top: 25px;

}

#options option {
    font-size: 18px;
    font-weight: 600;
    font-family: "FiraSans-Regular";
    line-height: 21.6px;
    letter-spacing: 0.5200000524520874px;

}

@media (max-width: 1280px) {

    .plan-types-btn-cont {

        width: 359px;
    
    }

    .plan-option-btn {
        width: 110px;
    }

    .plan-user-option-cont {
        width: 210px;
    }

    .user-selection-cont {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .user-selection-cont>select {
        border: 1px solid var(--color-theme_7);
    }

    .plan-info-container {
        margin-top: 0px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .newplan-options-container {
        width: 100%;
    }

    .plan-box-container {
        margin-top: 75px;

    }
}

@media screen and (max-width: 991px) {
    .newplans-container {
        padding: 50px 56px 62px;
    }
}

@media screen and (max-width: 770px) {
    .newplans-container {
        padding: 50px 0px 0px !important;
    }
}

@media screen and (max-width: 700px) {
    #plan-back-arrow {
        margin-left: 9px !important;
        height: 25px !important;
    }
}



@media screen and (max-width: 600px) {
    .newplans-container {
        padding: 50px 0px 0px !important;
    }

    .plan-info-container {
        justify-content: center;

    }

    .newplan-options-container {
        padding-right: 10px;
        padding-left: 10px;

    }

    .plan-types-btn-cont {
        width: 330px;
        justify-content: space-around;
    }

    .plan-user-option-cont {
        width: 215px;
        margin-top: 30px;
    }

    .newplan-options-container {
        flex-direction: column;
        /* align-items: flex-start; */
        align-items: center;
        justify-content: center;
        height: 135px;
        margin-top: 30px;
    }

    .plan-user-option-cont {
        height: 48px;
    }

    .plan-option-btn {
        width: 95px;
        height: 40px;
    }
}


@media screen and (max-width: 600px) {

    .heading-info-cont>h1 {
        font-size: 24px;
    }

    .newplan-options-container {
        /* padding-right: 10px; */
        align-items: center;
        justify-content: center;
    }

}