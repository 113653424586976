/* Scoped styles within the .ratingsection class */
.ratingsection * {
    box-sizing: border-box;
}

.ratingsection {
    font-family: Arial;
    width: 100%;
    padding: 20px;
}

.ratingsection .heading {
    font-size: 25px;
    margin-right: 10px;
}

.ratingsection .fa {
    font-size: 15px;
}

.ratingsection .checked {
    color: #E25569;
}

/* Three column layout */
.ratingsection .row {
    display: flex;
    /* Use flexbox for alignment */
    align-items: center;
    /* Center items vertically */
    margin-bottom: 10px;
    /* Add spacing between rows */
}

.ratingsection .side {
    margin-right: 1rem;
    /* Spacing between left and right */
}

.ratingsection .middle {
    flex-grow: 1;
    /* Allow this to take available space */
}

/* Place text to the right */
.ratingsection .right {
    text-align: right;
    margin-left: 1rem;
}

/* The bar container */
.ratingsection .bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
    border-radius: 13px;
}

/* Individual bars */
.ratingsection .bar-5 {
    width: 60%;
    height: 18px;
    background-color: #04AA6D;
}

.ratingsection .bar-4 {
    width: 30%;
    height: 18px;
    background-color: #2196F3;
}

.ratingsection .bar-3 {
    width: 10%;
    height: 18px;
    background-color: #00bcd4;
}

.ratingsection .bar-2 {
    width: 4%;
    height: 18px;
    background-color: #ff9800;
}

.ratingsection .bar-1 {
    width: 15%;
    height: 18px;
    background-color: #E25569;
    border-radius: 13px;
}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
    .ratingsection .row {
        flex-direction: column;
        /* Stack items vertically on small screens */
        align-items: flex-start;
        /* Align to start */
    }

    .ratingsection .side,
    .ratingsection .middle {
        width: 100%;
        margin-right: 0;
        /* Remove margin for small screens */
    }

    .ratingsection .right {
        display: none;
    }
}

.vertical-cards-section {
    width: 100%;

    font-family: Arial, sans-serif;
    max-height: 400px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */

    border-radius: 8px;
    /* Optional: Round the corners */
}

.vertical-cards-section .section-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
}

.vertical-cards-section .user-feedback {
    margin-bottom: 20px;
    /* padding: 15px; */

    border-radius: 8px;
    text-align: left;
}
.truncate-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines you want to show */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
.vertical-cards-section .user-image {
    width: 100px;
    /* Adjust as necessary */
    height: 100px;
    /* Adjust as necessary */
    border-radius: 50%;
    margin-bottom: 10px;
}

.vertical-cards-section .user-name {
    font-size: 25px;
    font-weight: bold;
    color: #23282E;
}

.vertical-cards-section .user-designation {
    font-size: 16px;
    color: #868686;
    margin: 5px 0;
}

.vertical-cards-section .user-quote {
    font-size: 16px;

    margin: 10px 0;
    color: #23282E;
}

.vertical-cards-section .divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin-top: 10px;
}

.why-extramile-section {
    /* width: 100%;
    padding: 20px; */
    font-family: Arial, sans-serif;
}

.why-extramile-section .section-title {
    font-size: 24px;
    text-align: left;
    /* Center the title */
    margin-bottom: 20px;
    /* Space below the title */
}

.why-extramile-section .user-feedback {
    box-shadow: 0px 4px 34px #00000029;
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    margin-bottom: 20px;
    /* Space between cards */
    padding: 15px;

    border-radius: 8px;
    /* Optional: Rounded corners */
}

.why-extramile-section .user-image {
    width: 80px;
    /* Adjust as necessary */
    height: 80px;
    /* Adjust as necessary */
    border-radius: 50%;
    /* Make image circular */
    margin-right: 15px;
    /* Space between image and text */
}

.why-extramile-section .user-info {
    flex-grow: 1;
    /* Allow text to grow and fill space */
}

.why-extramile-section .user-name {
    font-size: 25px;
    font-weight: 600;
    color: #23282E;
}

.why-extramile-section .user-quote {
    font-size: 16px;
    color: #868686;
    margin-top: 5px;
    /* Space between name and quote */
}

.pinkCard {
    box-shadow: none !important;
    min-width: 200px;
    height: 60px;
    background: #FDF1F3 0% 0% no-repeat padding-box !important;
    border-radius: 6px;
}

.font-21 {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #23282e !important;
}

.bgPink {
    background: #FDF1F3 0% 0% no-repeat padding-box !important;
    color: #343434;
}

.text-grey {
    color: #868686 !important;
    font-size: 18px;
}

.textBlack {
    color: #23282E !important;
    font-size: 18px;
    font-weight: 600;
}

.shadowBookEvent {
    border-radius: 6px;
    box-shadow: 0px 0px 16px #00000029 !important;
}

.paraColorAndFont {
    color: #23282E !important;
    font-size: 16px !important;
}

.bg-red-button {
    background: #E25569 0% 0% no-repeat padding-box !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.border-red-button {
    border: 1px solid #E25569 !important;
    color: #E25569 !important;
    background: transparent !important;
}

.bookEventCardImg {
    width: 100% !important;
    height: 192px !important;
    border-bottom-right-radius: unset !important;
    border-bottom-left-radius: unset !important;
}

.font-40 {
    font-size: 34px !important;
}

.font-15 {
    font-size: 15px !important;
    color: #23282E !important;
    font-weight: 600 !important;
}

.font-14 {
    font-size: 14px !important;
    color: #23282E !important;
    margin-bottom: 0.5rem;
    text-align: justify;
    opacity: 75%;
}

.textRed {
    color: #E25569 !important;
}



.inputFont {
    font-size: 14px !important;
    color: #868686 !important;
}

.user-image {
    width: 100px;
    /* Adjust based on your needs */
    height: 100px;
    border-radius: 50%;
    /* To make the image circular */
    position: relative;
    z-index: 10;
}

.moon-border {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 103px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #E25569;
    clip-path: circle(70% at 70% 50%);
    z-index: 5;
}

.bookeventcardcontainer .react-multi-carousel-list {

    width: 100%;
    margin: 0 auto;
}

.bookeventcardcontainer .react-multi-carousel-list ul {
    margin: 2rem;
    /* width: 100%; */
}

@media screen and (min-width: 100px) and (max-width: 464px) {
    .bookeventcardcontainer .react-multi-carousel-list ul {
        margin: 0.3rem;
    }
}

.bottom-right-image {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 8%;
    height: auto;
    z-index: 100;
}

.container-width-full{
    width: 100% !important;
}

.bookEventInfoCard .bookEventInfoCardImg {
    transition: transform 0.5s ease; /* Apply transition on the initial state */
}

.bookEventInfoCard:hover .bookEventInfoCardImg {
    transform: scale(1.05); /* Scale the image slightly */
}

.bookEventInfoCard .titleHoverInfo {
    transition: text-decoration 0.5s ease; /* Apply transition on the initial state */
}

.bookEventInfoCard:hover .titleHoverInfo {
    text-decoration: underline;
}